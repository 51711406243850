import * as Yup from 'yup';
import { NumberIntegerMinOneRequired, NumberIntegerRequired, NumberRequired, StringRequired, ToTwoDecimal } from '../utils/dataValidations';

export const CreateValues = (cuaderno_id, user_id) => {
    return {
        initialValues: {
            cuaderno_id: cuaderno_id,
            almacen_producto_id: '',
            cantidad_total: 0,
            cantidad_total_restante: 0,
            departamento_id: 2,
            user_id: '',
            precio_unitario: 0,
            cantidad: 0,
            cantidad_retorno: 0,
            cantidad_deuda: 0,
            metodo_intercambio: 'normal',
            metodo_pago: 'efectivo',
            monto_garantia: 0,
            fecha_estimada_devolucion: '',
            venta_total: 0,
            efectivo_total: 0,
            deuda_total: 0,
            transferencia_total: 0,
            fecha_estimada_cobro: '',
            obs: '',
            // cuaderno_id: cuaderno_id,
            // almacen_producto_id: '',
            // departamento_id: '1',
            // user_id: '',
            // precio_unitario: 2000,
            // cantidad: 5,
            // cantidad_retorno: 4,
            // cantidad_deuda: 1,
            // metodo_intercambio: 'garantía',
            // monto_garantia: 200,
            // fecha_estimada_devolucion: '',
            // metodo_pago: 'transferencia',
            // venta_total: 10000,
            // efectivo_total: 0,
            // deuda_total: 0,
            // transferencia_total: 10000,
            // fecha_estimada_cobro: '',
            // obs: '',
        },
        fieldsValidation: Yup.object().shape({
            almacen_producto_id: StringRequired,
            cantidad_deuda: NumberIntegerRequired,
            precio_unitario: NumberRequired,
            // cantidad_total: NumberIntegerRequired,
            // cantidad_total_restante: NumberIntegerRequired,
            cantidad: NumberIntegerMinOneRequired,
            cantidad_retorno: NumberIntegerRequired,

            // fecha_estimada_cobro: Yup.date()
            //     .required()
            // metodo_intercambio: Yup.string().when('cantidad_deuda', {
            //     is: cantidadDeuda => cantidadDeuda > 0,
            //     then: Yup.string().notOneOf(['normal'], 'No puede ser normal si la cantidad de deuda es mayor a 0').required(),
            // }),
        }),
        fields: [
            {
                label: 'Seleccione producto de almacén',
                name: 'almacen_producto_id',
                type: 'selectSearchDetailCard',
                // param: 'user_id',
                urlApi: `/user/${user_id}/almacenes/retornable`,
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Producto...',
                autoFocus: true,
                nameNumber: 'cantidad_total',
                labelNumberRestante: 'Restante',
                nameNumberRestante: 'cantidad_total_restante',
                alterField: 'cantidad',
                exclamationDefault: 'Seleccione un producto!',
                exclamationRestante: 'Stock insuficiente!',
                infoTags: [
                    {
                        label: 'Producto',
                        data: 'producto_nombre'
                    },
                    {
                        label: 'Estado',
                        data: 'estado_nombre'
                    },
                    {
                        label: 'En almacén',
                        data: 'cantidad_total',
                        mark: true
                    },
                ]
            },
            {
                label: 'Buscar consumidor',
                name: 'user_id',
                type: 'selectSearch',
                urlApi: '/users/consumidores',
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Consumidor...',
                autoFocus: false,
                // defaultValue: { value: '2', label: 'Clientes' }
            },
            // {
            //     type: 'divider',
            // },
            // {
            //     label: 'Buscar departamento',
            //     name: 'departamento_id',
            //     type: 'selectSearch',
            //     urlApi: '/departamentos',
            //     required: true,
            //     labelDescription: 'nombre',
            //     placeholder: 'Departamento...',
            //     autoFocus: false,
            //     defaultValue: { value: '2', label: 'Clientes' }
            // },
            // {
            //     label: 'Buscar usuario',
            //     name: 'user_id',
            //     type: 'subSelectSearch',
            //     param: 'departamento_id',
            //     urlApi: '/departamento/{param}/users',
            //     required: true,
            //     labelDescription: 'etiqueta',
            //     placeholder: 'Usuario...',
            //     autoFocus: false
            // },
            {
                label: 'Precio unitario',
                name: 'precio_unitario',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const precio_unitario = e.target.valueAsNumber || 0;
                    const cantidad = values.cantidad || 0;
                    const venta_total = ToTwoDecimal(precio_unitario * cantidad);
                    setValues('venta_total', venta_total)

                    const efectivo_total = values.efectivo_total || 0;
                    const deuda_total = ToTwoDecimal(venta_total - efectivo_total)
                    setValues('deuda_total', deuda_total)
                }
            },
            {
                label: 'Cantidad',
                name: 'cantidad',
                type: 'numberCalculator',
                subData: null,
                required: true,
                locked: false,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const precio_unitario = values.precio_unitario || 0;
                    const venta_total = ToTwoDecimal(precio_unitario * cantidad);
                    setValues('venta_total', venta_total)

                    const efectivo_total = values.efectivo_total || 0;
                    const deuda_total = ToTwoDecimal(venta_total - efectivo_total)
                    setValues('deuda_total', deuda_total)

                    const cantidad_retorno = values.cantidad_retorno || 0
                    const cantidad_deuda = cantidad - cantidad_retorno
                    setValues('cantidad_deuda', cantidad_deuda)

                    const cantidad_total = values.cantidad_total || 0
                    const total = ToTwoDecimal(cantidad_total - cantidad)
                    setValues('cantidad_total_restante', total < 0 ? 0 : total)
                }
            },
            {
                label: 'Cantidad recojidos',
                name: 'cantidad_retorno',
                type: 'numberCalculator',
                subData: null,
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad_retorno = e.target.valueAsNumber || 0;
                    const cantidad = values.cantidad || 0
                    const cantidad_deuda = cantidad - cantidad_retorno
                    setValues('cantidad_deuda', cantidad_deuda)
                },
            },
            {
                label: 'Cantidad deuda',
                name: 'cantidad_deuda',
                type: 'number',
                subData: null,
                required: true,
                disabled: true,
                highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20',
                locked: true,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    const cantidad_retorno = values.cantidad_retorno || 0
                    const cantidad = values.cantidad || 0
                    const cantidad_deuda = cantidad - cantidad_retorno
                    setValues('cantidad_deuda', cantidad_deuda)
                },
                fieldDependency: 'cantidad',
            },
            {
                label: 'Normal / Garantía / Prestamo',
                name: 'metodo_intercambio',
                type: 'selectCondition',
                required: true,
                options: [
                    {
                        label: 'Normal',
                        value: 'normal',
                        disabled: (values) => {
                            if (values.cantidad_deuda > 0)
                                return true
                        }
                    },
                    {
                        label: 'Garantía',
                        value: 'garantía',
                        disabled: (values) => {
                            if (values.cantidad_deuda <= 0)
                                return true
                        }
                    },
                    {
                        label: 'Prestamo',
                        value: 'préstamo',
                        disabled: (values) => {
                            if (values.cantidad_deuda <= 0)
                                return true
                        }
                    },
                ],
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.cantidad_deuda > 0 && values.metodo_intercambio === 'normal')
                        setValues('metodo_intercambio', 'garantía')
                    if (values.cantidad_deuda <= 0 && values.metodo_intercambio !== 'normal')
                        setValues('metodo_intercambio', 'normal')
                },
                fieldDependency: 'cantidad_deuda',
            },
            {
                label: 'Monto de garantía',
                name: 'monto_garantia',
                type: 'groupnumber',
                subData: 'Bs.',
                required: false,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_intercambio === 'garantía')
                        setAditionalClass('')
                    else
                        setAditionalClass('hidden')
                },
                fieldDependency: 'metodo_intercambio',
                highlight: 'bg-indigo-600 dark:bg-indigo-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Fecha estimada de devolución',
                name: 'fecha_estimada_devolucion',
                type: 'date',
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_intercambio === 'préstamo')
                        setAditionalClass('')
                    else
                        setAditionalClass('hidden')
                },
                fieldDependency: 'metodo_intercambio',
                highlight: 'bg-cyan-600 dark:bg-cyan-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Método de pago',
                name: 'metodo_pago',
                type: 'select',
                required: true,
                options: [
                    {
                        label: 'Efectivo',
                        value: 'efectivo'
                    },
                    {
                        label: 'Transferencia',
                        value: 'transferencia'
                    },
                    {
                        label: 'Cheque',
                        value: 'cheque'
                    },
                ],
            },
            {
                label: 'Total venta',
                name: 'venta_total',
                type: 'groupnumber',
                subData: 'Bs.',
                required: true,
                disabled: true,
                highlight: 'bg-blue-600 dark:bg-blue-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Total cobrado',
                name: 'efectivo_total',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const efectivo_total = e.target.valueAsNumber || 0;
                    const venta_total = values.venta_total || 0
                    const deuda_total = ToTwoDecimal(venta_total - efectivo_total)
                    setValues('deuda_total', deuda_total)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo') {
                        setAditionalClass('hidden')
                    }
                    else {
                        setAditionalClass('')
                    }
                },
                fieldDependency: 'metodo_pago',
                highlight: 'bg-green-600 dark:bg-green-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Saldo',
                name: 'deuda_total',
                type: 'groupnumber',
                subData: 'Bs.',
                required: true,
                disabled: true,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo') {
                        setAditionalClass('hidden')
                    }
                    else {
                        setAditionalClass('')
                    }
                },
                fieldDependency: 'metodo_pago',
                highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Pago confirmar',
                name: 'transferencia_total',
                type: 'groupnumber',
                subData: 'Bs.',
                required: false,
                disabled: true,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago === 'efectivo') {
                        setAditionalClass('hidden')
                        setValues('transferencia_total', 0)
                    }
                    else {
                        setAditionalClass('')
                        setValues('transferencia_total', values.venta_total)
                    }
                },
                fieldDependency: 'metodo_pago',
                highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Fecha estimada de cobro',
                name: 'fecha_estimada_cobro',
                type: 'date',
                required: false,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.deuda_total > 0 && values.metodo_pago === 'efectivo')
                        setAditionalClass('')
                    else
                        setAditionalClass('hidden')
                },
                fieldDependency: 'deuda_total',
                fieldDependencyExtra: 'metodo_pago',
                highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const ResetValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}

export const VentaNoRetornableValues = (cuaderno_id, user_id) => {
    return {
        initialValues: {
            cuaderno_id: cuaderno_id,
            almacen_producto_id: '',
            // departamento_id: '1',
            user_id: '',
            precio_unitario: 0,
            cantidad: 0,
            metodo_intercambio: 'normal',
            metodo_pago: 'efectivo',
            venta_total: 0,
            efectivo_total: 0,
            deuda_total: 0,
            transferencia_total: 0,
            fecha_estimada_cobro: '',
            obs: '',
            // cuaderno_id: cuaderno_id,
            // almacen_producto_id: '',
            // departamento_id: '1',
            // user_id: '',
            // precio_unitario: 60,
            // cantidad: 1,
            // metodo_intercambio: 'normal',
            // metodo_pago: 'efectivo',
            // venta_total: 60,
            // efectivo_total: 0,
            // deuda_total: 0,
            // transferencia_total: 0,
            // fecha_estimada_cobro: '',
            // obs: '',
        },
        fieldsValidation: Yup.object().shape({
            almacen_producto_id: StringRequired,
            precio_unitario: NumberRequired,
            cantidad: NumberIntegerMinOneRequired,
        }),
        fields: [
            {
                label: 'Seleccione producto de almacén',
                name: 'almacen_producto_id',
                type: 'selectSearchDetailCard',
                param: 'user_id',
                urlApi: `/user/${user_id}/almacenes/no-retornable`,
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Producto...',
                autoFocus: true,
                nameNumber: 'cantidad_total',
                labelNumberRestante: 'Restante',
                nameNumberRestante: 'cantidad_total_restante',
                alterField: 'cantidad',
                exclamationDefault: 'Seleccione un producto!',
                exclamationRestante: 'Stock insuficiente!',
                infoTags: [
                    {
                        label: 'Producto',
                        data: 'producto_nombre'
                    },
                    {
                        label: 'Estado',
                        data: 'estado_nombre'
                    },
                    {
                        label: 'En almacén',
                        data: 'cantidad_total',
                        mark: true
                    },
                ]
            },
            // {
            //     label: 'Seleccione producto',
            //     name: 'almacen_producto_id',
            //     type: 'selectSearch',
            //     param: 'user_id',
            //     urlApi: `/user/${user_id}/almacenes/no-retornable`,
            //     required: true,
            //     labelDescription: 'etiqueta',
            //     placeholder: 'Producto...',
            //     autoFocus: true,
            //     infoTags: [
            //         {
            //             label: 'Producto',
            //             data: 'producto_nombre'
            //         },
            //         {
            //             label: 'Estado',
            //             data: 'estado_nombre'
            //         },
            //         {
            //             label: 'En almacén',
            //             data: 'cantidad_total',
            //             mark: true
            //         },
            //     ]
            // },
            // {
            //     label: 'Buscar departamento',
            //     name: 'departamento_id',
            //     type: 'selectSearch',
            //     urlApi: '/departamentos',
            //     required: true,
            //     labelDescription: 'nombre',
            //     placeholder: 'Departamento...',
            //     autoFocus: false,
            //     defaultValue: { value: '1', label: 'Clientes' }
            // },
            // {
            //     label: 'Buscar usuario',
            //     name: 'user_id',
            //     type: 'subSelectSearch',
            //     param: 'departamento_id',
            //     urlApi: '/departamento/{param}/users',
            //     required: true,
            //     labelDescription: 'etiqueta',
            //     placeholder: 'Usuario...',
            //     autoFocus: false
            // },
            {
                label: 'Buscar consumidor',
                name: 'user_id',
                type: 'selectSearch',
                urlApi: '/users/consumidores',
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Consumidor...',
                autoFocus: false,
                // defaultValue: { value: '2', label: 'Clientes' }
            },
            {
                label: 'Precio unitario',
                name: 'precio_unitario',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const precio_unitario = e.target.valueAsNumber || 0;
                    const cantidad = values.cantidad || 0;
                    const venta_total = ToTwoDecimal(precio_unitario * cantidad);
                    setValues('venta_total', venta_total)

                    const efectivo_total = values.efectivo_total || 0;
                    const deuda_total = ToTwoDecimal(venta_total - efectivo_total)
                    setValues('deuda_total', deuda_total)
                }
            },
            {
                label: 'Cantidad',
                name: 'cantidad',
                type: 'numberCalculator',
                subData: null,
                required: true,
                locked: false,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const precio_unitario = values.precio_unitario || 0;
                    const venta_total = ToTwoDecimal(precio_unitario * cantidad);
                    setValues('venta_total', venta_total)

                    const efectivo_total = values.efectivo_total || 0;
                    const deuda_total = ToTwoDecimal(venta_total - efectivo_total)
                    setValues('deuda_total', deuda_total)

                    const cantidad_retorno = values.cantidad_retorno || 0
                    const cantidad_deuda = cantidad - cantidad_retorno
                    setValues('cantidad_deuda', cantidad_deuda)

                    const cantidad_total = values.cantidad_total || 0
                    const total = ToTwoDecimal(cantidad_total - cantidad)
                    setValues('cantidad_total_restante', total < 0 ? 0 : total)
                }
            },
            {
                label: 'Método de pago',
                name: 'metodo_pago',
                type: 'select',
                required: true,
                options: [
                    {
                        label: 'Efectivo',
                        value: 'efectivo'
                    },
                    {
                        label: 'Transferencia',
                        value: 'transferencia'
                    },
                    {
                        label: 'Cheque',
                        value: 'cheque'
                    },
                ],
            },
            {
                label: 'Total venta',
                name: 'venta_total',
                type: 'groupnumber',
                subData: 'Bs.',
                required: true,
                disabled: true,
                highlight: 'bg-blue-600 dark:bg-blue-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Total cobrado',
                name: 'efectivo_total',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const efectivo_total = e.target.valueAsNumber || 0;
                    const venta_total = values.venta_total || 0
                    const deuda_total = ToTwoDecimal(venta_total - efectivo_total)
                    setValues('deuda_total', deuda_total)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo') {
                        setAditionalClass('hidden')
                    }
                    else {
                        setAditionalClass('')
                    }
                },
                fieldDependency: 'metodo_pago',
                highlight: 'bg-green-600 dark:bg-green-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Saldo',
                name: 'deuda_total',
                type: 'groupnumber',
                subData: 'Bs.',
                required: true,
                disabled: true,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo') {
                        setAditionalClass('hidden')
                    }
                    else {
                        setAditionalClass('')
                    }
                },
                fieldDependency: 'metodo_pago',
                fieldDependencyExtra: 'cantidad',
                highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Pago confirmar',
                name: 'transferencia_total',
                type: 'groupnumber',
                subData: 'Bs.',
                required: false,
                disabled: true,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago === 'efectivo') {
                        setAditionalClass('hidden')
                        setValues('transferencia_total', 0)
                    }
                    else {
                        setAditionalClass('')
                        setValues('transferencia_total', values.venta_total)
                    }
                },
                fieldDependency: 'metodo_pago',
                highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Fecha estimada de cobro',
                name: 'fecha_estimada_cobro',
                type: 'date',
                required: false,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.deuda_total > 0 && values.metodo_pago === 'efectivo')
                        setAditionalClass('')
                    else
                        setAditionalClass('hidden')
                },
                fieldDependency: 'deuda_total',
                fieldDependencyExtra: 'metodo_pago',
                highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const GastoValues = (cuaderno_id) => {
    return {
        initialValues: {
            cuaderno_id: cuaderno_id,
            estado_gasto_id: '',
            monto: 0,
            obs: '',

        },
        fieldsValidation: Yup.object().shape({
            estado_gasto_id: StringRequired,
            monto: NumberRequired,
        }),
        fields: [
            {
                label: 'Tipo de gasto',
                name: 'estado_gasto_id',
                type: 'selectSearch',
                urlApi: "/nombreEstado/Gastos vendedores/subEstados",
                required: true,
                labelDescription: 'nombre',
                placeholder: 'Tipo de gasto...',
                autoFocus: false,
                // defaultValue: { value: '1', label: 'Clientes' }
            },

            {
                label: 'Monto del Gasto',
                name: 'monto',
                type: 'groupnumber',
                subData: 'Bs.',
                required: true,

            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'text',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const DevolucionDePrestamosValues = (cuaderno_id, user_id) => {
    return {
        initialValues: {
            cuaderno_id: cuaderno_id,
            deuda_envase_id: '',
            saldo: 0,
            saldo_restante: 0,
            cantidad: 0,
            obs: '',
        },
        fieldsValidation: Yup.object().shape({
            cantidad: NumberIntegerMinOneRequired,
            saldo_restante: NumberIntegerRequired,
        }),
        fields: [
            {
                label: 'Seleccion un usuario',
                name: 'deuda_envase_id',
                type: 'selectSearchDetailCard',
                // param: 'user_id',
                urlApi: `/user/${user_id}/prestamos`,
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Deudor...',
                autoFocus: true,
                nameNumber: 'saldo',
                labelNumberRestante: 'Restante',
                nameNumberRestante: 'saldo_restante',
                alterField: 'cantidad',
                exclamationDefault: 'Seleccione un deudor!',
                exclamationRestante: 'El resto debe ser mayor o igual a 0!',
                infoTags: [
                    {
                        label: 'Deudor',
                        data: 'envaseDeudorDetalles'
                    },
                    {
                        label: 'Producto',
                        data: 'producto_nombre'
                    },
                    {
                        label: 'Fecha',
                        data: 'createdAt',
                        isDateValue: true
                    },
                    {
                        label: 'Fecha límite',
                        data: 'fecha_limite',
                        isDateValue: true
                    },
                    {
                        label: 'Saldo',
                        data: 'saldo',
                        mark: true
                    },
                ]
            },
            {
                label: 'Envases devueltos',
                name: 'cantidad',
                type: 'numberCalculator',
                subData: null,
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const saldo = values.saldo || 0;
                    const saldo_restante = saldo - cantidad
                    setValues('saldo_restante', saldo_restante)
                }
            },
            {
                label: 'Envases restantes',
                name: 'saldo_restante',
                type: 'numberCalculator',
                subData: null,
                required: true,
                locked: true,
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const saldo = values.saldo || 0;
                    const saldo_restante = saldo - cantidad
                    setValues('saldo_restante', saldo_restante)
                },
                highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const DevolucionDeGarantiasValues = (cuaderno_id, user_id) => {
    return {
        initialValues: {
            cuaderno_id: cuaderno_id,
            deuda_envase_id: '',
            metodo_pago: 'efectivo',
            saldo: 0,
            saldo_restante: 0,
            cantidad: 0,
            monto: 0,
            monto_garantia: 0,
            monto_restante: 0,
            obs: '',
        },
        fieldsValidation: Yup.object().shape({
            cantidad: NumberIntegerRequired,
            saldo_restante: NumberIntegerRequired,
        }),
        fields: [
            {
                label: 'Seleccion un usuario',
                name: 'deuda_envase_id',
                type: 'selectSearchDetailCard',
                urlApi: `/user/${user_id}/garantias`,
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Deudor...',
                autoFocus: true,
                nameNumber: 'saldo',
                labelNumberRestante: 'Restante',
                nameNumberRestante: 'saldo_restante',
                alterField: 'cantidad',
                exclamationDefault: 'Seleccione un deudor!',
                exclamationRestante: 'El resto debe ser mayor o igual a 0!',
                setAction: (setValues, data) => {
                    if (data != null) {
                        setValues('monto_garantia', data.monto_garantia)
                        setValues('monto_restante', data.monto_garantia)
                    }
                    else {
                        setValues('monto_garantia', 0)
                        setValues('monto_restante', 0)
                        setValues('monto', 0)
                        setValues('cantidad', 0)
                        setValues('cantidad_restante', 0)
                    }
                },
                infoTags: [
                    {
                        label: 'Deudor',
                        data: 'envaseDeudorDetalles'
                    },
                    {
                        label: 'Producto',
                        data: 'producto_nombre'
                    },
                    {
                        label: 'Fecha',
                        data: 'createdAt',
                        isDateValue: true
                    },
                    {
                        label: 'Monto Garantía Bs.',
                        data: 'monto_garantia',
                        mark: true,
                    },
                    // {
                    //     label: 'Fecha límite:',
                    //     data: 'fecha_limite',
                    //     isDateValue: true
                    // },
                    {
                        label: 'Saldo',
                        data: 'saldo',
                        mark: true
                    },
                ]
            },
            {
                label: 'Método de pago',
                name: 'metodo_pago',
                type: 'select',
                required: true,
                options: [
                    {
                        label: 'Efectivo',
                        value: 'efectivo'
                    },
                    // {
                    //     label: 'Transferencia',
                    //     value: 'transferencia'
                    // },
                    // {
                    //     label: 'Cheque',
                    //     value: 'cheque'
                    // },
                ],
            },
            {
                label: 'Garantía',
                name: 'monto_garantia',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const monto_garantia = e.target.valueAsNumber || 0;
                    const monto = values.monto_garantia || 0
                    const monto_restante = monto_garantia - monto
                    setValues('monto_restante', monto_restante)
                },
                highlight: 'bg-green-600 dark:bg-green-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Monto devuelto',
                name: 'monto',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: false,
                ChangeCalculator: (values, setValues, e) => {
                    const monto = e.target.valueAsNumber || 0;
                    const monto_garantia = values.monto_garantia || 0
                    const monto_restante = monto_garantia - monto
                    setValues('monto_restante', monto_restante)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo')
                        setAditionalClass('hidden')
                    else
                        setAditionalClass('')
                },
                fieldDependency: 'metodo_pago',
            },
            {
                label: 'Garantía restante',
                name: 'monto_restante',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const monto = e.target.valueAsNumber || 0;
                    const monto_garantia = values.monto_garantia || 0
                    const monto_restante = monto_garantia - monto
                    setValues('monto_restante', monto_restante)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo')
                        setAditionalClass('hidden')
                    else
                        setAditionalClass('')
                },
                fieldDependency: 'metodo_pago',
                fieldDependencyExtra: 'monto_garantia',
                highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Envases recogidos',
                name: 'cantidad',
                type: 'numberCalculator',
                subData: null,
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const saldo = values.saldo || 0;
                    const saldo_restante = saldo - cantidad
                    setValues('saldo_restante', saldo_restante)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo')
                        setAditionalClass('hidden')
                    else
                        setAditionalClass('')
                },
                fieldDependency: 'metodo_pago',
            },
            {
                label: 'Envases restantes',
                name: 'saldo_restante',
                type: 'numberCalculator',
                subData: null,
                required: true,
                locked: true,
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const saldo = values.saldo || 0;
                    const saldo_restante = saldo - cantidad
                    setValues('saldo_restante', saldo_restante)
                },
                highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const CobrosValues = (cuaderno_id, user_id) => {
    return {
        initialValues: {
            cuaderno_id: cuaderno_id,
            deuda_id: '',
            metodo_pago: 'efectivo',
            saldo: 0,
            saldo_restante: 0,
            monto: 0,
            obs: '',
        },
        fieldsValidation: Yup.object().shape({
            cantidad: NumberIntegerRequired,
            saldo_restante: NumberIntegerRequired,
        }),
        fields: [
            {
                label: 'Seleccion un usuario',
                name: 'deuda_id',
                type: 'selectSearchDetailCard',
                urlApi: `/user/${user_id}/deudas`,
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Deudor...',
                autoFocus: true,
                nameNumber: 'saldo',
                labelNumberRestante: 'Restante',
                nameNumberRestante: 'saldo_restante',
                alterField: 'cantidad',
                exclamationDefault: 'Seleccione un deudor!',
                exclamationRestante: 'El resto debe ser mayor o igual a 0!',
                setAction: (setValues, data) => {
                    if (data != null) {
                        setValues('monto_garantia', data.monto_garantia)
                        setValues('monto_restante', data.monto_garantia)
                    }
                    else {
                        setValues('monto_garantia', 0)
                        setValues('monto_restante', 0)
                        setValues('monto', 0)
                        setValues('cantidad', 0)
                        setValues('cantidad_restante', 0)
                    }
                },
                infoTags: [
                    {
                        label: 'Deudor',
                        data: 'deudorDetalles'
                    },
                    {
                        label: 'Producto',
                        data: 'producto_nombre'
                    },
                    {
                        label: 'Fecha',
                        data: 'createdAt',
                        isDateValue: true
                    },
                    {
                        label: 'Saldo',
                        data: 'saldo',
                        mark: true
                    },
                ]
            },
            {
                label: 'Método de pago',
                name: 'metodo_pago',
                type: 'select',
                required: true,
                options: [
                    {
                        label: 'Efectivo',
                        value: 'efectivo'
                    },
                    // {
                    //     label: 'Transferencia',
                    //     value: 'transferencia'
                    // },
                    // {
                    //     label: 'Cheque',
                    //     value: 'cheque'
                    // },
                ],
            },
            {
                label: 'Saldo',
                name: 'saldo',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const saldo = e.target.valueAsNumber || 0;
                    const monto = values.saldo || 0
                    const monto_restante = saldo - monto
                    setValues('saldo_restante', monto_restante)
                },
                highlight: 'bg-green-600 dark:bg-green-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Monto devuelto',
                name: 'monto',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: false,
                ChangeCalculator: (values, setValues, e) => {
                    const monto = e.target.valueAsNumber || 0;
                    const saldo = values.saldo || 0
                    const monto_restante = saldo - monto
                    setValues('saldo_restante', monto_restante)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo')
                        setAditionalClass('hidden')
                    else
                        setAditionalClass('')
                },
                fieldDependency: 'metodo_pago',
            },
            {
                label: 'Saldo restante',
                name: 'saldo_restante',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const monto = e.target.valueAsNumber || 0;
                    const monto_garantia = values.monto_garantia || 0
                    const monto_restante = monto_garantia - monto
                    setValues('saldo_restante', monto_restante)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo')
                        setAditionalClass('hidden')
                    else
                        setAditionalClass('')
                },
                fieldDependency: 'metodo_pago',
                fieldDependencyExtra: 'monto',
                highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            },
            // {
            //     label: 'Envases recogidos',
            //     name: 'cantidad',
            //     type: 'numberCalculator',
            //     subData: null,
            //     required: true,
            //     ChangeCalculator: (values, setValues, e) => {
            //         const cantidad = e.target.valueAsNumber || 0;
            //         const saldo = values.saldo || 0;
            //         const saldo_restante = saldo - cantidad
            //         setValues('saldo_restante', saldo_restante)
            //     },
            //     ChangeCondition: (values, setValues, setAditionalClass) => {
            //         if (values.metodo_pago !== 'efectivo')
            //             setAditionalClass('hidden')
            //         else
            //             setAditionalClass('')
            //     },
            //     fieldDependency: 'metodo_pago',
            // },
            // {
            //     label: 'Envases restantes',
            //     name: 'saldo_restante',
            //     type: 'numberCalculator',
            //     subData: null,
            //     required: true,
            //     locked: true,
            //     disabled: true,
            //     ChangeCalculator: (values, setValues, e) => {
            //         const cantidad = e.target.valueAsNumber || 0;
            //         const saldo = values.saldo || 0;
            //         const saldo_restante = saldo - cantidad
            //         setValues('saldo_restante', saldo_restante)
            //     },
            //     highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            // },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

// export const CobrosValues = (cuaderno_id, user_id) => {
//     return {
//         initialValues: {
//             cuaderno_id: cuaderno_id,
//             cuenta_cliente_historial_id: '',
//             departamento_id: '1',
//             user_id: '',
//             metodo_intercambio: 'normal',
//             metodo_pago: 'efectivo',
//             cuenta_cliente_id: '',
//             deuda_total: 0,
//             obs: '',
//         },
//         fieldsValidation: Yup.object().shape({
//             almacen_producto_id: StringRequired,
//             precio_unitario: NumberRequired,
//             cantidad: NumberIntegerMinOneRequired,
//         }),
//         fields: [

//             {
//                 label: 'Buscar departamento',
//                 name: 'departamento_id',
//                 type: 'selectSearch',
//                 urlApi: '/departamentos',
//                 required: true,
//                 labelDescription: 'nombre',
//                 placeholder: 'Departamento...',
//                 autoFocus: false,
//                 defaultValue: { value: '1', label: 'Clientes' }
//             },
//             {
//                 label: 'Buscar usuario',
//                 name: 'user_id',
//                 type: 'subSelectSearch',
//                 param: 'departamento_id',
//                 urlApi: '/departamento/{param}/users',
//                 required: true,
//                 labelDescription: 'etiqueta',
//                 placeholder: 'Usuario...',
//                 autoFocus: false,
//                 infoTags: [
//                     {
//                         label: 'Producto',
//                         data: 'producto_nombre'
//                     },
//                     {
//                         label: 'Saldo',
//                         data: 'saldo'
//                     },
//                     {
//                         label: 'Fecha',
//                         data: 'Fecha',
//                     },
//                 ]
//             },
//             {
//                 label: 'Método de pago',
//                 name: 'metodo_pago',
//                 type: 'select',
//                 required: true,
//                 options: [
//                     {
//                         label: 'Efectivo',
//                         value: 'efectivo'
//                     },
//                     {
//                         label: 'Transferencia',
//                         value: 'transferencia'
//                     },
//                     {
//                         label: 'Cheque',
//                         value: 'cheque'
//                     },
//                 ],
//             },
//             {
//                 label: 'Monto',
//                 name: 'monto',
//                 type: 'groupnumber',
//                 subData: 'Bs.',
//                 required: true,

//             },
//             {
//                 label: 'Saldo Restante',
//                 name: 'saldo-restante',
//                 type: 'groupnumber',
//                 subData: 'Bs.',
//                 required: true,
//                 disabled: true,
//                 highlight: 'bg-blue-600 dark:bg-blue-500 bg-opacity-25 dark:bg-opacity-20'
//             },
//             {
//                 label: 'Observaciones',
//                 name: 'obs',
//                 type: 'text',
//                 required: false,
//                 placeholder: 'Observaciones...',
//                 autoFocus: false
//             },
//         ]
//     }
// }

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}