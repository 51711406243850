import React, { useEffect, useState } from 'react'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { Step4 } from './Step4'
import { Step5 } from './Step5'
import { useDispatch } from 'react-redux'
import { requestAuth } from '../../../http/httpRequest'
import { toastOn } from '../../../store/slices/toast'
import { Step6 } from './Step6'

export const StepCompraViews = ({ data, setReload }) => {
    const dispatch = useDispatch()
    const [detail, setDetail] = useState(null)
    const [mainRecall, setMainRecall] = useState(false)
    const [currentNav, setCurrentNav] = useState(data.paso)
    const getData = async () => {
        await requestAuth(
            'get',
            `compra/${data.id}/detail`,
            null
        )
            .then((response) => {
                setDetail(response.data)
                setCurrentNav(response.data.paso)
            }
            )
            .catch(error => {
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las opciones' }))
            })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainRecall])
    return (
        detail != null &&
        <>
            <div className="p-3 space-y-6 min-h-[550px]">
                {
                    currentNav === 1 &&
                    <Step1
                        generalData={detail}
                        setMainRecall={setMainRecall}
                        setReload={setReload}
                        stepNumber={1}
                    />
                }
                {
                    currentNav === 2 &&
                    <Step2
                        generalData={detail}
                        setMainRecall={setMainRecall}
                        setReload={setReload}
                        stepNumber={2}
                        saveUrlApi={`compra/${detail.id}/paso/2`}
                    />
                }
                {
                    currentNav === 3 &&
                    <Step3
                        generalData={detail}
                        setMainRecall={setMainRecall}
                        setReload={setReload}
                        stepNumber={3}
                        saveUrlApi={`compra/${detail.id}/paso/3`}
                    />
                }
                {
                    currentNav === 4 &&
                    <Step4
                        generalData={detail}
                        setMainRecall={setMainRecall}
                        setReload={setReload}
                        stepNumber={4}
                        saveUrlApi={`compra/${detail.id}/paso/4`}
                    />
                }
                {
                    currentNav === 5 &&
                    <Step5
                        generalData={detail}
                        setMainRecall={setMainRecall}
                        setReload={setReload}
                        stepNumber={5}
                        saveUrlApi={`compra/${detail.id}/paso/5`}
                    />
                }
                {
                    currentNav === 6 &&
                    <Step6
                        generalData={detail}
                        setMainRecall={setMainRecall}
                        setReload={setReload}
                        stepNumber={6}
                    // saveUrlApi={`compra/${detail.id}/paso/5`}
                    />
                }
            </div>
            <NavStepper
                detail={detail}
                setCurrentNav={setCurrentNav}
                currentNav={currentNav}
            />
        </>
    )
}

const NavStepper = ({ detail, setCurrentNav, currentNav }) => {
    return (
        <>
            <div className='col-span-12 flex justify-center gap-x-5 py-2 overflow-auto'>
                <div>
                    <ButtonStep
                        // check={detail?.paso > 1 ? true : false}
                        currentPaso={detail?.paso}
                        paso={1}
                        setCurrentNav={setCurrentNav}
                        currentNav={currentNav}
                    />
                </div>
                <div>
                    <ButtonStep
                        // check={detail?.paso > 2 ? true : false}
                        currentPaso={detail?.paso}
                        paso={2}
                        setCurrentNav={setCurrentNav}
                        currentNav={currentNav}
                    />
                </div>
                <div>
                    <ButtonStep
                        // check={detail?.paso > 3 ? true : false}
                        currentPaso={detail?.paso}
                        paso={3}
                        setCurrentNav={setCurrentNav}
                        currentNav={currentNav}
                    />
                </div>
                <div>
                    <ButtonStep
                        // check={detail?.paso > 4 ? true : false}
                        currentPaso={detail?.paso}
                        paso={4}
                        setCurrentNav={setCurrentNav}
                        currentNav={currentNav}
                    />
                </div>
                <div>
                    <ButtonStep
                        // check={detail?.paso > 5 ? true : false}
                        currentPaso={detail?.paso}
                        paso={5}
                        setCurrentNav={setCurrentNav}
                        currentNav={currentNav}
                    />
                </div>
            </div>
        </>
    )
}

const ButtonStep = ({ paso, setCurrentNav, currentPaso, currentNav }) => {
    return (
        <>
            <button
                type='button'
                className={`rounded-full ${currentPaso > paso ? 'bg-green-400 dark:bg-green-600 text-gray-50 dark:text-gray-50' : 'bg-gray-100 text-gray-400 dark:bg-gray-500 dark:text-gray-600'} py-[4px] px-2 text-sm dark:hover:bg-gray-500 transition ease-linear`}
                onClick={() => {
                    setCurrentNav(paso)
                }}
            >
                <i className='fa-solid fa-check' />
            </button>
            <div className={`mt-2 ${currentNav === paso ? 'bg-gray-400' : 'bg-gray-200 dark:bg-gray-600'} h-1 rounded-lg`} />
        </>
    )
}