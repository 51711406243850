import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requestDefaulAuth } from '../../http/httpRequest'
import { useNavigate } from 'react-router-dom'

export const MainTargetInfo = ({
    extraClassName,
    children,
    urlApi,
    setData,
    dataRef,
    reload,
}) => {
    const reloadMainTable = useSelector(state => state.login.reloadMainTable)
    const dipatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const getData = async () => {
        setLoading(true)
        await requestDefaulAuth(
            'get',
            urlApi,
            null,
            setData,
            dipatch
        )
            .then(() => {
                setLoading(false)
            })
    }
    useEffect(() => {
        // setLoading(true)
        getData();
        // setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadMainTable, dataRef, reload]);
    return (
        <div className={`relative grid ${extraClassName} overflow-hidden`}>
            {children}
            <>
                {
                    loading &&
                    <div className="absolute w-full top-0 h-full flex justify-center items-center overflow-hidden bg-[rgba(31,41,55,0.2)] dark:bg-gray-800/40 rounded-md">
                        <div >
                            <svg aria-hidden="true" className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-500 dark:fill-yellow-500 fill-yellow-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
            </>
        </div>
    )
}
export const LogoTargetInfo = ({ logo }) => {
    return (
        <div className='flex p-4 items-center text-gray-700 dark:text-gray-300 text-6xl'>
            <i className={`fa-solid ${logo}`}></i>
        </div>
    )
}

export const LogoLabelTargetInfo = ({ logo }) => {
    return (
        <div className='flex p-4 items-center text-gray-700 dark:text-gray-300 text-6xl'>
            {logo}
        </div>
    )
}

export const TitleTargetInfo = ({ label, data }) => {
    return (
        <>
            <h5 className='font-semibold text-gray-800 dark:text-gray-400'>{label} <span className='font-bold text-lg bg-green-500 text-white dark:text-gray-800 px-1'>{data}</span></h5>
        </>
    )
}

export const SubTitleTargetInfo = ({ label, data }) => {
    return (
        <h5 className='font-semibold text-gray-800 dark:text-gray-400'>{label} <span className='font-bold text-lg bg-orange-500 text-white dark:text-gray-800 px-1'>{data}</span></h5>
    )
}

export const DefautlSubTitleTargetInfo = ({ label, data }) => {
    return (
        <h5 className='font-semibold text-gray-800 dark:text-gray-400'>{label} <span className='font-bold text-lg bg-gray-400 text-white dark:text-gray-800 px-1'>{data}</span></h5>
    )
}

export const DescriptionTargetInfo = ({ label, data }) => {
    return (
        <h5 className='font-semibold text-gray-700 dark:text-gray-400'>{label} <span className='text-green-600 dark:text-green-500'>{data}</span></h5>
    )
}

export const NumberTargetInfo = ({ label, data }) => {
    return (
        <h5 className='font-semibold text-gray-700 dark:text-gray-400'>{label} <span className='text-red-500 dark:text-red-400'>{data || 0}</span></h5>
    )
}

export const NumberCalculatorsTargetInfo = ({ children }) => {
    return (
        <div className='sm:col-span-1 col-span-2 grid grid-cols-2 p-2 bg-white rounded-md shadow dark:border-gray-700 dark:bg-gray-800'>
            {children}
        </div>
    )
}

export const NumberGridTargetInfo = ({ label, data, borderBotton = true }) => {
    return (
        <>
            <div className={`text-gray-700 dark:text-gray-400 border-r-[1px] ${borderBotton ? 'border-b-[1px]' : 'border-b-transparent'} border-neutral-500 border-dashed`}>
                {label}
            </div>
            <div className={`font-semibold text-red-600 dark:text-red-400 text-right ${borderBotton ? 'border-b-[1px] ' : 'border-b-transparent'} border-neutral-500 border-dashed`}>
                {data}
            </div>
        </>
    )
}

export const EmptyGridTargetInfo = ({ label, data, borderBotton = true }) => {
    return (
        <>
            <div className={`text-transparent ${borderBotton ? 'border-b-[1px]' : 'border-b-transparent'} border-neutral-500 border-dashed`}>
                {label}
            </div>
            <div className={`font-semibold text-red-600 dark:text-red-400 text-right ${borderBotton ? 'border-b-[1px]' : 'border-b-transparent'} border-neutral-500 border-dashed`}>
                {data}
            </div>
        </>
    )
}

export const ValueTargetInfo = ({ label, data, icon }) => {
    return (
        <h5 className='font-semibold text-gray-800 dark:dark:text-gray-400'>{label} <span className='text-red-400'>{data || '-'} </span>{icon ? <i className='fa-solid fa-lock text-gray-500' /> : ''}</h5>
    )
}

export const ItemCardTargetInfo = ({ logo, children, logoLabel, aditionalClassName = '', backTo = '..' }) => {
    const navigate = useNavigate()
    return (
        <div className={`relative flex flex-grow ${aditionalClassName} bg-white rounded-md shadow dark:border-gray-700 dark:bg-gray-800`}>
            {
                logo ?
                    logo
                    :
                    logoLabel
            }
            <div className='flex flex-col md:justify-start p-4'>
                {children}
            </div>
            {
                (logo || logoLabel) &&
                <button onClick={() => navigate(backTo)} className='absolute px-[6px] my-[-1px] right-0'>
                    <i className='fa-solid fa-close text-gray-300 dark:text-gray-400' />
                </button>
            }
        </div>
    )
}