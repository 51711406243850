import * as Yup from 'yup';

export const UpdateValues = (data) => {
    console.log(data)
    return {
        initialValues: {
            id: data?.id || '',
            nombre: data?.nombre || '',
            descripcion: data?.descripcion || '',
            main_estado_id: data?.main_estado_id ? (data?.main_estado_id + '') : ''
        },
        fieldsValidation: Yup.object().shape({
            nombre: Yup.string()
                .min(2, 'Mínimo 2 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
        }),
        fields: [
            {
                label: "Nombre",
                name: "nombre",
                type: "text",
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: "Descripción",
                name: "descripcion",
                type: "text",
                required: true,
                placeholder: 'Descripción...',
                autoFocus: true
            },
            {
                label: "Pertenece a:",
                name: "main_estado_id",
                type: "radio",
                required: true,
                urlApi: `/estados/${data?.id}`,
            },
        ]
    }
}

export const AddAddSubEstadosValues = (data) => {
    let subEstados = []
    data?.subEstados?.forEach(estado => {
        subEstados.push(estado.id + "")
    });
    return {
        initialValues: {
            id: data?.id || '',
            subEstados: subEstados
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            {
                label: "Adicionar elementos a la lista",
                name: "subEstados",
                type: "checkboxes",
                required: false,
                urlApi: `/estados/${data?.id}/subEstados`,
                indexLabel: 'nombre'
            },
        ]
    }
}

export const CreateValues = () => {
    return {
        initialValues: {
            id: '',
            nombre: '',
            descripcion: '',
            main_estado_id: ''
        },
        fieldsValidation: Yup.object().shape({
            nombre: Yup.string()
                .min(3, 'Mínimo 3 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
        }),
        fields: [
            {
                label: "Nombre",
                name: "nombre",
                type: "text",
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: "Descripción",
                name: "descripcion",
                type: "text",
                required: false,
                placeholder: 'Descripción...',
                // autoFocus: true
            },
            {
                label: "Pertenece a:",
                name: "main_estado_id",
                type: "radio",
                required: true,
                urlApi: "/estados/null",
            },
        ]
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}