import React, { useState } from 'react'
import { ListEfectivoProductoTable } from './ListEfectivoProductoTable'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { EfectivoHistorialIngresos } from './efectivo_historial_ingresos'
import { EfectivoHistorialEgresos } from './efectivo_historial_egresos'
import { ErrorPage } from '../../../../error/errorPage'
import CardInfoEfectivo from '../elementosGenerales/CardInfo'

export const EfectivoProducto = ({ mainReloadTable, setMainReloadTable }) => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <div className='grid grid-cols-1 gap-2'>
            <div className='col-span-1 dark:bg-[#4a5c68] bg-[#4a5c6840] p-1 rounded-md'>
                <CardInfoEfectivo
                    reload={reload}
                    data={data}
                    setData={setData}
                    nameLabel='PRODUCTOS'
                />
                <ListEfectivoProductoTable
                    reload={reload}
                    setReload={setReload}
                    dataCard={data}
                    mainReloadTable={mainReloadTable}
                    setMainReloadTable={setMainReloadTable}
                />
            </div>
            <Routes>
                <Route index element={
                    null
                } />
                <Route
                    path={`/productos/:sub_efectivo_id/*`}
                    element={<EfectivoProducto
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                    />}
                />
                <Route
                    path={`/movimientos/:sub_efectivo_id/*`}
                    element={<EfectivoHistorialIngresos
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                    />}
                />
                <Route
                    path={`/movimientos_egresos/:sub_efectivo_id/*`}
                    element={<EfectivoHistorialEgresos
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                    />}
                />
                {/* <Route
                    path={`/cierres/:efectivo_id/*`}
                    element={<EfectivoCierresHistorial
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                    />}
                /> */}
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </div>
    )
}
