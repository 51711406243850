import React from 'react'
import { useParams } from 'react-router-dom';
import { DescriptionTargetInfo, ItemCardTargetInfo, LogoTargetInfo, MainTargetInfo, NumberCalculatorsTargetInfo, NumberGridTargetInfo, SubTitleTargetInfo } from '../../../../../../../components/card/MainTargetInfo';
import { formatDate } from '../../../../../../../utils/dateFormat';

const CardInfo = ({ reload, data, setData }) => {
    const params = useParams()
    return (
        <MainTargetInfo
            extraClassName='grid-cols-1 lg:grid-cols-2 gap-1'
            urlApi={`/almacenCierre/${params.almacen_cierre_id}`}
            setData={setData}
            dataRef={params.almacen_cierre_id}
            reload={reload}
        >
            <ItemCardTargetInfo
                logo={<LogoTargetInfo logo='fa-boxes-packing' />}
                aditionalClassName='col-span-2 md:col-span-1'
            >
                <SubTitleTargetInfo label={'CIERRE'} data={data?.nombre} />
                <DescriptionTargetInfo label='Desde:' data={formatDate(data?.fecha_inicio)} />
                <DescriptionTargetInfo label='Hasta:' data={formatDate(data?.fecha_fin)} />
            </ItemCardTargetInfo>
            {/* <ItemCardTargetInfo>
                <NumberTargetInfo label='Total ingreso' data={data?.cantidad_ingreso} />
                <NumberTargetInfo label='Total egreso' data={data?.cantidad_egreso} />
                <NumberTargetInfo label='Total' data={data?.cantidad} />
            </ItemCardTargetInfo> */}
            <NumberCalculatorsTargetInfo>
                <NumberGridTargetInfo label='Cantidad inicial' data={data?.cantidad_inicial} />
                <NumberGridTargetInfo label='Cantidad final' data={data?.cantidad_final} />
                <NumberGridTargetInfo label='Total ingreso' data={data?.cantidad_total_ingreso} />
                <NumberGridTargetInfo label='Total egreso' data={data?.cantidad_total_egreso} borderBotton={false} />
                {/* <EmptyGridTargetInfo label='.' data={''} borderBotton={false} />
                <EmptyGridTargetInfo label='.' data={''} borderBotton={false} /> */}
            </NumberCalculatorsTargetInfo>
            {/* <NumberCalculatorsTargetInfo>
                <NumberGridTargetInfo label='Venta Bs.' data={data?.monto_venta} />
                <NumberGridTargetInfo label='Efectivo Bs.' data={data?.monto_efectivo} />
                <NumberGridTargetInfo label='Deuda Bs.' data={data?.monto_deuda} />
                <NumberGridTargetInfo label='Garantía Bs.' data={data?.monto_garantia} borderBotton={false} />

            </NumberCalculatorsTargetInfo> */}
        </MainTargetInfo>
    )
}

export default CardInfo