export function iconByNameMenu(name) {
    switch (name) {
        default:
            return 'fa-solid fa-list-check dark:text-yellow-500 text-yellow-700'
    }
}

export function iconSelectByNameMenu(name) {
    switch (name) {
        default:
            return 'fa-solid fa-angle-right'
    }
}