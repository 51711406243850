import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../../../hooks/useDataPaginate'
import { formatDateWithTime } from '../../../../../../utils/dateFormat'
import { CreateValues, IngresoCuadernoValues } from '../../../../../../FormSchemes/EfectivoGeneralHistorialScheme'
import { MinimalActionButton } from '../../../../../../components/form/minimalActionButton'
import { CreateValuesEfectivoTraspasosExterno } from '../../../../../../FormSchemes/EfectivoProductoScheme'
import { setReloadSubTable } from '../../../../../../store/slices/auth/authSlice'

export const ListEfectivoHistorialIngresoTable = ({
    reload,
    setReload,
    dataCard,
    mainReloadTable,
    setMainReloadTable
}) => {
    const {
        dispatch,
        params,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
        // UpdateValuesModal,
        // redirect
    } = useGeneralParams('nombre')

    const [ingresoCuadernoModal, setIngresoCuadernoModal] = useState(false)
    // const [modalTraspasoExterno, setModalTraspasoExterno] = useState(false);
    const [traspasosModal, setTraspasosModal] = useState(false)

    const getDataPaginate = async () => {
        await requestAuthPaginate(
            'get',
            `/efectivo/${params.sub_efectivo_id}/ingreso/historial/pag`,
            null,
            paginate,
            setData,
            setStateData,
            setPaginate,
            dispatch
        )
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters, params.sub_efectivo_id]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters]);

    const recall = () => {
        getDataPaginate()
        setReload(!reload)
        setMainReloadTable(!mainReloadTable)
    }

    return (
        <Section>
            <ActionSection>
                {
                    <Actions
                        buttons={[
                            {
                                icon: 'add',
                                label: 'Ingreso directo',
                                action: () => setCreateModal(true),
                                className: 'dark:bg-green-700 bg-green-500 text-white'
                            },
                            // {
                            //     icon: 'add',
                            //     label: 'Ingreso de cuaderno',
                            //     action: () => setIngresoCuadernoModal(true),
                            //     className: 'dark:bg-cyan-700 bg-cyan-500 text-white'
                            // },
                            // {
                            //     icon: 'minus',
                            //     label: 'Traspasos',
                            //     action: () => setTraspasosModal(true),
                            //     className: 'dark:bg-red-700 bg-red-500 text-white'
                            // },
                            // {
                            //     icon: 'minus',
                            //     label: 'Egreso directo',
                            //     action: () => setEgresoDirectoModal(true),
                            //     className: 'dark:bg-red-700 bg-red-500'
                            // },
                        ]}
                    />
                }
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: "Nombre",
                            value: "nombre"
                        },
                        {
                            label: "Estado",
                            value: "estado"
                        }
                    ]}
                />
                <div className="flex gap-2 flex-wrap">
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: "Estado",
                                value: ""
                            },
                            {
                                label: "Activo",
                                value: "activo"
                            },
                            {
                                label: "Inactivo",
                                value: "inactivo"
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={recall}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Origen / Destino',
                            columns: ['user_nombres', 'user_apellidos', 'proveedor_encargado']
                        },
                        {
                            label: 'Monto anterior Bs.',
                            columns: ['monto_anterior'],
                            numberValue: true,
                        },
                        {
                            label: 'Monto Bs.',
                            columns: ['monto'],
                            numberValue: true,
                        },
                        {
                            label: 'Monto actual Bs.',
                            columns: ['monto_actual'],
                            numberValue: true,
                        },
                        {
                            label: 'Billetes Bs.',
                            columns: ['billetes'],
                            numberValue: true,
                        },
                        {
                            label: 'Monedas Bs.',
                            columns: ['monedas'],
                            numberValue: true,
                        },
                        {
                            label: 'Dolares en Bs.',
                            columns: ['dolares_en_bs'],
                            numberValue: true,
                        },
                        {
                            label: 'ingreso / egreso',
                            columns: ['ingreso'],
                            booleanState: true,
                            booleanOptions: ['ingreso', 'egreso']
                        },
                        {
                            label: 'CÓDIGO DE TRANSACCIÓN',
                            columns: ['codigo_transaccion', 'tipo'],
                        },
                        {
                            label: 'CONFIRMACIÓN',
                            columns: ['confirmado'],
                            boolean: true,
                            booleanOptions: ['Confirmado', 'Pendiente'],
                            booleanIconTrue: 'fa-check',
                            booleanIconFalse: 'fa-xmark'
                        },
                        {
                            label: 'fecha de registro',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime
                        },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {/* <Paginator
                paginate={paginate}
                setPaginate={setPaginate}
            /> */}
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label="Registrar Ingreso directo de efectivo"
                    dataValues={CreateValues(params.sub_efectivo_id, true)}
                    urlApi={`/efectivo_historial`}
                    method={'post'}
                    call={() => {
                        recall()
                        dispatch(setReloadSubTable())
                        // dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                ingresoCuadernoModal &&
                <ModalForm
                    setModal={setIngresoCuadernoModal}
                    label="Registrar Ingreso de cuaderno"
                    dataValues={IngresoCuadernoValues(params.sub_efectivo_id, true)}
                    urlApi={`/efectivo_historial`}
                    method={'post'}
                    call={() => {
                        recall()
                        dispatch(setReloadSubTable())
                        // dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                traspasosModal &&
                <ModalForm
                    setModal={setTraspasosModal}
                    label='Cargar almacenes'
                    dataValues={CreateValuesEfectivoTraspasosExterno(dataCard)}
                    urlApi={`/efectivoTraspasos`}
                    method={'post'}
                    call={() => {
                        recall()
                        dispatch(setReloadSubTable())
                        // dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }

            {/* {
                deleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label="Eliminar producto del almacén"
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/almacen_producto/${currentData.id}`}
                    method={'delete'}
                    call={recall}
                    buttonLabel='Eliminar'
                />
            } */}
        </Section>
    )
}