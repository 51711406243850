import React from 'react'
import { useParams } from 'react-router-dom';
import { DescriptionTargetInfo, ItemCardTargetInfo, LogoTargetInfo, MainTargetInfo, NumberCalculatorsTargetInfo, NumberGridTargetInfo, SubTitleTargetInfo, TitleTargetInfo } from '../../../../../../components/card/MainTargetInfo';
import { formatDate } from '../../../../../../utils/dateFormat';

const CardInfo = ({ reload, data, setData }) => {
    const params = useParams()
    return (
        <MainTargetInfo
            extraClassName='grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1'
            urlApi={`/almacenProducto/${params.almacen_producto_id}`}
            setData={setData}
            dataRef={params.almacen_producto_id}
            reload={reload}
        >
            <ItemCardTargetInfo
                logo={<LogoTargetInfo logo='fa-boxes-packing' />}
                aditionalClassName='col-span-2'
            >
                <TitleTargetInfo label='' data={data?.producto_nombre} />
                <SubTitleTargetInfo label={null} data={data?.estado_nombre} />
                <DescriptionTargetInfo label={data?.estado_descripcion} data={null} />
                <DescriptionTargetInfo label='' data={formatDate(data?.createdAt)} />
            </ItemCardTargetInfo>
            {/* <ItemCardTargetInfo>
                <NumberTargetInfo label='Total ingreso' data={data?.cantidad_ingreso} />
                <NumberTargetInfo label='Total egreso' data={data?.cantidad_egreso} />
                <NumberTargetInfo label='Total' data={data?.cantidad} />
            </ItemCardTargetInfo> */}
            <NumberCalculatorsTargetInfo>
                <NumberGridTargetInfo label='Existente' data={data?.cantidad} />
                <NumberGridTargetInfo label='Deudas' data={data?.cantidad_deuda} />
                <NumberGridTargetInfo label='Garantías' data={data?.cantidad_garantia} />
                <NumberGridTargetInfo label='Total' data={data?.cantidad_total} borderBotton={false} />
                {/* <EmptyGridTargetInfo label='.' data={''} borderBotton={false} />
                <EmptyGridTargetInfo label='.' data={''} borderBotton={false} /> */}
            </NumberCalculatorsTargetInfo>
            <NumberCalculatorsTargetInfo>
                <NumberGridTargetInfo label='Venta Bs.' data={data?.monto_venta} />
                <NumberGridTargetInfo label='Efectivo Bs.' data={data?.monto_efectivo} />
                <NumberGridTargetInfo label='Deuda Bs.' data={data?.monto_deuda} />
                <NumberGridTargetInfo label='Garantía Bs.' data={data?.monto_garantia} borderBotton={false} />

            </NumberCalculatorsTargetInfo>
        </MainTargetInfo>
    )
}

export default CardInfo