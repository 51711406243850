import React, { useEffect } from 'react'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { CreateValues, DeleteValues } from '../../../../../FormSchemes/AlmacenProductoScheme'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'

export const ListProductoEstadoTable = ({
    reload,
    setReload,
    dataCard
}) => {
    const {
        dispatch,
        params,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        deleteModal, setDeleteModal,
        currentData,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        // formatFilters,
        Searcher,
        // FilterSelect,
        // RangeDate,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
        // UpdateValuesModal,
        // redirect
    } = useGeneralParams('producto')

    const getDataPaginate = async () => {
        await requestAuthPaginate(
            'get',
            `/producto/${params.producto_id}/estados/pag`,
            null,
            paginate,
            setData,
            setStateData,
            setPaginate,
            dispatch
        )
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters, params.producto_id]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters]);

    const recall = () => {
        getDataPaginate()
        setReload(!reload)
    }

    const reloadTable = () => {
        getDataPaginate()
    }

    return (
        <Section>
            <ActionSection>
                <Actions
                    buttons={[
                        {
                            icon: 'add',
                            label: 'Crear',
                            action: () => setCreateModal(true)
                        },
                    ]}
                />
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Producto',
                            value: 'producto'
                        },
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    {/* <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Estado',
                                value: ''
                            },
                            {
                                label: 'Activo',
                                value: 'activo'
                            },
                            {
                                label: 'Inactivo',
                                value: 'inactivo'
                            }
                        ]}
                    /> */}
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={recall}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Estado del producto',
                            columns: ['estado_nombre'],
                            tag: true
                        },
                        {
                            label: 'Precio estándar Bs.',
                            columns: ['precio'],
                            numberValue: true,
                        },
                        // {
                        //     label: 'Acciones',
                        //     actions: [
                        //         // {
                        //         //     type: 'edit',
                        //         //     icon: 'fa-edit',
                        //         //     // action: (data) => UpdateValuesModal(data, setCurrentData, setUpdateModal),
                        //         // },
                        //         {
                        //             type: 'yellow',
                        //             icon: 'fa-share',
                        //             action: (data) => redirect(`historial/${data.id}`),
                        //             reference: 'id'
                        //         },
                        //         {
                        //             type: 'cyan',
                        //             icon: 'fa-shop-lock',
                        //             action: (data) => redirect(`cierres/${data.id}`),
                        //             reference: 'id',
                        //             tooltipText: 'Cierres de almacén'
                        //         },
                        //         {
                        //             type: 'delete',
                        //             icon: 'fa-trash',
                        //             action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                        //             reference: 'id'
                        //         },
                        //     ],
                        //     // stickyR: true
                        // },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {/* <Paginator
                paginate={paginate}
                setPaginate={setPaginate}
            /> */}
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label='Crear almacén'
                    dataValues={CreateValues(params?.almacen_id)}
                    urlApi={`/almacenProductos`}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {/*{
                updateModal &&
                <ModalForm
                    setModal={setUpdateModal}
                    label='Editar almacén'
                    dataValues={UpdateValues(currentData)}
                    urlApi={'/almacen'}
                    method={'put'}
                    call={recall}
                    buttonLabel='Editar'
                />
            }*/}
            {
                deleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar producto del almacén'
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/almacen_producto/${currentData.id}`}
                    method={'delete'}
                    call={recall}
                    buttonLabel='Eliminar'
                />
            }
        </Section>
    )
}