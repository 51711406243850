import React, { useState } from 'react'
import CardInfoEfectivo from '../elementosGenerales/CardInfo';
import { ListEfectivoHistorialTable } from './ListEfectivoHistorialTable';

export const EfectivoHistorial = ({ mainReloadTable, setMainReloadTable }) => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <>
            <div className='col-span-1 dark:bg-[#4a5c68] bg-[#4a5c6840] p-1 rounded-md'>
                <CardInfoEfectivo
                    reload={reload}
                    data={data}
                    setData={setData}
                    nameLabel='CIERRE'
                />
                <ListEfectivoHistorialTable
                    reload={reload}
                    setReload={setReload}
                    dataCard={data}
                    mainReloadTable={mainReloadTable}
                    setMainReloadTable={setMainReloadTable}
                />
            </div>
        </>
    )
}
