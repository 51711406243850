import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { CreateAlmacenProveedorValues, CreatePersonalValues, CreateValues, DeleteValues } from '../../../../FormSchemes/AlmacenScheme'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'

export const ListAlmacenTable = () => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        // updateModal, setUpdateModal,
        deleteModal, setDeleteModal,
        currentData, setCurrentData,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        // RangeDate,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
        UpdateValuesModal,
        redirect
    } = useGeneralParams('nombre')
    const [createAlmacenPersonalModal, setCreateAlmacenPersonalModal] = useState(false)
    const [createAlmacenProveedorModal, setCreateAlmacenProveedorModal] = useState(false)
    const getDataPaginate = async () => {
        await requestAuthPaginate(
            'get',
            `/almacens/pag`,
            null,
            paginate,
            setData,
            setStateData,
            setPaginate,
            dispatch
        )
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters]);

    const recall = () => {
        getDataPaginate()
    }

    return (
        <Section>
            <ActionSection>
                <Actions
                    buttons={[
                        // {
                        //     icon: 'repeat',
                        //     label: '',
                        //     action: recall
                        // },
                        {
                            icon: 'add',
                            label: 'Crear Almacen General',
                            action: () => setCreateModal(true),
                            // className: 'bg-cyan-500 dark:bg-cyan-700 text-white',
                        },
                        {
                            icon: 'add',
                            label: 'Crear Almacen para el personal',
                            action: () => setCreateAlmacenPersonalModal(true),
                            // // className: 'bg-cyan-500 dark:bg-cyan-700 text-white',
                        },
                        {
                            icon: 'add',
                            label: 'Crear Almacen para proveedores',
                            action: () => setCreateAlmacenProveedorModal(true),
                            // // className: 'bg-cyan-500 dark:bg-cyan-700 text-white',
                        },
                    ]}
                />
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombre'
                        },
                        {
                            label: 'Encargado',
                            value: 'encargado'
                        }
                    ]}
                    reloadTable={recall}
                />
                <div className='flex gap-2 flex-wrap'>
                    {/* <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    /> */}
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='tipo_almacen'
                        options={[
                            {
                                label: 'Todos',
                                value: ''
                            },
                            {
                                label: 'General',
                                value: 'general'
                            },
                            {
                                label: 'Personal',
                                value: 'personal'
                            },
                            {
                                label: 'Proveedor',
                                value: 'proveedor'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={recall}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        // {
                        //     label: 'Nombre',
                        //     columns: ['nombre']
                        // },
                        {
                            label: 'Almacén',
                            columns: ['nombre', 'codigo', 'nombres', 'proveedor']
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'yellow',
                                    icon: 'fa-share',
                                    action: (data) => redirect(`productos/${data.id}`),
                                    reference: 'id',
                                    tooltipText: 'Ver productos'
                                },
                                {
                                    type: 'teal',
                                    icon: 'fa-boxes-packing',
                                    action: (data) => redirect(`compras/${data.id}`),
                                    reference: 'id',
                                    tooltipText: 'Compras'
                                },
                                // {
                                //     type: 'edit',
                                //     icon: 'fa-edit',
                                //     action: (data) => UpdateValuesModal(data, setCurrentData, setUpdateModal),
                                // },
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id'
                                },
                            ],
                            // stickyR: true
                        },
                    ]}
                    data={data.data}
                    checkList={false}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label='Crear almacén general'
                    dataValues={CreateValues()}
                    urlApi={'/almacen'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                createAlmacenPersonalModal &&
                <ModalForm
                    setModal={setCreateAlmacenPersonalModal}
                    label='Crear almacén para personal'
                    dataValues={CreatePersonalValues()}
                    urlApi={'/almacens'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                createAlmacenProveedorModal &&
                <ModalForm
                    setModal={setCreateAlmacenProveedorModal}
                    label='Crear almacén para proveedor'
                    dataValues={CreateAlmacenProveedorValues()}
                    urlApi={'/almacens'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {/* {
                updateModal &&
                <ModalForm
                    setModal={setUpdateModal}
                    label='Editar almacén'
                    dataValues={UpdateValues(currentData)}
                    urlApi={'/almacen'}
                    method={'put'}
                    call={recall}
                    buttonLabel='Editar'
                />
            } */}
            {
                deleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar almacén'
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/almacen/${currentData.id}`}
                    method={'delete'}
                    call={recall}
                    buttonLabel='Eliminar'
                />
            }
        </Section>
    )
}