import React from "react"
import { ListDeudaTable } from "./ListDeudaTable"
import { Route, Routes } from "react-router-dom"
import { HistorialDeudas } from "./historial_deudas"
import { ErrorPage } from "../../../error/errorPage"


export const Deudas = () => {
    return (
        <div className='grid xl:grid-cols-1 gap-2'>
            <div className='col-span-2 xl:col-span-1'>
                <ListDeudaTable />
            </div>
            <Routes>
                <Route index element={
                    null
                } />
                    <Route
                        path={`deudas/:deuda_id/*`}
                        element={<HistorialDeudas />}
                    />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </div>
    )
}