import * as Yup from 'yup';

export const CreateValues = () => {
    return {
        initialValues: {
            nombre: '',
            descripcion: '',
            permitir_cuaderno: false,
            permitir_almacen: false,
            consumidor: false,
            empleado: false,
            responsable_vehiculo: false
        },
        fieldsValidation: Yup.object().shape({
            nombre: Yup.string()
                .min(4, 'Mínimo 4 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Nombre',
                name: 'nombre',
                type: 'text',
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: 'Descripción',
                name: 'descripcion',
                type: 'text',
                required: false,
                placeholder: 'Descripción...',
            },
            {
                label: '¿Permitir abrir cuaderno?',
                name: 'permitir_cuaderno',
                type: 'checkbox',
            },
            {
                label: '¿Permitir gestionar almacén?',
                name: 'permitir_almacen',
                type: 'checkbox',
            },
            {
                label: '¿És consumidor?',
                name: 'consumidor',
                type: 'checkbox',
            },
            {
                label: '¿És empleado?',
                name: 'empleado',
                type: 'checkbox',
            },
            {
                label: '¿És responsable de un vehiculo?',
                name: 'responsable_vehiculo',
                type: 'checkbox',
            },
        ]
    }
}

export const UpdateValues = (data) => {
    return {
        initialValues: {
            id: data?.id || '',
            nombre: data?.nombre || '',
            descripcion: data?.descripcion || '',
            permitir_cuaderno: data?.permitir_cuaderno || false,
            permitir_almacen: data?.permitir_almacen || false,
            consumidor: data?.consumidor || false,
            empleado: data?.empleado || false,
            responsable_vehiculo: data?.responsable_vehiculo || false
        },
        fieldsValidation: Yup.object().shape({
            nombre: Yup.string()
                .min(4, 'Mínimo 4 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Nombre',
                name: 'nombre',
                type: 'text',
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: 'Descripción',
                name: 'descripcion',
                type: 'text',
                required: false,
                placeholder: 'Descripción...',
            },
            {
                label: '¿Permitir abrir cuaderno?',
                name: 'permitir_cuaderno',
                type: 'checkbox',
            },
            {
                label: '¿Permitir gestionar almacén?',
                name: 'permitir_almacen',
                type: 'checkbox',
            },
            {
                label: '¿És consumidor?',
                name: 'consumidor',
                type: 'checkbox',
            },
            {
                label: '¿És empleado?',
                name: 'empleado',
                type: 'checkbox',
            },
            {
                label: '¿És responsable de un vehiculo?',
                name: 'responsable_vehiculo',
                type: 'checkbox',
            },
        ]
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}