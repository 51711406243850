import React, { useEffect } from 'react'
import { useGeneralParams } from '../../../../../../hooks/useDataPaginate'
import { CreateValues, DeleteValues } from '../../../../../../FormSchemes/AlmacenCierreScheme'
import { MinimalActionButton } from '../../../../../../components/form/minimalActionButton'
import { useSelector } from 'react-redux'
import { formatDateWithTime } from '../../../../../../utils/dateFormat'

export const ListAlmacenCierreTable = ({
    reload,
    setReload,
    dataCard
}) => {
    const {
        dispatch,
        params,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        deleteModal, setDeleteModal,
        currentData, setCurrentData,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        // formatFilters,
        Searcher,
        // FilterSelect,
        // RangeDate,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
        UpdateValuesModal,
        redirect
    } = useGeneralParams('producto')
    const reloadMainTable = useSelector(state => state.login.reloadMainTable)

    const getDataPaginate = async () => {
        await requestAuthPaginate(
            'get',
            `/almacenProducto/${params.almacen_producto_id}/cierres/pag`,
            null,
            paginate,
            setData,
            setStateData,
            setPaginate,
            dispatch
        )
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters, params.almacen_producto_id, reloadMainTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters]);

    const recall = () => {
        getDataPaginate()
        setReload(!reload)
    }

    const reloadTable = () => {
        getDataPaginate()
    }

    return (
        <Section>
            <ActionSection>
                <Actions
                    buttons={[
                        {
                            icon: 'shop-lock',
                            label: 'Crear cierre',
                            action: () => setCreateModal(true)
                        },
                    ]}
                />
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Producto',
                            value: 'producto'
                        },
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    {/* <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Estado',
                                value: ''
                            },
                            {
                                label: 'Activo',
                                value: 'activo'
                            },
                            {
                                label: 'Inactivo',
                                value: 'inactivo'
                            }
                        ]}
                    /> */}
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={recall}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Nombre',
                            columns: ['nombre']
                        },
                        {
                            label: 'Cantidad inicial',
                            columns: ['cantidad_inicial'],
                            numberValue: true
                        },
                        {
                            label: 'Cantidad final',
                            columns: ['cantidad_final'],
                            numberValue: true
                        },
                        {
                            label: 'Cantidad ingreso',
                            columns: ['cantidad_total_ingreso'],
                            numberValue: true
                        },
                        {
                            label: 'Cantidad egreso',
                            columns: ['cantidad_total_egreso'],
                            numberValue: true
                        },
                        {
                            label: 'Fecha de inicio',
                            columns: ['fecha_inicio'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        {
                            label: 'Fecha de fin',
                            columns: ['fecha_fin'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                // {
                                //     type: 'edit',
                                //     icon: 'fa-edit',
                                //     // action: (data) => UpdateValuesModal(data, setCurrentData, setUpdateModal),
                                // },
                                {
                                    type: 'yellow',
                                    icon: 'fa-share',
                                    action: (data) => redirect(`historial/${data.id}`),
                                    reference: 'id'
                                },
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id',
                                    tooltipText: 'eliminar'
                                },
                            ],
                            // stickyR: true
                        },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                deleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label="Eliminar cierre del almacén"
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/almacenCierre/${currentData.id}`}
                    method={'delete'}
                    call={recall}
                    buttonLabel='Eliminar'
                />
            }
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label="Crear cierre"
                    dataValues={CreateValues(params?.almacen_producto_id)}
                    urlApi={`/almacenCierre`}
                    method={'post'}
                    call={recall}
                    buttonLabel='Crear cierre'
                />
            }
        </Section>
    )
}