import React, { useState } from 'react'
// import MainTargetInfo from './MainTargetInfo'
import { ListAlmacenCierreTable } from './ListAlmacenCierreTable'
import CardInfo from './CardInfo'
import { Route, Routes } from 'react-router-dom';
import { AlmacenHistorials } from './almacen_historials_cierres';
import { ErrorPage } from '../../../../../error/errorPage';

export const AlmacenCierresHistorial = () => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <>
            <div className='col-span-1 dark:bg-[#4a5c68] bg-[#4a5c6830] p-1 rounded-md mb-2'>
                <CardInfo
                    reload={reload}
                    data={data}
                    setData={setData}
                // setReload={setReload}
                />
                <ListAlmacenCierreTable
                    reload={reload}
                    setReload={setReload}
                    data={data}
                    setData={setData}
                />
            </div>
            <div className='col-span-1 xl:col-span-2 mt-2'>
                <Routes>
                    <Route index element={
                        null
                    } />
                    <Route
                        path={`/historial/:almacen_cierre_id`}
                        element={<AlmacenHistorials />}
                    />
                    <Route path='*' element={<ErrorPage />} />
                </Routes>
            </div>
        </>
    )
}
