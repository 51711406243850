import React from 'react'

export const MinimalActionButton = ({ icon, label, action, className }) => {
    return (
        <button
            type='button'
            // title='asd'
            className={`${className != null ? className : 'dark:bg-gray-700 bg-white'} border border-gray-300 dark:border-gray-700 dark:shadow-gray-800 shadow-gray-500 dark:hover:bg-gray-800 hover:bg-gray-500 hover:text-gray-100 text-black dark:text-white font-semibold rounded-lg max-w-[150px] flex items-center mx-0 px-2 py-1 portrait:m-0`}
            onClick={action}
        >
            <i className={`fa fa-${icon} text-xs`} />{label && <span className='text-[13px]'> {label}</span>}
        </button>
        // <div className='flex gap-1 flex-wrap'>
        //     </div>
    )
}
