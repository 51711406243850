import { Field } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toastOn } from '../../store/slices/toast'
import { requestAuth } from '../../http/httpRequest'
import Select from 'react-select'
import { colourStyles } from '../../utils/styleFormat'
import { AlmacenTraspasosForm } from './AlmacenTraspasosForm'
import { formatDefaultDate } from '../../utils/dateFormat'
import { AlmacenTraspasosIternosForm } from './AlmacenTraspasosIternosForm'
import { AlmacenTraspasosIngresosForm } from './AlmacenTraspasosIngresosForm'
import { EfectivoTraspasosForm } from './EfectivoTraspasosForm'
import { CuadernoEfectivoForm } from './CuadernoEfectivoForm'
import { CuadernoEfectivoPersonalForm } from './CuadernoEfectivoPersonalForm'
import { ExtraerSubEfectivoForm } from './ExtraerSubEfectivoForm'
import { SelectTable } from './SelectTable'
import { SolicitarCompraForm } from './SolicitarCompraForm'

export const ModalFormField = ({ fields, errors, touched, values, setValues, handleChange, submitForm }) => {
    return (
        fields?.map((field, index) => (
            <React.Fragment
                key={index}
            >
                <FieldParam
                    field={field}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setValues={setValues}
                    handleChange={handleChange}
                    submitForm={submitForm}
                />
            </React.Fragment>
        ))
    )
}


const FieldParam = ({ field, errors, touched, values, setValues, handleChange, submitForm }) => {

    const [aditionalClass, setAditionalClass] = useState('');
    if (field.highlight === null || field.highlight === '' || field.highlight === undefined)
        field.highlight = 'bg-gray-50 dark:bg-gray-600'

    useEffect(() => {
        if (field.fieldDependency !== undefined && field.fieldDependency !== null) {
            field.ChangeCondition(values, setValues, setAditionalClass)
        }
        if (field.fieldDependencyExtra !== null && field.fieldDependencyExtra !== undefined) {
            field.ChangeCondition(values, setValues, setAditionalClass)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values[field.fieldDependency], values[field.fieldDependencyExtra]]);

    switch (field.type) {
        case 'text':
        case 'email':
        case 'password':
        case 'date':
        case 'datetime-local':
        case 'number':
            return (
                <div className={`${aditionalClass} col-span-6 sm:col-span-4 md:col-span-3`} >
                    <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                        {field.label}
                        {field.required && <RequiredPick />}
                    </label>
                    <Field
                        type={field.type}
                        name={field.name}
                        id={field.name}
                        className={`shadow-sm outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-semibold disabled:opacity-55 disabled:text-gray-500 dark:disabled:text-gray-400 ${field.highlight}`}
                        placeholder={field.placeholder}
                        required={field.required}
                        autoFocus={field.autoFocus}
                        disabled={field.disabled}
                        autoComplete='new-password'
                    />
                    {
                        field.locked ?
                            <ErrorLockLabel
                                name={field.name}
                                errors={errors}
                                submitForm={submitForm}
                            />
                            :
                            <ErrorLabel
                                name={field.name}
                                errors={errors}
                                touched={touched}
                            />
                    }
                </div>
            )
        case 'groupnumber':
            return (
                <div className={`${aditionalClass} col-span-6 sm:col-span-4 md:col-span-3`} >
                    <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                        {field.label}
                        {field.required && <RequiredPick />}
                    </label>
                    <div className='flex'>
                        <Field
                            type={'number'}
                            name={field.name}
                            id={field.name}
                            step='0.1'
                            className={`shadow-sm outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border border-gray-300 text-gray-900 text-sm rounded-l-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-semibold disabled:opacity-55 disabled:text-gray-500 dark:disabled:text-gray-400 ${field.highlight}`}
                            placeholder={field.placeholder}
                            required={field.required}
                            autoFocus={field.autoFocus}
                            disabled={field.disabled}
                        // validate={(value) => {
                        //     if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                        //         return 'Ingresa un número decimal válido';
                        //     }
                        // }}
                        />
                        <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-gray-300 rounded-r-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-500'>
                            {field.subData}
                        </span>
                    </div>
                    {
                        field.locked ?
                            <ErrorLockLabel
                                name={field.name}
                                errors={errors}
                            />
                            :
                            <ErrorLabel
                                name={field.name}
                                errors={errors}
                                touched={touched}
                            />
                    }
                </div>
            )
        case 'groupnumberCalculator':
            return (
                <div className={`${aditionalClass} col-span-6 sm:col-span-4 md:col-span-3`} >
                    <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                        {field.label}
                        {field.required && <RequiredPick />}
                    </label>
                    <div className='flex'>
                        <Field
                            type={'number'}
                            name={field.name}
                            id={field.name}
                            step='0.1'
                            className={`shadow-sm outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border border-gray-300 text-gray-900 text-sm rounded-l-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 ${field.highlight} dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-semibold disabled:opacity-55 disabled:text-gray-500 dark:disabled:text-gray-400`}
                            placeholder={field.placeholder}
                            required={field.required}
                            autoFocus={field.autoFocus}
                            disabled={field.disabled}
                            autoComplete='new-password'
                            onChange={(e) => {
                                handleChange(e)
                                field.ChangeCalculator(values, setValues, e)
                            }}
                        />
                        <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-gray-300 rounded-r-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-500'>
                            {field.subData}
                        </span>
                    </div>
                    {
                        field.locked ?
                            <ErrorLockLabel
                                name={field.name}
                                errors={errors}
                            />
                            :
                            <ErrorLabel
                                name={field.name}
                                errors={errors}
                                touched={touched}
                            />
                    }
                </div>
            )
        case 'numberCalculator':
            return (
                <div className={`${aditionalClass} col-span-6 sm:col-span-4 md:col-span-3`} >
                    <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                        {field.label}
                        {field.required && <RequiredPick />}
                    </label>
                    <Field
                        type={'number'}
                        name={field.name}
                        id={field.name}
                        step={'any'}
                        className={`shadow-sm outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 ${field.highlight} dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-semibold disabled:opacity-55 disabled:text-gray-500 dark:disabled:text-gray-400`}
                        placeholder={field.placeholder}
                        required={field.required}
                        autoFocus={field.autoFocus}
                        disabled={field.disabled}
                        autoComplete='new-password'
                        onChange={(e) => {
                            handleChange(e)
                            field.ChangeCalculator(values, setValues, e)
                        }}
                    />
                    {
                        field.locked ?
                            <ErrorLockLabel
                                name={field.name}
                                errors={errors}
                            />
                            :
                            <ErrorLabel
                                name={field.name}
                                errors={errors}
                                touched={touched}
                            />
                    }
                </div>
            )
        case 'textArea':
            return (
                <div className='col-span-12 md:col-span-6 xl:col-span-9' >
                    <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                        {field.label}
                        {field.required && <RequiredPick />}
                    </label>
                    <textarea
                        rows={field.rows}
                        name={field.name}
                        id={field.name}
                        className='block p-2.5 w-full outline-none text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                        placeholder={field.placeholder}
                        required={field.required}
                        autoFocus={field.autoFocus}
                        value={values[field?.name]}
                        onChange={handleChange}
                    />
                    {
                        field.locked ?
                            <ErrorLockLabel
                                name={field.name}
                                errors={errors}
                            />
                            :
                            <ErrorLabel
                                name={field.name}
                                errors={errors}
                                touched={touched}
                            />
                    }
                </div>
            )
        case 'divider':
            return (
                <div className='col-span-12'>
                    <hr className='dark:border-gray-500 border-gray-300' />
                </div>
            )
        case 'checkbox':
            return (
                <label
                    htmlFor={field.name}
                    className='col-span-6 sm:col-span-4 md:col-span-3 p-2 rounded-lg dark:bg-gray-600 bg-gray-200 flex items-center border border-gray-300 dark:border-gray-500 select-none'
                >
                    <Field
                        type={field.type}
                        name={field.name}
                        id={field.name}
                        className='form-checkbox outline-none dark:accent-pink-500 accent-yellow-500 h-6 w-6 rounded bg-gray-50 focus:ring-3 focus:ring-yellow-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 text-yellow-600'
                    />
                    <span className='m-2 text-sm font-semibold text-gray-900 dark:text-white'>
                        {field.label}
                    </span>
                </label>
            )
        case 'select':
            return (
                <div className='col-span-6 sm:col-span-4 md:col-span-3' >
                    <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                        {field.label}
                        {field.required && <RequiredPick />}
                    </label>
                    <Field
                        id={field.name}
                        as='select'
                        name={field.name}
                        required={field.required}
                        className='bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    >
                        <option value=''>...</option>
                        {
                            field?.options?.map((option, index) => (
                                <option value={option?.value} key={index}>{option?.label}</option>
                            ))
                        }
                    </Field>
                    {
                        field.locked ?
                            <ErrorLockLabel
                                name={field.name}
                                errors={errors}
                            />
                            :
                            <ErrorLabel
                                name={field.name}
                                errors={errors}
                                touched={touched}
                            />
                    }
                </div>
            )
        case 'selectCondition':
            return (
                <div className='col-span-6 sm:col-span-4 md:col-span-3' >
                    <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                        {field.label}
                        {field.required && <RequiredPick />}
                    </label>
                    <Field
                        id={field.name}
                        as='select'
                        name={field.name}
                        required={field.required}
                        className='bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    >
                        <option value=''>...</option>
                        {
                            field?.options?.map((option, index) => (
                                <option value={option?.value} key={index} disabled={option.disabled ? option.disabled(values) : false}>{option?.label}</option>
                            ))
                        }
                    </Field>
                    {
                        field.locked ?
                            <ErrorLockLabel
                                name={field.name}
                                errors={errors}
                            />
                            :
                            <ErrorLabel
                                name={field.name}
                                errors={errors}
                                touched={touched}
                            />
                    }
                </div>
            )
        case 'selectApi':
            return (
                <SelectApi
                    field={field}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                />
            )
        case 'doubleSelectApi':
            return (
                <DoubleSelectApi
                    field={field}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setValues={setValues}
                    handleChange={handleChange}
                />
            )
        case 'selectSearch':
            return (
                <SelectSearch
                    field={field}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setValues={setValues}
                    handleChange={handleChange}
                    aditionalClass={aditionalClass}
                />
            )
        case 'selectSearchStatic':
            return (
                <SelectSearchStatic
                    field={field}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setValues={setValues}
                    handleChange={handleChange}
                    aditionalClass={aditionalClass}
                />
            )
        case 'showSelectedData':
            return (
                <ShowSelectedData
                    field={field}
                />
            )
        case 'selectSearchDetailCard':
            return (
                <SelectSearchDetailCard
                    field={field}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setValues={setValues}
                    handleChange={handleChange}
                />
            )
        case 'subSelectSearch':
            return (
                <SubSelectSearch
                    field={field}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setValues={setValues}
                    handleChange={handleChange}
                />
            )
        case 'subSelectDinamicSearch':
            return (
                <SubSelectDinamicSearch
                    field={field}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setValues={setValues}
                    handleChange={handleChange}
                />
            )
        case 'checkboxes':
            return (
                <Checkboxes
                    field={field}
                    values={values}
                    setValues={setValues}
                />
            )
        case 'solicitarCompra':
            return (
                <SolicitarCompraForm
                    field={field}
                    values={values}
                    setValues={setValues}
                />
            )
        case 'almacenTraspasosForm':
            return (
                <AlmacenTraspasosForm
                    field={field}
                    values={values}
                    setValues={setValues}
                />
            )
        case 'efectivoTraspasosForm':
            return (
                <EfectivoTraspasosForm
                    field={field}
                    values={values}
                    setValues={setValues}
                />
            )
        case 'extraerSubEfectivoForm':
            return (
                <ExtraerSubEfectivoForm
                    field={field}
                    values={values}
                    setValues={setValues}
                />
            )
        case 'almacenTraspasosIngresosForm':
            return (
                <AlmacenTraspasosIngresosForm
                    field={field}
                    values={values}
                    setValues={setValues}
                />
            )
        case 'almacenTraspasosIternosForm':
            return (
                <AlmacenTraspasosIternosForm
                    field={field}
                    values={values}
                    setValues={setValues}
                />
            )
        case 'cuadernoField':
            return (
                <CuadernoField
                    field={field}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setValues={setValues}
                    handleChange={handleChange}
                />
            )
        case 'cuadernoEfectivo':
            return (
                <CuadernoEfectivoForm
                    field={field}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setValues={setValues}
                    handleChange={handleChange}
                />
            )
        case 'cuadernoEfectivoPersonal':
            return (
                <CuadernoEfectivoPersonalForm
                    field={field}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setValues={setValues}
                    handleChange={handleChange}
                />
            )
        case 'selectTable':
            return (
                <SelectTable
                    field={field}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setValues={setValues}
                    handleChange={handleChange}
                />
            )
        case 'radio':
            return (
                <RadioCheck field={field} />
            )
        case 'radioStatic':
            return (
                <RadioStaticCheck field={field} />
            )
        default:
            break
    }
}

const Checkboxes = ({
    field,
    values,
    setValues,
}) => {
    const dispatch = useDispatch()
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)
    const getData = async () => {
        await requestAuth(
            'get',
            field?.urlApi,
            null
        )
            .then((response) => {
                setList(response.data)
                setLoading(false)
            }
            )
            .catch(error => {
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las casillas.' }))
            })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleSelectAll = () => {
        if (values[field.name]?.length > 0)
            setValues(field.name, []);
        else
            setValues(field.name, list?.map(l => l.id + ''));
    };

    return (
        <div className='col-start-1 col-end-13 dark:text-gray-100 bg-gray-100 dark:bg-gray-700 border border-gray-200 dark:border-gray-500 rounded p-3' >
            <div className='text-right'>
                <button
                    type='button'
                    onClick={handleSelectAll}
                    className='text-sm'
                >
                    {
                        // values[field.name]?.length === list.length && list?.length > 0 ?
                        values[field.name]?.length > 0 ?
                            <>
                                <i className='fa-solid fa-square-check text-yellow-600' /> Desmarcar todo
                            </>
                            :
                            <>
                                <i className='fa-regular fa-square dark:text-gray-100 text-gray-700' /> Seleccionar todo
                            </>
                    }
                </button>
            </div>
            <div className='text-center text-sm font-bold pb-2'>{field.label}</div>
            <div className='grid sm:grid-cols-3 gap-3 max-h-80 overflow-auto'>
                {
                    list?.map((item, index) => (
                        <div key={index} className='select-none'>
                            <label htmlFor={item.nombre} className='col-span-6 sm:col-span-2 p-2 rounded-lg dark:bg-gray-600 bg-gray-200 flex items-center border border-gray-300 dark:border-gray-500' >
                                <Field
                                    type='checkbox'
                                    name={field.name}
                                    id={item.nombre}
                                    className='form-checkbox outline-none dark:accent-pink-500 accent-yellow-500 h-6 w-6 rounded bg-gray-50 focus:ring-3 focus:ring-yellow-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 text-yellow-600'
                                    value={item.id + ''}
                                    disabled={item?.permanent}
                                />
                                <span className='m-2 text-sm font-semibold text-gray-900 dark:text-white'>
                                    {item[field.indexLabel]}
                                </span>
                            </label>
                        </div>
                    ))
                }
            </div>
            {
                !loading && list.length === 0 &&
                <div className='text-center text-sm mt-3 text-gray-500 dark:text-gray-400'>
                    (Lista vacía, no se encuentran opciones disponibles)
                </div>
            }
        </div>
    )
}

const RadioCheck = ({
    field
}) => {
    const dispatch = useDispatch()
    const [list, setList] = useState([])
    const getData = async () => {
        await requestAuth(
            'get',
            field?.urlApi,
            null
        )
            .then((response) => {
                setList(response.data)
            }
            )
            .catch(error => {
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las casillas.' }))
            })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className='col-start-1 col-end-13 dark:text-gray-100 bg-gray-100 dark:bg-gray-700 border border-gray-200 dark:border-gray-500 rounded p-3' >
            <div className='text-center text-sm font-bold pb-2'>{field.label}</div>
            <div className='grid sm:grid-cols-3 gap-3 max-h-80 overflow-auto'>
                <div className='select-none'>
                    <label htmlFor={'ninguno'} className='col-span-6 sm:col-span-2 p-2 rounded-lg dark:bg-gray-600 bg-gray-200 flex items-center border border-gray-300 dark:border-gray-500' >
                        <Field
                            type='radio'
                            name={field.name}
                            id={'ninguno'}
                            className='form-checkbox outline-none dark:accent-pink-500 accent-yellow-500 h-5 w-5 rounded-full bg-gray-50 focus:ring-3 focus:ring-pink-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-pink-600 dark:ring-offset-gray-800 text-pink-600'
                            value={''}
                        // disabled={item?.permanent}
                        />
                        <span className='m-2 text-sm font-semibold text-gray-500 dark:text-gray-300'>
                            (ninguno)
                        </span>
                    </label>
                </div>
                {
                    list?.map((item, index) => (
                        <div key={index} className='select-none'>
                            <label htmlFor={item.nombre} className='col-span-6 sm:col-span-2 p-2 rounded-lg dark:bg-gray-600 bg-gray-200 flex items-center border border-gray-300 dark:border-gray-500' >
                                <Field
                                    type='radio'
                                    name={field.name}
                                    id={item.nombre}
                                    className='form-checkbox outline-none dark:accent-pink-500 accent-yellow-500 h-5 w-5 rounded-full bg-gray-50 focus:ring-3 focus:ring-yellow-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 text-yellow-600'
                                    value={item.id + ''}
                                    disabled={item?.permanent}
                                />
                                <span className='m-2 text-sm font-semibold text-gray-900 dark:text-white'>
                                    {item.nombre}
                                </span>
                            </label>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const RadioStaticCheck = ({
    field
}) => {
    return (
        <div className='col-start-1 col-end-13 dark:text-gray-100 bg-gray-100 dark:bg-gray-700 border border-gray-200 dark:border-gray-500 rounded p-3' >
            <div className='text-center text-sm font-bold pb-2'>{field.label}</div>
            <div className='grid sm:grid-cols-3 gap-3 max-h-80 overflow-auto'>
                {
                    field.checOptions?.map((item, index) => (
                        <div key={index} className='select-none'>
                            <label htmlFor={item.nombre} className='col-span-6 sm:col-span-2 p-2 rounded-lg dark:bg-gray-600 bg-gray-200 flex items-center border border-gray-300 dark:border-gray-500' >
                                <Field
                                    type='radio'
                                    name={field.name}
                                    id={item.nombre}
                                    className='form-checkbox outline-none dark:accent-pink-500 accent-yellow-500 h-5 w-5 rounded-full bg-gray-50 focus:ring-3 focus:ring-yellow-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 text-yellow-600'
                                    value={item.id}
                                    disabled={item?.permanent}
                                    required={field.required}
                                />
                                <span className='m-2 text-sm font-semibold text-gray-900 dark:text-white'>
                                    {item.nombre}
                                </span>
                            </label>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const SelectApi = ({ field, handleChange, errors, touched }) => {
    const dispatch = useDispatch()
    const [list, setList] = useState([])
    const [extraData, setExtraData] = useState(null)
    const getData = async () => {
        await requestAuth(
            'get',
            field?.urlApi,
            null
        )
            .then((response) => {
                setList(response.data)
            }
            )
            .catch(error => {
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las opciones' }))
            })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className='col-span-6 sm:col-span-4 md:col-span-3' >
            <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                {field.label}
                {field.required && <RequiredPick />}
            </label>
            <Field
                id={field.name}
                as='select'
                name={field.name}
                required={field.required}
                className='bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-semibold'
                onChange={(e) => {
                    handleChange(e)
                    setExtraData(list.find(l => (l.id + '') === e.target.value))
                }}
            >
                <option value=''>...</option>
                {
                    list?.map((option, index) => (
                        <option value={option?.id} key={index}>{option[field.optionDescription]}</option>
                    ))
                }
            </Field>
            {
                field.locked ?
                    <ErrorLockLabel
                        name={field.name}
                        errors={errors}
                    />
                    :
                    <ErrorLabel
                        name={field.name}
                        errors={errors}
                        touched={touched}
                    />
            }
            {
                field.infoTags && field.infoTags.length > 0 && extraData &&
                <div className='space-y-1 gap-x-1 text-xs dark:bg-gray-800 bg-gray-200 p-1 rounded-b-lg dark:text-gray-400 text-gray-700'>
                    {
                        field.infoTags?.map((info, index) => (
                            <div
                                key={index}
                                className='p-0 leading-none'
                            >
                                <span className='font-bold dark:text-gray-400 text-gray-600'>{info.label}:</span> <span className={`${info.mark ? 'dark:text-red-500 text-red-600 font-bold' : 'font-semibold dark:text-gray-300 text-gray-800'}`}>{extraData[info.data]}</span>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}

const DoubleSelectApi = ({ field, errors, touched, values, setValues, handleChange }) => {
    const dispatch = useDispatch()
    const [list, setList] = useState([])
    const [subList, setSubList] = useState([])
    const [extraData, setExtraData] = useState(null)
    const [called, setCalled] = useState(false)
    const getData = async () => {
        await requestAuth(
            'get',
            field?.urlApi,
            null
        )
            .then((response) => {
                setList(response.data)
            }
            )
            .catch(error => {
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las opciones' }))
            })
    }

    useEffect(() => {
        getData()
        if (values[field.name] !== null && values[field.name] !== '')
            definedSubList(values[field.name])

        setCalled(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const definedSubList = async (value) => {
        if (called) {
            setValues(field.sub_name, '')
            setSubList([])
        }
        if (value !== null && value !== '' && value !== undefined) {
            await requestAuth(
                'get',
                field?.sub_urlApi.replace('{param}', value),
                null
            )
                .then((response) => {
                    setSubList(response.data)
                }
                )
                .catch(error => {
                    dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las opciones' }))
                })
        }
        else {
            values[field.sub_name] = ''
        }
    }
    return (
        <>
            <div className='col-span-6 sm:col-span-4 md:col-span-3' >
                <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                    {field.label}
                    {field.required && <RequiredPick />}
                </label>
                <Field
                    id={field.name}
                    as='select'
                    name={field.name}
                    required={field.required}
                    onChange={(e) => {
                        handleChange(e)
                        definedSubList(e.target.value)
                        setExtraData(null)
                    }}
                    className='bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                >
                    <option value=''>...</option>
                    {
                        list?.map((option, index) => (
                            <option value={option?.id} key={index}>{option[field.optionDescription]}</option>
                        ))
                    }
                </Field>
                {
                    field.locked ?
                        <ErrorLockLabel
                            name={field.name}
                            errors={errors}
                        />
                        :
                        <ErrorLabel
                            name={field.name}
                            errors={errors}
                            touched={touched}
                        />
                }
            </div>
            {
                // subList.length > 0 &&
                <>
                    < div className='col-span-6 sm:col-span-4 md:col-span-3' >
                        <label htmlFor={field.sub_name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                            {field.sub_label}
                            {field.required && <RequiredPick />}
                        </label>
                        <Field
                            id={field.sub_name}
                            as='select'
                            name={field.sub_name}
                            required={field.required}
                            className='bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                            onChange={(e) => {
                                handleChange(e)
                                setExtraData(subList.find(l => (l.id + '') === e.target.value))
                            }}
                        >
                            <option value=''>...</option>
                            {
                                subList?.map((option, index) => (
                                    <option value={option?.id} key={index}>{option[field.sub_optionDescription]}</option>
                                ))
                            }
                        </Field>
                        <ErrorLabel
                            name={field.sub_name}
                            errors={errors}
                            touched={touched}
                        />
                        {
                            field.sub_infoTags && field.sub_infoTags.length > 0 && extraData &&
                            <div className='space-y-1 gap-x-1 text-xs dark:bg-gray-800 bg-gray-200 p-1 rounded-b-lg dark:text-gray-400 text-gray-700'>
                                {
                                    field.sub_infoTags?.map((info, index) => (
                                        <div
                                            key={index}
                                            className='p-0 leading-none'
                                        >
                                            <span className='font-bold dark:text-gray-400 text-gray-600'>{info.label}:</span> <span className={`${info.mark ? 'dark:text-red-500 text-red-600 font-bold' : 'font-semibold dark:text-gray-300 text-gray-800'}`}>{extraData[info.data]}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div >
                </>
            }
        </>
    )
}

export const RequiredPick = () => {
    return (
        <span className='dark:text-red-500 text-red-600 font-extrabold'>
            *
        </span>
    )
}

const ErrorLabel = ({ name, errors, touched }) => {
    return (
        errors[name] && touched[name] ? (
            <span className='text-xs text-red-600 dark:text-red-500 font-semibold absolute'>{errors[name]}</span>
        ) : null
    )
}

const ErrorLockLabel = ({ name, errors, submitForm }) => {
    return (
        errors[name] || submitForm ? (
            <span className='text-xs text-red-600 dark:text-red-500 font-semibold absolute'>{errors[name]}</span>
        ) : null
    )
}

const ErrorLockDescription = ({ message }) => {
    return (
        <div className='text-red-500 text-xs'><i className='fa-solid fa-triangle-exclamation' /> {message}</div>
    )
}

const AlertLockDescription = ({ message }) => {
    return (
        <div className='text-yellow-600 dark:text-yellow-500 text-xs'><i className='fa-solid fa-circle-exclamation' /> {message}</div>
    )
}

const SelectSearch = ({
    field,
    errors,
    touched,
    values,
    setValues,
    handleChange,
    aditionalClass
}) => {
    const [options, setOptions] = useState([])
    const [data, setData] = useState([])
    const [extraData, setExtraData] = useState(null)
    const dispatch = useDispatch()
    const getData = async () => {
        await requestAuth(
            'get',
            field?.urlApi,
            null
        )
            .then((response) => {
                const opt = response.data.map(item => ({
                    value: item[field.labelId] ?? item.id.toString(),
                    label: item[field.labelDescription]
                }))
                setData(response.data)
                setOptions(opt)
            }
            )
            .catch(error => {
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las opciones' }))
            })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className={`${aditionalClass} col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3`}>
            <div className='grid grid-cols-1'>
                <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                    {field.label}
                    {field.required && <RequiredPick />}
                </label>
                <Field name={field.name}>
                    {() => (
                        <Select
                            id={field.name}
                            defaultValue={field.defaultValue}
                            placeholder={field.placeholder}
                            options={options}
                            isClearable={true}
                            onChange={(e) => {
                                handleChange(field.name, e)
                                setValues(field.name, e?.value ?? '')
                                setExtraData(data.find(l => (l.id + '') === e?.value))
                            }}
                            styles={colourStyles}
                            autoFocus={field.autoFocus}
                            maxMenuHeight={150}
                            noOptionsMessage={() => 'No hay opciones disponibles'}
                        />
                    )}
                </Field>
            </div>
            {
                field.locked ?
                    <ErrorLockLabel
                        name={field.name}
                        errors={errors}
                    />
                    :
                    <ErrorLabel
                        name={field.name}
                        errors={errors}
                        touched={touched}
                    />
            }
            {
                field.infoTags && field.infoTags.length > 0 && extraData &&
                <div className='space-y-1 gap-x-1 text-xs dark:bg-gray-800 bg-gray-200 p-1 rounded-b-lg dark:text-gray-400 text-gray-700'>
                    {
                        field.infoTags?.map((info, index) => (
                            <div
                                key={index}
                                className='p-0 leading-none'
                            >
                                <span className='font-bold dark:text-gray-400 text-gray-600'>{info.label}:</span> <span className={`${info.mark ? 'dark:text-red-500 text-red-600 font-bold' : 'font-semibold dark:text-gray-300 text-gray-800'}`}>{extraData[info.data]}</span>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}

const SelectSearchStatic = ({
    field,
    errors,
    touched,
    values,
    setValues,
    handleChange,
    aditionalClass
}) => {
    const [options, setOptions] = useState([])

    useEffect(() => {
        // setData(field.data)
        setOptions(field.data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className={`${aditionalClass} col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3`}>
            <div className='grid grid-cols-1'>
                <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                    {field.label}
                    {field.required && <RequiredPick />}
                </label>
                <Field name={field.name}>
                    {() => (
                        <Select
                            id={field.name}
                            defaultValue={field.defaultValue}
                            placeholder={field.placeholder}
                            options={options}
                            isClearable={true}
                            onChange={(e) => {
                                handleChange(field.name, e)
                                setValues(field.name, e?.value ?? '')
                                // setExtraData(data.find(l => (l.id + '') === e?.value))
                            }}
                            required={field.required}
                            styles={colourStyles}
                            autoFocus={field.autoFocus}
                            maxMenuHeight={120}
                            noOptionsMessage={() => 'No hay opciones disponibles'}
                        />
                    )}
                </Field>
            </div>
            {
                field.locked ?
                    <ErrorLockLabel
                        name={field.name}
                        errors={errors}
                    />
                    :
                    <ErrorLabel
                        name={field.name}
                        errors={errors}
                        touched={touched}
                    />
            }
        </div>
    )
}

const SelectSearchDetailCard = ({
    field,
    errors,
    touched,
    values,
    setValues,
    handleChange
}) => {
    const [options, setOptions] = useState([])
    const [data, setData] = useState([])
    const [extraData, setExtraData] = useState(null)
    const dispatch = useDispatch()
    const getData = async () => {
        await requestAuth(
            'get',
            field?.urlApi,
            null
        )
            .then((response) => {
                const opt = response.data.map(item => ({
                    value: item.id.toString(),
                    label: item[field.labelDescription]
                }))
                setData(response.data)
                setOptions(opt)
            }
            )
            .catch(error => {
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las opciones' }))
            })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (field.setAction != null && field.setAction !== undefined) {
            field.setAction(setValues, extraData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extraData])

    return (
        <>
            <div className='col-span-12 row-span-2 sm:col-span-6 md:col-span-4 lg:col-span-3 grid gap-2 dark:bg-gray-800 bg-gray-200 p-2 rounded-md'>
                <div className='grid grid-cols-1'>
                    <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                        {field.label}
                        {field.required && <RequiredPick />}
                    </label>
                    {/* <div className='w-[calc(100%-55px)] bg-red-300'> */}
                    <Field name={field.name}>
                        {() => (
                            <Select
                                id={field.name}
                                defaultValue={field.defaultValue}
                                placeholder={field.placeholder}
                                options={options}
                                isClearable={true}
                                onChange={(e) => {
                                    handleChange(field.name, e)
                                    setValues(field.name, e?.value ?? '')
                                    setExtraData(data?.find(l => (l.id + '') === e?.value))
                                    setValues(field.nameNumber, data && data?.find(l => (l.id + '') === e?.value) ? data?.find(l => (l.id + '') === e?.value)[field.nameNumber] : 0)
                                    setValues(field.nameNumberRestante, data && data?.find(l => (l.id + '') === e?.value) ? data?.find(l => (l.id + '') === e?.value)[field.nameNumber] : 0)
                                    // setValues(field.nameNumberRestante, 0)
                                    setValues(field.alterField, 0)
                                }}
                                styles={colourStyles}
                                autoFocus={field.autoFocus}
                                maxMenuHeight={150}
                                noOptionsMessage={() => 'No hay opciones disponibles'}
                            />
                        )}
                    </Field>
                    {
                        field.locked ?
                            <ErrorLockLabel
                                name={field.name}
                                errors={errors}
                            />
                            :
                            <ErrorLabel
                                name={field.name}
                                errors={errors}
                                touched={touched}
                            />
                    }
                </div>
                <div className='text-sm dark:text-gray-400 text-gray-700'>
                    {
                        extraData
                        &&
                        field.infoTags?.map((info, index) => (
                            <div
                                key={index}
                            >
                                <span className='font-semibold dark:text-gray-400 text-gray-600'>{info.label}: </span>
                                <span
                                    className={`font-bold dark:text-gray-300 text-gray-800`}>
                                    {
                                        info.isDateValue ?
                                            formatDefaultDate(extraData[info?.data])
                                            :
                                            extraData[info?.data]
                                    }
                                </span>
                            </div>
                        ))
                    }
                    {
                        extraData &&
                        <div>
                            <span className='font-semibold dark:text-gray-400 text-gray-600'>{field.labelNumberRestante}: </span>
                            <span
                                className={`${(values[field?.nameNumberRestante] <= 0 ? 'dark:text-red-500 text-red-600 font-bold' : 'dark:text-green-500 text-green-600 font-bold')}`}>
                                {values[field?.nameNumberRestante]}
                            </span>
                        </div>
                    }
                </div>
                {
                    !extraData &&
                    <AlertLockDescription
                        message={field.exclamationDefault}
                    />
                }
                {
                    parseFloat(values[field.nameNumberRestante]) < 0 && parseFloat(values[field.nameNumber]) > 0 &&
                    <ErrorLockDescription
                        message={field.exclamationRestante}
                    />
                }
                {
                    extraData && parseFloat(values[field.nameNumber]) < 1 &&
                    <ErrorLockDescription
                        message={field.exclamationRestante}
                    />
                }
            </div>
        </>
    )
}

const SubSelectSearch = ({
    field,
    errors,
    touched,
    values,
    setValues,
    handleChange
}) => {
    const [options, setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState(null)
    const dispatch = useDispatch()
    const getData = async (urlApi) => {
        await requestAuth(
            'get',
            urlApi,
            null
        )
            .then((response) => {
                const opt = response.data.map(item => ({
                    value: item[field.labelId] ?? item.id.toString(),
                    label: item[field.labelDescription]
                }))
                setOptions(opt)
            }
            )
            .catch(error => {
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las opciones' }))
            })
    }

    useEffect(() => {
        setSelectedOption('')
        setValues(field.name, '')
        setOptions([])
        if (values[field.param] !== '' && values[field.param] !== null) {
            getData(field?.urlApi.replace('{param}', values[field.param]))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values[field.param]])
    return (
        <div className='col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3'>
            <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                {field.label}
                {field.required && <RequiredPick />}
            </label>
            <Field name={field.name}>
                {() => (
                    <Select
                        id={field.name}
                        placeholder={field.placeholder}
                        options={options}
                        isClearable={true}
                        value={selectedOption}
                        onChange={(sop) => {
                            setSelectedOption(options?.find(d => d.value === values[field.param]))
                            handleChange(field.name, sop)
                            setValues(field.name, sop?.value ?? '')
                        }}
                        styles={colourStyles}
                        autoFocus={field.autoFocus}
                        maxMenuHeight={150}
                        noOptionsMessage={() => 'No hay opciones disponibles'}
                    />
                )}
            </Field>
            {
                field.locked ?
                    <ErrorLockLabel
                        name={field.name}
                        errors={errors}
                    />
                    :
                    <ErrorLabel
                        name={field.name}
                        errors={errors}
                        touched={touched}
                    />
            }
        </div>
    )
}

const SubSelectDinamicSearch = ({
    field,
    errors,
    touched,
    values,
    setValues,
    handleChange
}) => {
    const [options, setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState(null)
    const dispatch = useDispatch()
    const getData = async (urlApi) => {
        await requestAuth(
            'get',
            urlApi,
            null
        )
            .then((response) => {
                const opt = response.data.map(item => ({
                    value: item[field.labelId] ?? item.id.toString(),
                    label: item[field.labelDescription]
                }))
                setOptions(opt)
            }
            )
            .catch(error => {
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las opciones' }))
            })
    }

    useEffect(() => {
        setSelectedOption('')
        setValues(field.name, '')
        setOptions([])
        // console.log(values[field.param])
        if (values[field.param] !== '' && values[field.param] !== null && values[field.param] !== undefined) {
            getData(field?.urlApi.replace('{param}', values[field.param]))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values[field.param]])
    return (
        // field.showValidator && options?.length > 0 &&
        <div className='col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3'>
            <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                {field.label}
                {field.required && <RequiredPick />}
            </label>
            <Field name={field.name}>
                {() => (
                    <Select
                        id={field.name}
                        placeholder={field.placeholder}
                        options={options}
                        isClearable={true}
                        value={selectedOption}
                        onChange={(sop) => {
                            setSelectedOption(options?.find(d => d.value === values[field.param]))
                            handleChange(field.name, sop)
                            setValues(field.name, sop?.value ?? '')
                        }}
                        styles={colourStyles}
                        autoFocus={field.autoFocus}
                        maxMenuHeight={150}
                        required={field.required}
                        noOptionsMessage={() => 'No hay opciones disponibles'}
                    />
                )}
            </Field>
            {
                field.locked ?
                    <ErrorLockLabel
                        name={field.name}
                        errors={errors}
                    />
                    :
                    <ErrorLabel
                        name={field.name}
                        errors={errors}
                        touched={touched}
                    />
            }
        </div>
    )
}

const ShowSelectedData = ({
    field,
}) => {
    return (
        <div className='col-start-1 col-end-13 dark:text-gray-100 bg-gray-100 dark:bg-gray-700 border border-gray-200 dark:border-gray-500 rounded p-3' >
            <div className='text-right'>
            </div>
            <div className='text-center text-sm font-bold pb-2'>{field.label}</div>
            <div className='grid sm:grid-cols-3 gap-3 max-h-80 overflow-auto'>
                {
                    field.data?.map((item, index) => (
                        <div key={index} className='relative bg-gray-300 dark:bg-gray-800 p-2 rounded-md'>
                            <div>
                                {
                                    field.items.map((i, idx) => (
                                        <div
                                            key={idx}
                                        >
                                            {
                                                i.subItems.map((si, idy) => (
                                                    <div
                                                        key={idy}
                                                        className=''
                                                    >
                                                        {
                                                            si.split(':').map((isi, idz) => (
                                                                <span
                                                                    key={idz}
                                                                >
                                                                    {' '}{item[isi]}
                                                                </span>
                                                            ))
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            <div>
                                <i className='fa-solid fa-circle-check text-green-500 text-xl absolute bottom-1 right-2' />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const CuadernoField = ({
    field,
    errors,
    touched,
    values,
    setValues,
    handleChange
}) => {
    const [options, setOptions] = useState([])
    const [data, setData] = useState([])
    // const [fullData, setFullData] = useState([])
    const [extraData, setExtraData] = useState(null)

    const dispatch = useDispatch()
    const getData = async () => {
        await requestAuth(
            'get',
            field?.urlApi,
            null
        )
            .then((response) => {
                const opt = response.data.map(item => ({
                    value: item.id.toString(),
                    label: item[field.labelDescription]
                }))
                setData(response.data)
                setOptions(opt)
                // setFullData(response.data)
            }
            )
            .catch(error => {
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las opciones' }))
            })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setFormData = (dataItem) => {
        console.log(dataItem)
        var recibo = ''
        var totalEntregado = 0
        var billetes = 0
        var monedas = 0
        var dolares = 0
        var faltante = 0
        if (dataItem != null) {
            recibo = parseFloat(dataItem.recibo ?? '');
            totalEntregado = parseFloat(dataItem.efectivo_entregado.replace(/\./g, '').replace(',', '.'));
            billetes = parseFloat(dataItem.billetes_total.replace(/\./g, '').replace(',', '.'));
            monedas = parseFloat(dataItem.monedas_total.replace(/\./g, '').replace(',', '.'));
            dolares = parseFloat(dataItem.dolares_total.replace(/\./g, '').replace(',', '.'));
            faltante = parseFloat(dataItem.efectivo_faltante.replace(/\./g, '').replace(',', '.'));
        }

        setValues('recibo', recibo)
        setValues('efectivo_entregado', totalEntregado)
        setValues('billetes_total', billetes)
        setValues('monedas_total', monedas)
        setValues('dolares_total', dolares)
        setValues('efectivo_faltante', faltante)
    }
    return (
        <>
            <div className='col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3'>
                <div className='grid grid-cols-1'>
                    <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                        {field.label}
                        {field.required && <RequiredPick />}
                    </label>
                    <Field name={field.name}>
                        {() => (
                            <Select
                                id={field.name}
                                defaultValue={field.defaultValue}
                                placeholder={field.placeholder}
                                options={options}
                                isClearable={true}
                                onChange={(e) => {
                                    handleChange(field.name, e)
                                    setValues(field.name, e?.value ?? '')
                                    setExtraData(data.find(l => (l.id + '') === e?.value))
                                    setFormData(data.find(l => (l.id + '') === e?.value))
                                }}
                                styles={colourStyles}
                                autoFocus={field.autoFocus}
                                maxMenuHeight={150}
                                noOptionsMessage={() => 'No hay opciones disponibles'}
                            />
                        )}
                    </Field>
                </div>
                {
                    field.locked ?
                        <ErrorLockLabel
                            name={field.name}
                            errors={errors}
                        />
                        :
                        <ErrorLabel
                            name={field.name}
                            errors={errors}
                            touched={touched}
                        />
                }
                {
                    field.infoTags && field.infoTags.length > 0 && extraData &&
                    <div className='space-y-1 gap-x-1 text-xs dark:bg-gray-800 bg-gray-200 p-1 rounded-b-lg dark:text-gray-400 text-gray-700'>
                        {
                            field.infoTags?.map((info, index) => (
                                <div
                                    key={index}
                                    className='p-0 leading-none'
                                >
                                    <span className='font-bold dark:text-gray-400 text-gray-600'>{info.label}:</span> <span className={`${info.mark ? 'dark:text-red-500 text-red-600 font-bold' : 'font-semibold dark:text-gray-300 text-gray-800'}`}>{extraData[info.data]}</span>
                                </div>
                            ))
                        }
                    </div>
                }
            </div>
        </>
    )
}