import React from 'react'
import '../../index.css'
import { ModalHeader } from './ModalHeader';

export const ModalPdf = ({ setModal, children, width }) => {
    return (
        <>
            <div
                className={`fixed inset-0 bg-black bg-opacity-50 z-20 flex items-center justify-center max-h-screen`}
                onClick={() => {
                    setModal(false);
                }}
            >
                <div
                    className={`fixed z-20 inset-0 flex items-center justify-center`}
                >
                    <div
                        className={`${width} max-h-full overflow-auto dark:bg-gray-700 bg-gray-100 rounded-md`}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <ModalHeader
                            setModal={setModal}
                            label={'Cuaderno diario'}
                        />
                        <div className='flex justify-center p-2'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
