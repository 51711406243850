import React from 'react'
import { useParams } from 'react-router-dom'
import { DefautlSubTitleTargetInfo, DescriptionTargetInfo, EmptyGridTargetInfo, ItemCardTargetInfo, LogoTargetInfo, MainTargetInfo, NumberCalculatorsTargetInfo, NumberGridTargetInfo, SubTitleTargetInfo, TitleTargetInfo } from '../../../../../../components/card/MainTargetInfo'
import { JoinStrings } from '../../../../../../utils/dataValidations'

const CardInfoEfectivo = ({ reload, data, setData, nameLabel }) => {
    const params = useParams()
    return (
        <MainTargetInfo
            extraClassName='grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-1'
            urlApi={`/efectivo/${params.sub_efectivo_id}`}
            setData={setData}
            dataRef={params.sub_efectivo_id}
            reload={reload}
        >
            <ItemCardTargetInfo
                logo={<LogoTargetInfo logo='fa-coins' />}
                aditionalClassName='col-span-2 lg:col-span-1'
            >
                {
                    nameLabel === 'EGRESOS' ?
                        <>
                            <SubTitleTargetInfo label={null} data={'EGRESOS'} />
                            <SubTitleTargetInfo label={''} data={data?.producto_nombre} />
                        </>
                        :
                        nameLabel === 'INGRESOS' ?
                            <>
                                <TitleTargetInfo label={null} data={'INGRESOS'} />
                                <TitleTargetInfo label={null} data={data?.producto_nombre} />
                            </>
                            :
                            <>
                                <DefautlSubTitleTargetInfo label={null} data={nameLabel} />
                                <DefautlSubTitleTargetInfo label={null} data={data?.producto_nombre} />
                            </>
                }
                {
                    data?.nombres != null && data?.nombres !== '-' &&
                    <DescriptionTargetInfo label='Encargado:' data={data ? JoinStrings([data.nombres, data.apellido_paterno, data.apellido_materno], ' ') : '-'} />
                }
                {/* <DescriptionTargetInfo label='Obs:' data={data?.obs} /> */}
            </ItemCardTargetInfo>
            <NumberCalculatorsTargetInfo>
                <NumberGridTargetInfo label='Monto total Bs.' data={data?.monto_total} />
                <NumberGridTargetInfo label='Ingresos Bs.' data={data?.monto_total_ingreso} />
                <NumberGridTargetInfo label='Egresos Bs' data={data?.monto_total_egreso} borderBotton={false} />
            </NumberCalculatorsTargetInfo>
            <NumberCalculatorsTargetInfo>
                <NumberGridTargetInfo label='Billetes Bs.' data={data?.billetes_total} />
                <NumberGridTargetInfo label='Monedas Bs.' data={data?.monedas_total} />
                <EmptyGridTargetInfo label='.' data={null} borderBotton={false} />
            </NumberCalculatorsTargetInfo>
        </MainTargetInfo>
    )
}

export default CardInfoEfectivo