import React, { useState } from 'react'
import { TabContainer } from '../../../../../components/tab/TabContainer'
import { TabItem } from '../../../../../components/tab/TabItem'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ListCuadernoHistorialTable } from './ListCuadernoHistorialTable'
import { ErrorPage } from '../../../../error/errorPage'
import { CobrosValues, CreateValues, DevolucionDeGarantiasValues, DevolucionDePrestamosValues, GastoValues, VentaNoRetornableValues } from '../../../../../FormSchemes/CuadernoHistorialScheme'
import { ModalPdf } from '../../../../../components/modals/ModalPdf'
import CuadernoPdf from './CuadernoPdf'
import { VerificationPathByString } from '../../../../../utils'
import { ListCuadernoHistorialTableGastos } from './ListCuadernoHistorialTableGastos'
import { ListCuadernoEnvasesHistorialTable } from './ListCuadernoEnvasesHistorialTable'
import { ListCuadernoCobranzaHistorialTable } from './ListCuadernoCobranzaHistorialTable'
import { GeneralUpdateValues, RegistrarEfectivoFaltanteValues, UpdateAlmacenValues, UpdateCuadernoEfectivoValues } from '../../../../../FormSchemes/CuadernoScheme'
import { setReloadMainTable, setReloadSubTable } from '../../../../../store/slices/auth/authSlice'
import { ListCuadernoAlmacenProductoIngresosTable } from './ListCuadernoAlmacenProductoIngresosTable'
import BoletaSalidaPdf from './BoletaSalidaPdf'
import { ListCuadernoEfectivoTable } from './ListCuadernoEfectivoTable'
import { ListCuadernoAlmacenTable } from './ListCuadernoAlmacenTable'

export const CuadernoTab = ({
    reload,
    setReload,
    dataCard,
    mainReloadTable,
    setMainReloadTable
}) => {
    const {
        dispatch,
        params,
        // data, setData,
        // paginate, setPaginate,
        // selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        // stateData, setStateData,
        createModal, setCreateModal,
        // // imports
        // requestAuthPaginate,
        // TableContainer,
        // Paginator,
        // formatFilters,
        // Searcher,
        // FilterSelect,
        // RangeDate,
        Actions,
        // TableSection,
        // ActionSection,
        Section,
        ModalForm,
    } = useGeneralParams('')
    const [recallState, setRecallState] = useState(false);
    const [ventaRetornableModal, setVentaRetornableModal] = useState(false);
    // const [resetModal, setResetModal] = useState(false);
    const [gastosModal, setGastosModal] = useState(false);
    const [prestamosModal, setPrestamosModal] = useState(false);
    const [garantiasModal, setGarantiasModal] = useState(false);
    const [cobrosModal, setCobrosModal] = useState(false);
    const [reOpenModal, setReOpenModal] = useState(false);
    const [cerrarCuadernoModal, setCerrarCuadernoModal] = useState(false);
    const [revisarModal, setRevisarModal] = useState(false);
    const [updateAlmacenModal, setUpdateAlmacenModal] = useState(false);
    const [updateEfectivoModal, setUpdateEfectivoModal] = useState(false);
    const [generarPdfModal, setGenerarPdfModal] = useState(false);
    const [boletaSalidaPdfModal, setBoletaSalidaPdfModal] = useState(false);
    const [registrarFaltanteModal, setRegistrarFaltanteModal] = useState(false);

    return (
        <Section>
            <Actions
                buttons={[
                    // {
                    //     icon: 'repeat',
                    //     label: '',
                    //     action: () => recall()
                    // },
                    // {
                    //     icon: 'circle-plus',
                    //     label: '',
                    //     action: () => {
                    //         console.log(selecteds)
                    //         // console.log(selectAllChecked)
                    //         // console.log(isChecked)
                    //     },
                    //     className: 'dark:bg-yellow-600 bg-yellow-500 text-white',
                    //     disabled: false
                    // },
                    {
                        icon: 'circle-plus',
                        label: 'Vender producto retornable',
                        action: () => setCreateModal(true),
                        className: 'dark:bg-blue-700 bg-blue-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'circle-plus',
                        label: 'Vender producto no retornable',
                        action: () => setVentaRetornableModal(true),
                        className: 'dark:bg-cyan-700 bg-cyan-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'circle-plus',
                        label: 'Gastos',
                        action: () => setGastosModal(true),
                        className: 'dark:bg-yellow-700 bg-yellow-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'circle-plus',
                        label: 'Devolución de prestamos',
                        action: () => setPrestamosModal(true),
                        className: 'dark:bg-indigo-700 bg-indigo-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'circle-plus',
                        label: 'Devolución de Garantías',
                        action: () => setGarantiasModal(true),
                        className: 'dark:bg-yellow-700 bg-yellow-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'circle-plus',
                        label: 'Cobros',
                        action: () => setCobrosModal(true),
                        className: 'dark:bg-fuchsia-700 bg-fuchsia-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    {
                        icon: 'database',
                        label: 'Actualizar almacén',
                        action: () => setUpdateAlmacenModal(true),
                        className: 'dark:bg-indigo-700 bg-indigo-500 text-white',
                        disabled: false
                    },
                    {
                        icon: 'folder-open',
                        label: 'Abrir cuaderno',
                        action: () => setReOpenModal(true),
                        className: 'dark:bg-orange-700 bg-orange-500 text-white',
                        disabled: dataCard?.abierto
                    },
                    {
                        icon: 'folder-close',
                        label: 'Cerrar cuaderno',
                        action: () => setCerrarCuadernoModal(true),
                        className: 'dark:bg-orange-700 bg-orange-500 text-white',
                        disabled: !dataCard?.abierto
                    },
                    // {
                    //     icon: 'folder-open',
                    //     label: 'Revisar cuaderno',
                    //     action: () => setRevisarModal(true),
                    //     className: 'dark:bg-cyan-700 bg-cyan-500 text-white',
                    //     disabled: dataCard?.abierto
                    // },
                    {
                        icon: 'money-bills',
                        label: 'Efectivo',
                        action: () => setUpdateEfectivoModal(true),
                        className: 'dark:bg-green-700 bg-green-500 text-white',
                        // disabled: dataCard?.abierto
                    },
                    {
                        icon: 'money-bill',
                        label: 'Faltante o Sobrante',
                        action: () => setRegistrarFaltanteModal(true),
                        className: 'dark:bg-red-700 bg-red-500 text-white',
                        disabled: dataCard?.abierto
                    },
                    {
                        icon: 'file-pdf',
                        label: 'Cuaderno PDF',
                        action: () => setGenerarPdfModal(true),
                        className: 'dark:bg-pink-600 bg-pink-500 text-white',
                        disabled: false
                    },
                    {
                        icon: 'file-pdf',
                        label: 'Boleta salida PDF',
                        action: () => setBoletaSalidaPdfModal(true),
                        className: 'dark:bg-fuchsia-600 bg-fuchsia-500 text-white',
                        disabled: !VerificationPathByString('/almacen-productos')
                    },
                    // {
                    //     icon: 'minus',
                    //     label: 'Egreso directo',
                    //     action: () => setEgresoDirectoModal(true),
                    //     className: 'dark:bg-red-700 bg-red-500'
                    // },
                ]}
            />
            <TabContainer>
                <TabItem
                    to='ventas'
                    label='Ventas'
                    active={VerificationPathByString('/ventas')}
                />
                <TabItem
                    to='gastos'
                    label='Gastos'
                    active={VerificationPathByString('/gastos')}
                />
                <TabItem
                    to='cobranzas'
                    label='Cobranzas'
                    active={VerificationPathByString('/cobranzas')}
                />
                <TabItem
                    to='turriles-recogidos'
                    label='Turriles recogidos'
                    active={VerificationPathByString('/turriles-recogidos')}
                />
                <TabItem
                    to='almacen-productos'
                    label='Almacén ingresos'
                    active={VerificationPathByString('/almacen-productos')}
                />
                <TabItem
                    to='cuaderno-almacen'
                    label='Almacén'
                    active={VerificationPathByString('/cuaderno-almacen')}
                />
                <TabItem
                    to='efectivo'
                    label='Efectivo'
                    active={VerificationPathByString('/efectivo')}
                />
            </TabContainer>
            <Routes>
                <Route index element={
                    <ListCuadernoHistorialTable
                        reload={reload}
                        setReload={setReload}
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                        //
                        params={params}
                        setSelectAllChecked={setSelectAllChecked}
                        setIsChecked={setIsChecked}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        selectAllChecked={selectAllChecked}
                        isChecked={isChecked}
                        recallState={recallState}
                    />
                } />
                <Route
                    path='ventas'
                    element={
                        <ListCuadernoHistorialTable
                            reload={reload}
                            setReload={setReload}
                            mainReloadTable={mainReloadTable}
                            setMainReloadTable={setMainReloadTable}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                        />
                    } />


                <Route
                    path='cobranzas'
                    element={
                        <ListCuadernoCobranzaHistorialTable
                            reload={reload}
                            setReload={setReload}
                            mainReloadTable={mainReloadTable}
                            setMainReloadTable={setMainReloadTable}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                        />
                    } />


                <Route
                    path='gastos'
                    element={
                        <ListCuadernoHistorialTableGastos
                            reload={reload}
                            setReload={setReload}
                            mainReloadTable={mainReloadTable}
                            setMainReloadTable={setMainReloadTable}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                        />
                    } />
                <Route
                    path='turriles-recogidos'
                    element={
                        <ListCuadernoEnvasesHistorialTable
                            reload={reload}
                            setReload={setReload}
                            mainReloadTable={mainReloadTable}
                            setMainReloadTable={setMainReloadTable}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                        />
                    } />
                <Route
                    path='almacen-productos'
                    element={
                        <ListCuadernoAlmacenProductoIngresosTable
                            reload={reload}
                            setReload={setReload}
                            mainReloadTable={mainReloadTable}
                            setMainReloadTable={setMainReloadTable}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                        />
                    } />
                <Route
                    path='cuaderno-almacen'
                    element={
                        <ListCuadernoAlmacenTable
                            reload={reload}
                            setReload={setReload}
                            mainReloadTable={mainReloadTable}
                            setMainReloadTable={setMainReloadTable}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                        />
                    } />
                <Route
                    path='efectivo'
                    element={
                        <ListCuadernoEfectivoTable
                            reload={reload}
                            setReload={setReload}
                            mainReloadTable={mainReloadTable}
                            setMainReloadTable={setMainReloadTable}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                        />
                    } />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label={<span>Registrar Venta de producto retornable en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={CreateValues(params?.cuaderno_id, dataCard?.user_id)}
                    urlApi={`/cuaderno_historial/venta-retornable`}
                    method={'post'}
                    call={() => {
                        setRecallState(!recallState)
                        setReload(!reload)
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                ventaRetornableModal &&
                <ModalForm
                    setModal={setVentaRetornableModal}
                    label={<span>Registrar Venta de producto no retornable en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={VentaNoRetornableValues(params?.cuaderno_id, dataCard?.user_id)}
                    urlApi={`/cuaderno_historial/venta-no-retornable`}
                    method={'post'}
                    call={() => {
                        setRecallState(!recallState)
                        setReload(!reload)
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                gastosModal &&
                <ModalForm
                    setModal={setGastosModal}
                    label={<span>Registrar Gasto en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={GastoValues(params?.cuaderno_id)}
                    urlApi={`/cuaderno_historial/gasto`}
                    method={'post'}
                    call={() => {
                        setRecallState(!recallState)
                        setReload(!reload)
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                prestamosModal &&
                <ModalForm
                    setModal={setPrestamosModal}
                    label={<span>Devolución de Prestamos en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={DevolucionDePrestamosValues(params?.cuaderno_id, dataCard?.user_id)}
                    urlApi={`/deudaEnvasePrestamo`}
                    method={'post'}
                    call={() => {
                        setRecallState(!recallState)
                        setReload(!reload)
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                garantiasModal &&
                <ModalForm
                    setModal={setGarantiasModal}
                    label={<span>Devolución de garantías en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={DevolucionDeGarantiasValues(params?.cuaderno_id, dataCard?.user_id)}
                    urlApi={`/deudaEnvaseGarantia`}
                    method={'post'}
                    call={() => {
                        setRecallState(!recallState)
                        setReload(!reload)
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                cobrosModal &&
                <ModalForm
                    setModal={setCobrosModal}
                    label={<span>Cobros en <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={CobrosValues(params?.cuaderno_id, dataCard?.user_id)}
                    urlApi={`/deuda`}
                    method={'post'}
                    call={() => {
                        setRecallState(!recallState)
                        setReload(!reload)
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                reOpenModal &&
                <ModalForm
                    setModal={setReOpenModal}
                    label={<span>Abrir cuaderno <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={GeneralUpdateValues(params?.cuaderno_id, false)}
                    urlApi={`/cuaderno/${params.cuaderno_id}/re-open`}
                    method={'put'}
                    call={() => {
                        setRecallState(!recallState)
                        dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }
                    }
                    buttonLabel='Abrir'
                />
            }
            {
                cerrarCuadernoModal &&
                <ModalForm
                    setModal={setCerrarCuadernoModal}
                    label={<span>Cerrar cuaderno <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={GeneralUpdateValues(params?.cuaderno_id, false)}
                    urlApi={`/cuaderno/${params.cuaderno_id}/close`}
                    method={'put'}
                    call={() => {
                        setRecallState(!recallState)
                        dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }
                    }
                    buttonLabel='Cerrar'
                />
            }
            {
                revisarModal &&
                <ModalForm
                    setModal={setRevisarModal}
                    label={<span>Revisar cuaderno <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={GeneralUpdateValues(params?.cuaderno_id, false)}
                    urlApi={`/cuaderno/${params.cuaderno_id}/revisar`}
                    method={'put'}
                    call={() => {
                        setRecallState(!recallState)
                        dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }
                    }
                    buttonLabel='Revisado'
                />
            }
            {
                updateAlmacenModal &&
                <ModalForm
                    setModal={setUpdateAlmacenModal}
                    label={<span>Actualizar almacén para el cuaderno <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={UpdateAlmacenValues(dataCard)}
                    urlApi={`/cuaderno/${params.cuaderno_id}/almacenes`}
                    method={'post'}
                    call={() => {
                        setRecallState(!recallState)
                        dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }
                    }
                    buttonLabel='Actualizar'
                />
            }
            {
                updateEfectivoModal &&
                <ModalForm
                    setModal={setUpdateEfectivoModal}
                    label={<span>Actualizar efectivo del cuaderno <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={UpdateCuadernoEfectivoValues(dataCard)}
                    urlApi={`/cuaderno/efectivo`}
                    method={'post'}
                    call={() => {
                        setRecallState(!recallState)
                        dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }
                    }
                    buttonLabel='Actualizar'
                />
            }
            {
                registrarFaltanteModal &&
                <ModalForm
                    setModal={setRegistrarFaltanteModal}
                    label={<span>Registrar faltante o sobrante del cuaderno <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={RegistrarEfectivoFaltanteValues(dataCard)}
                    urlApi={`/cuadernoEfectivo/faltanteSobrante`}
                    method={'post'}
                    call={() => {
                        setRecallState(!recallState)
                        dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }
                    }
                    buttonLabel='Actualizar'
                />
            }
            {
                generarPdfModal &&
                <ModalPdf
                    setModal={setGenerarPdfModal}
                    width={'md:max-w-5xl'}
                >
                    <CuadernoPdf />
                </ModalPdf>
            }
            {
                boletaSalidaPdfModal &&
                <ModalPdf
                    setModal={setBoletaSalidaPdfModal}
                    width={'md:max-w-5xl'}
                >
                    <BoletaSalidaPdf
                        cuaderno={dataCard}
                        selecteds={selecteds}
                    />
                </ModalPdf>
            }
        </Section>
    )
}
