import React, { useState } from 'react'
// import MainTargetInfo from './MainTargetInfo'
import { ListAlmacenHistorialTable } from './ListAlmacenHistorialTable'
import CardInfo from './CardInfo'

export const AlmacenHistorials = () => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <div className='dark:bg-[#3a5e5e] bg-[#4a5c6830] p-1 rounded-md'>
            <CardInfo
                reload={reload}
                data={data}
                setData={setData}
            // setReload={setReload}
            />
            <ListAlmacenHistorialTable
                reload={reload}
                setReload={setReload}
                dataCard={data}
            />
        </div>
    )
}
