import React from 'react'
import { useParams } from 'react-router-dom';
import { DescriptionTargetInfo, ItemCardTargetInfo, LogoTargetInfo, MainTargetInfo, SubTitleTargetInfo, TitleTargetInfo } from '../../../../../components/card/MainTargetInfo';
// import { NoteIconSvg } from '../../../../../utils/styleFormat';

const CardInfo = ({ reload, data, setData }) => {
    const params = useParams()
    return (
        <MainTargetInfo
            extraClassName='grid-cols-1 gap-1'
            urlApi={`/almacen/${params.almacen_id}`}
            setData={setData}
            dataRef={params.almacen_id}
            reload={reload}
        >
            <ItemCardTargetInfo
                // logoLabel={<LogoLabelTargetInfo logo={<NoteIconSvg />} />}
                logo={<LogoTargetInfo logo='fa-store' />}
                aditionalClassName='col-span-2'
            >
                {
                    data?.nombre !== null && data?.nombre !== '-' &&
                    <>
                        <TitleTargetInfo label='' data={data?.nombre} />
                        <DescriptionTargetInfo label='Almacén General' data='' />
                    </>
                }
                {
                    data?.encargado_id !== null &&
                    <>
                        <SubTitleTargetInfo label='' data={`${data?.encargado_nombres}`} />
                        <DescriptionTargetInfo label='Almacén Personal' data='' />
                    </>
                }
                {
                    data?.proveedor !== null && data?.proveedor !== undefined &&
                    <>
                        <SubTitleTargetInfo label='' data={`${data?.proveedor}`} />
                        <DescriptionTargetInfo label='Almacén Proveedor' data='' />
                    </>
                }
            </ItemCardTargetInfo>
        </MainTargetInfo>
    )
}

export default CardInfo