import React from "react"
import { ListGarantiaTable } from "./ListGarantiaTable"
import { HistorialGarantia } from "./historial_garan"
import { ErrorPage } from "../../../error/errorPage"
import { Route, Routes } from "react-router-dom"


export const Garantias = () => {
    return (
        <div className='grid xl:grid-cols-1 gap-2'>
            <div className='col-span-2 xl:col-span-1'>
                <ListGarantiaTable />
            </div>
            <Routes>
                <Route index element={
                    null
                } />
                    <Route
                        path={`deudaEnvases/garantía/:deuda_id/*`}
                        element={<HistorialGarantia />}
                    />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </div>
    )
}