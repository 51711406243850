import React, { useEffect } from 'react'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { CreateValues, DeleteValues } from '../../../../FormSchemes/EfectivoGeneralScheme'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import { useSelector } from 'react-redux'

export const ListEfectivoTable = ({ mainReloadTable }) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        deleteModal, setDeleteModal,
        currentData, setCurrentData,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
        UpdateValuesModal,
        redirect

    } = useGeneralParams('nombre')
    const reloadMainTable = useSelector(state => state.login.reloadMainTable)
    let recallCount = 1

    const getDataPaginate = async () => {
        await requestAuthPaginate(
            'get',
            `/efectivos/pag`,
            null,
            paginate,
            setData,
            setStateData,
            setPaginate,
            dispatch
        )
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters, reloadMainTable]);

    // useEffect(() => {
    //     console.log(selecteds)
    // }, [selecteds]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters]);

    const recall = () => {
        getDataPaginate()
    }
    useEffect(() => {
        if (recallCount > 1) {
            recall()
        }
        recallCount++
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainReloadTable])
    return (
        <Section>
            <ActionSection>
                <Actions
                    buttons={[
                        {
                            icon: 'add',
                            label: 'Crear',
                            action: () => setCreateModal(true)
                        },
                    ]}
                />
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombre'
                        },
                        {
                            label: 'Código',
                            value: 'codigo'
                        }
                    ]}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Estado',
                                value: ''
                            },
                            {
                                label: 'Activo',
                                value: 'activo'
                            },
                            {
                                label: 'Inactivo',
                                value: 'inactivo'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={recall}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Nombre',
                            columns: ['nombre']
                        },
                        {
                            label: 'Encargado',
                            columns: ['nombres', 'apellido_paterno:apellido_materno']
                        },
                        {
                            label: 'Monto total',
                            columns: ['monto_total'],
                            numberValue: true,
                        },
                        {
                            label: 'Monto ingreso',
                            columns: ['monto_total_ingreso'],
                            numberValue: true,
                        },
                        {
                            label: 'Monto egreso',
                            columns: ['monto_total_egreso'],
                            numberValue: true,
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'cyan',
                                    icon: 'fa-table',
                                    action: (data) => redirect(`productos/${data.id}`),
                                    reference: 'id',
                                    tooltipText: 'Productos'
                                },
                                {
                                    type: 'green',
                                    icon: 'fa-plus',
                                    action: (data) => redirect(`movimientos/${data.id}`),
                                    reference: 'id',
                                    tooltipText: 'Ingresos'
                                },
                                {
                                    type: 'red',
                                    icon: 'fa-minus',
                                    action: (data) => redirect(`movimientos_egresos/${data.id}`),
                                    reference: 'id',
                                    tooltipText: 'Egresos'
                                },
                                {
                                    type: 'yellow',
                                    icon: 'fa-money-bills',
                                    action: (data) => redirect(`cierres/${data.id}`),
                                    reference: 'id',
                                    tooltipText: 'Cierres de efectivo'
                                },
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id',
                                    validate: { value: 'abierto', validator: true }
                                }
                            ],
                            // stickyR: true
                        },
                    ]}
                    data={data.data}
                    checkList={false}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label='Crear CAJA'
                    dataValues={CreateValues()}
                    urlApi={'/efectivo'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                deleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar efectivo'
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/efectivo/${currentData.id}`}
                    method={'delete'}
                    call={recall}
                    buttonLabel='Eliminar'
                />
            }
        </Section>
    )
}