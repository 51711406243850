import React, { useState } from "react";
import { TabContainer } from "../../../../../components/tab/TabContainer";
import { TabItem } from "../../../../../components/tab/TabItem";
import { useGeneralParams } from "../../../../../hooks/useDataPaginate";
import { Route, Routes } from "react-router-dom";
import { ErrorPage } from "../../../../error/errorPage";
import { VerificationPathByString } from "../../../../../utils";
import { ListClienteDeudaTable } from "./ListClienteDeudaTable";
import { ListClientePrestamoTable } from "./ListClientePrestamoTable";
import { ListClienteGarantiaTable } from "./ListClienteGarantiaTable";


export const ClienteTab = ({
    reload,
    setReload,
    mainReloadTable,
    setMainReloadTable
}) => {
    const {
        params,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        Section,
    } = useGeneralParams('')
    const [recallState] = useState(false);

    

    return (
        <Section>
            <TabContainer>
                <TabItem
                    to='deudas'
                    label='Deudas'
                    active={VerificationPathByString('/deudas')}
                />
                <TabItem
                    to='prestamos'
                    label='Prestamos'
                    active={VerificationPathByString('/prestamos')}
                />
                <TabItem
                    to='garantias'
                    label='Garantias'
                    active={VerificationPathByString('/garantias')}
                />
            </TabContainer>
            <Routes>
                <Route index element={
                    <ListClienteDeudaTable
                        reload={reload}
                        setReload={setReload}
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                        //
                        params={params}
                        setSelectAllChecked={setSelectAllChecked}
                        setIsChecked={setIsChecked}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        selectAllChecked={selectAllChecked}
                        isChecked={isChecked}
                        recallState={recallState}
                    />
                } />   
                <Route
                path='deudas'
                element={
                    <ListClienteDeudaTable
                        reload={reload}
                        setReload={setReload}
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                        //
                        params={params}
                        setSelectAllChecked={setSelectAllChecked}
                        setIsChecked={setIsChecked}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        selectAllChecked={selectAllChecked}
                        isChecked={isChecked}
                        recallState={recallState}
                    />
                } />
                <Route
                path='prestamos'
                element={
                    <ListClientePrestamoTable
                        reload={reload}
                        setReload={setReload}
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                        //
                        params={params}
                        setSelectAllChecked={setSelectAllChecked}
                        setIsChecked={setIsChecked}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        selectAllChecked={selectAllChecked}
                        isChecked={isChecked}
                        recallState={recallState}
                    />
                } />
                <Route
                path='garantias'
                element={
                    <ListClienteGarantiaTable
                        reload={reload}
                        setReload={setReload}
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                        //
                        params={params}
                        setSelectAllChecked={setSelectAllChecked}
                        setIsChecked={setIsChecked}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        selectAllChecked={selectAllChecked}
                        isChecked={isChecked}
                        recallState={recallState}
                    />
                } />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </Section>
    )
}
