import React, { useState } from 'react'
import { TabContainer } from '../../../../../components/tab/TabContainer'
import { TabItem } from '../../../../../components/tab/TabItem'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ErrorPage } from '../../../../error/errorPage'
import { VerificationPathByString } from '../../../../../utils'
import { ListVendedorDeudaTable } from './ListVendedorDeudaTable'
import { ListVendedorPrestamoTable } from './ListVendedorPrestamoTable'
import { ListVendedorGarantiaTable } from './ListVendedorGarantiaTable'

export const VendedorTab = ({
    reload,
    setReload,
    dataCard,
    mainReloadTable,
    setMainReloadTable
}) => {
    const {
        params,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        Section,
    } = useGeneralParams('')
    const [recallState] = useState(false);
    

    return (
        <Section>
            <TabContainer>
                <TabItem
                    to='deudas'
                    label='Deudas'
                    active={VerificationPathByString('/deudas')}
                />
                <TabItem
                    to='prestamos'
                    label='Prestamos'
                    active={VerificationPathByString('/prestamos')}
                />
                <TabItem
                    to='garantias'
                    label='Garantias'
                    active={VerificationPathByString('/garantias')}
                />
            </TabContainer>
            <Routes>
                <Route index element={
                    <ListVendedorDeudaTable
                        reload={reload}
                        setReload={setReload}
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                        //
                        params={params}
                        setSelectAllChecked={setSelectAllChecked}
                        setIsChecked={setIsChecked}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        selectAllChecked={selectAllChecked}
                        isChecked={isChecked}
                        recallState={recallState}
                    />
                } />
                <Route
                path='deudas'
                element={
                    <ListVendedorDeudaTable
                        reload={reload}
                        setReload={setReload}
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                        params={params}
                        setSelectAllChecked={setSelectAllChecked}
                        setIsChecked={setIsChecked}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        selectAllChecked={selectAllChecked}
                        isChecked={isChecked}
                        recallState={recallState}
                    />
                } />
                <Route
                path='prestamos'
                element={
                    <ListVendedorPrestamoTable
                        reload={reload}
                        setReload={setReload}
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                        params={params}
                        setSelectAllChecked={setSelectAllChecked}
                        setIsChecked={setIsChecked}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        selectAllChecked={selectAllChecked}
                        isChecked={isChecked}
                        recallState={recallState}
                    />
                } />
                <Route
                path='garantias'
                element={
                    <ListVendedorGarantiaTable
                        reload={reload}
                        setReload={setReload}
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                        params={params}
                        setSelectAllChecked={setSelectAllChecked}
                        setIsChecked={setIsChecked}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        selectAllChecked={selectAllChecked}
                        isChecked={isChecked}
                        recallState={recallState}
                    />
                } />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </Section>
    )
}
