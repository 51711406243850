import View from './View'
import Text from './Text'

export const ValueTablePdf = (props) => {
  const { fields = [], data = [], border, width = '100%', startExtraData = null } = props

  return (
    <View flexDirection="column" width={width}>
      <View border={border} width="100%">
        <View
          // border={{ right: true : false }}
          width={'100%'}
          style={{ backgroundColor: 'rgba(30,95,160,.2)' }}
          justifyContent='center'
        >
          <Text bold>
            {props.headerTitle}
          </Text>
        </View>
      </View>
      {
        startExtraData != null &&
        <View
          border={{ bottom: true }}
          width="100%"
        >
          <View
            border={{ right: true }}
            width='50%'
          >
            <Text>
              {startExtraData.label}
            </Text>
          </View>
          <View
            width='50%'
            justifyContent='flex-end'
          >
            <Text>
              {startExtraData.value}
            </Text>
          </View>
        </View>
      }
      {data
        ? data.map((item, idx) => (
          <View
            key={idx}
            border={{ bottom: true }}
            width="100%"
          >
            {fields.map((field, idy) => (
              <View
                key={idy}
                border={{ right: idy + 1 < fields.length ? true : false }}
                width={field.width ? `${field.width}%` : 'auto'}
                justifyContent={`${field.justifyContent ? 'flex-end' : 'flex-start'}`}
              >
                <Text>
                  {
                    field.dateConvert ?
                      field.dateConvert(item[field.name])
                      :
                      item[field.name]
                  }
                </Text>
              </View>
            ))}
          </View>
        ))
        : null}
    </View>
  )
}

export const LabelValueTablePdf = ({ headerTitle, fields, border, width = '100%' }) => {
  // const { fields = [], data = [], border, width = '100%' } = props

  return (
    <View flexDirection="column" width={width}>
      <View width="100%">
        <View
          border={{ bottom: true }}
          width={'100%'}
          style={{ backgroundColor: 'rgba(30,95,160,.2)' }}
          justifyContent='center'
        >
          <Text bold>
            {headerTitle}
          </Text>
        </View>
      </View>
      {fields
        ? fields.map((field, idx) => (
          <View
            key={idx}
            border={{ bottom: true }}
            width="100%"
          >
            <View
              border={{ right: true }}
              width={field.widthLabel ? `${field.widthLabel}%` : 'auto'}
            // justifyContent={`${field.justifyContent ? 'flex-end' : 'flex-start'}`}
            >
              <Text>
                {field.label}
              </Text>
            </View>
            <View
              width={field.widthName ? `${field.widthName}%` : 'auto'}
              justifyContent={'flex-end'}
            >
              <Text>
                {field.value}
              </Text>
            </View>
          </View>
        ))
        : null}
    </View>
  )
}

export const SectionAlmacenCuadernoTablePdf = ({
  margin,
  titleTable,
  width = '100%',
  saldoAnterior,
  cargadoEnElDia,
  subTotal,
  venta,
  saldo,
}) => {
  return (
    <>
      <SubTitleTable
        margin={margin}
        textTitle={titleTable}
      />
      <View flexDirection="column" width={width}>
        <View width="100%">
          <View
            border={{ top: false, bottom: true, right: true }}
            width='40%'
          >
            <Text bold>{' '}</Text>
          </View>
          <View
            border={{ top: true, right: true, bottom: true }}
            width='20%'
            style={{ backgroundColor: 'rgba(30,95,160,.2)' }}
          >
            <Text bold>CANT.</Text>
          </View>
          <View
            border={{ top: true, right: true, bottom: true }}
            width='40%'
            style={{ backgroundColor: 'rgba(30,95,160,.2)' }}
          >
            <Text bold>TRANSPORTISTA</Text>
          </View>
        </View>
        <RowAlmacenSection
          col1='SALDO ANTERIOR'
          col2={saldoAnterior}
          col3=''
        />
        <RowAlmacenSection
          col1={titleTable?.includes('VACIOS') ? 'RECOGIDO' : 'CARGADO EN EL DÍA'}
          col2={cargadoEnElDia}
          col3=''
        />
        <RowAlmacenTotalSection
          col1='SUB-TOTAL'
          col2={subTotal}
          col3=''
        />
        <RowAlmacenTotalSection
          col1={titleTable?.includes('VACIOS') ? 'ENTREGADO' : 'VENTA'}
          col2={venta}
        />
        <RowAlmacenTotalSection
          col1='SALDO CARRO'
          col2={saldo}
        />
      </View>
    </>
  )
}

const RowAlmacenSection = ({ col1, col2, col3 }) => {
  return (
    <View
      border={{ bottom: true }}
      width="100%"
    >
      <View
        width="40%"
        border={{ left: true, right: true }}
      >
        <Text>
          {col1}
        </Text>
      </View>
      <View
        width="20%"
        border={{ right: true }}
        justifyContent={'flex-end'}
      >
        <Text>
          {col2}
        </Text>
      </View>
      <View
        width="40%"
        border={{ right: true }}
      >
        <Text>
          {col3}
        </Text>
      </View>
    </View>
  )
}

const RowAlmacenTotalSection = ({ col1, col2, col3 }) => {
  return (
    <View
      width="100%"
    >
      <View
        width="40%"
        border={{ bottom: true, left: true, right: true }}
      >
        <Text>
          {col1}
        </Text>
      </View>
      <View
        width="20%"
        border={{ bottom: true, right: true }}
        justifyContent={'flex-end'}
      >
        <Text>
          {col2}
        </Text>
      </View>
    </View>
  )
}

const SubTitleTable = ({
  textTitle,
  margin = '3px 0px 0px 0px',
  border = { top: false, right: false, left: false },
  style = { letterSpacing: '2px' },

}) => {
  return (
    <View justifyContent='center' width='100%' border={border} style={style} margin={margin}>
      <Text padding='1px 0px' bold textAlign='center'>{textTitle}</Text>
    </View>
  )
}