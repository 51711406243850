import * as Yup from 'yup'
import { ToTwoDecimal } from '../utils/dataValidations'
import { formatInputDate } from '../utils/dateFormat'

// export const CreateValues = () => {
//     return {
//         initialValues: {
//             departamento_id: 2,
//             user_id: '',
//             users:[]
//         },
//         fieldsValidation: Yup.object().shape({
//             user_id: Yup.string()
//                 .required('Campo requerido'),
//         }),
//         fields: [
//             // {
//             //     label: 'Buscar usuario por departamento',
//             //     name: 'departamento_id',
//             //     type: 'doubleSelectApi',
//             //     required: false,
//             //     optionDescription: 'nombre',
//             //     urlApi: '/departamentos',
//             //     sub_name: 'user_id',
//             //     sub_label: 'Seleccionar usuario',
//             //     sub_urlApi: '/departamento/{param}/users',
//             //     sub_optionDescription: 'etiqueta',
//             // },
//             {
//                 label: 'Seleccionar usuarios',
//                 name: 'users',
//                 type: 'checkboxes',
//                 required: false,
//                 urlApi: '/users/abrirCuaderno',
//                 indexLabel:'etiqueta'
//             },
//         ]

//     }
// }
export const CreateValues = () => {
    return {
        initialValues: {
            users: []
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            {
                label: 'Seleccionar usuarios',
                name: 'users',
                type: 'checkboxes',
                required: false,
                urlApi: '/users/permitirCuaderno',
                indexLabel: 'etiqueta'
            },
        ]
    }
}

export const UpdateValues = (data) => {
    return {
        initialValues: {
            id: data.id || '',
            nombre_banco: data.nombre_banco || '',
            numero_cuenta: data.numero_cuenta || '',
        },
        fieldsValidation: Yup.object().shape({
            nombre_banco: Yup.string()
                .min(4, 'Mínimo 4 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
            numero_cuenta: Yup.string()
                .min(4, 'Mínimo 4 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Nombre del Banco',
                name: 'nombre_banco',
                type: 'text',
                required: true,
                placeholder: 'Nombre del Banco...',
                autoFocus: true
            },
            {
                label: 'Número de cuenta',
                name: 'numero_cuenta',
                type: 'text',
                required: false,
                placeholder: 'Numero de cuenta...',
            },
        ]
    }
}

export const CerrarCuadernosValues = (data) => {
    return {
        initialValues: {
            cuadernoIds: data?.map(c => c.id) || []
            // cuadernos: data
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            {
                type: 'showSelectedData',
                label: 'Cuadernos seleccionados',
                data: data,
                items: [
                    {
                        itemLabel: 'Cuaderno',
                        itemType: 'text',
                        subItems: ['codigo'],
                    },
                    {
                        itemLabel: 'Nombre',
                        itemType: 'text',
                        subItems: ['nombres', 'apellido_paterno:apellido_materno'],
                    },
                ]
            }
        ]
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}

export const GeneralUpdateValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}

export const UpdateAlmacenValues = (data) => {
    return {
        initialValues: {
            fecha_inicio: data.fecha_inicio ? formatInputDate(new Date(data.fecha_inicio)) : formatInputDate(new Date()),
            fecha_fin: data.fecha_inicio ? formatInputDate(new Date(data.fecha_fin)) : formatInputDate(new Date()),
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            {
                label: 'Fecha de inicio',
                name: 'fecha_inicio',
                type: 'date',
                required: true,
                placeholder: 'Fecha de inicio...',
                autoFocus: false
            },
            {
                label: 'Fecha fin',
                name: 'fecha_fin',
                type: 'date',
                required: true,
                placeholder: 'Fecha fin...',
                autoFocus: false
            },
        ]
    }
}

export const UpdateEfectivoValues = (cuaderno) => {
    return {
        initialValues: {
            cuaderno_id: cuaderno.id,
            efectivo_total_neto: parseFloat(cuaderno.efectivo_total_neto?.replace(/\./g, '').replace(',', '.')) || 0,
            efectivo_entregado: parseFloat(cuaderno.efectivo_entregado?.replace(/\./g, '').replace(',', '.')) || 0,
            efectivo_sobrante: parseFloat(cuaderno.efectivo_sobrante?.replace(/\./g, '').replace(',', '.')) || 0,
            efectivo_faltante: parseFloat(cuaderno.efectivo_faltante?.replace(/\./g, '').replace(',', '.')) || parseFloat(cuaderno.efectivo_total_neto?.replace(/\./g, '').replace(',', '.')) || 0,
            billetes_total: parseFloat(cuaderno.billetes_total?.replace(/\./g, '').replace(',', '.')) || 0,
            monedas_total: parseFloat(cuaderno.monedas_total?.replace(/\./g, '').replace(',', '.')) || 0,
            dolares_total: parseFloat(cuaderno.dolares_total?.replace(/\./g, '').replace(',', '.')) || 0,
            tipo_cambio: parseFloat(cuaderno.tipo_cambio?.replace(/\./g, '').replace(',', '.')) || 6.96,
            dolares_en_bs_total: parseFloat(cuaderno.dolares_en_bs_total?.replace(/\./g, '').replace(',', '.')) || 0,
            obs: cuaderno.obs || '',
        },
        fieldsValidation: Yup.object().shape({
            // almacen_producto_id: StringRequired,
            // precio_unitario: NumberRequired,
            // cantidad: NumberIntegerMinOneRequired,
        }),
        fields: [
            {
                label: 'Efectivo neto',
                name: 'efectivo_total_neto',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Efectivo entregado',
                name: 'efectivo_entregado',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Efectivo sobrante',
                name: 'efectivo_sobrante',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Efectivo faltante',
                name: 'efectivo_faltante',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Dolares en Bs.',
                name: 'dolares_en_bs_total',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Billetes',
                name: 'billetes_total',
                type: 'groupnumberCalculator',
                subData: 'Sus.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const billetes_total = e.target.valueAsNumber || 0
                    const monedas_total = values.monedas_total || 0
                    const dolares_en_bs_total = values.dolares_en_bs_total || 0
                    const efectivo_total_neto = values.efectivo_total_neto || 0
                    const efectivo_entregado = ToTwoDecimal(billetes_total + monedas_total + dolares_en_bs_total)
                    const sobrante_o_faltante = ToTwoDecimal(efectivo_total_neto - efectivo_entregado)
                    if (sobrante_o_faltante >= 0) {
                        setValues('efectivo_faltante', sobrante_o_faltante)
                        setValues('efectivo_sobrante', 0)
                    }
                    else {
                        setValues('efectivo_sobrante', sobrante_o_faltante * -1)
                        setValues('efectivo_faltante', 0)
                    }
                    setValues('efectivo_entregado', efectivo_entregado)
                },
            },
            {
                label: 'Monedas',
                name: 'monedas_total',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const monedas_total = e.target.valueAsNumber || 0
                    const billetes_total = values.billetes_total || 0
                    const dolares_en_bs_total = values.dolares_en_bs_total || 0
                    const efectivo_total_neto = values.efectivo_total_neto || 0
                    const efectivo_entregado = ToTwoDecimal(billetes_total + monedas_total + dolares_en_bs_total)
                    const sobrante_o_faltante = ToTwoDecimal(efectivo_total_neto - efectivo_entregado)
                    if (sobrante_o_faltante >= 0) {
                        setValues('efectivo_faltante', sobrante_o_faltante)
                        setValues('efectivo_sobrante', 0)
                    }
                    else {
                        setValues('efectivo_sobrante', sobrante_o_faltante * -1)
                        setValues('efectivo_faltante', 0)
                    }
                    setValues('efectivo_entregado', efectivo_entregado)
                },
            },
            {
                label: 'Dolares',
                name: 'dolares_total',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const dolares_total = e.target.valueAsNumber || 0
                    const tipo_cambio = values.tipo_cambio || 0
                    const monedas_total = values.monedas_total || 0
                    const billetes_total = values.billetes_total || 0
                    const dolares_en_bs_total = dolares_total * tipo_cambio
                    const efectivo_total_neto = values.efectivo_total_neto || 0
                    const efectivo_entregado = ToTwoDecimal(billetes_total + monedas_total + dolares_en_bs_total)
                    const sobrante_o_faltante = ToTwoDecimal(efectivo_total_neto - efectivo_entregado)
                    if (sobrante_o_faltante >= 0) {
                        setValues('efectivo_faltante', sobrante_o_faltante)
                        setValues('efectivo_sobrante', 0)
                    }
                    else {
                        setValues('efectivo_sobrante', sobrante_o_faltante * -1)
                        setValues('efectivo_faltante', 0)
                    }
                    setValues('efectivo_entregado', efectivo_entregado)
                    setValues('dolares_en_bs_total', dolares_en_bs_total)
                },
            },
            {
                label: 'Tipo de cambio',
                name: 'tipo_cambio',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const tipo_cambio = e.target.valueAsNumber || 0
                    const dolares_total = values.dolares_total || 0
                    const monedas_total = values.monedas_total || 0
                    const billetes_total = values.billetes_total || 0
                    const dolares_en_bs_total = dolares_total * tipo_cambio
                    const efectivo_total_neto = values.efectivo_total_neto || 0
                    const efectivo_entregado = ToTwoDecimal(billetes_total + monedas_total + dolares_en_bs_total)
                    const sobrante_o_faltante = ToTwoDecimal(efectivo_total_neto - efectivo_entregado)
                    if (sobrante_o_faltante >= 0) {
                        setValues('efectivo_faltante', sobrante_o_faltante)
                        setValues('efectivo_sobrante', 0)
                    }
                    else {
                        setValues('efectivo_sobrante', sobrante_o_faltante * -1)
                        setValues('efectivo_faltante', 0)
                    }
                    setValues('efectivo_entregado', efectivo_entregado)
                    setValues('dolares_en_bs_total', dolares_en_bs_total)
                },
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const UpdateCuadernoEfectivoValues = (cuaderno) => {
    return {
        initialValues: {
            cuaderno_id: cuaderno.id,
            cuadernoData: cuaderno,
            recibo: cuaderno.recibo ?? '',
            // efectivo_total_neto: parseFloat(cuaderno.efectivo_total_neto?.replace(/\./g, '').replace(',', '.')) || 0,
            // efectivo_entregado: parseFloat(cuaderno.efectivo_entregado?.replace(/\./g, '').replace(',', '.')) || 0,
            // efectivo_sobrante: parseFloat(cuaderno.efectivo_sobrante?.replace(/\./g, '').replace(',', '.')) || 0,
            // efectivo_faltante: parseFloat(cuaderno.efectivo_faltante?.replace(/\./g, '').replace(',', '.')) || parseFloat(cuaderno.efectivo_total_neto?.replace(/\./g, '').replace(',', '.')) || 0,
            // billetes_total: parseFloat(cuaderno.billetes_total?.replace(/\./g, '').replace(',', '.')) || 0,
            // monedas_total: parseFloat(cuaderno.monedas_total?.replace(/\./g, '').replace(',', '.')) || 0,
            // dolares_total: parseFloat(cuaderno.dolares_total?.replace(/\./g, '').replace(',', '.')) || 0,
            // tipo_cambio: parseFloat(cuaderno.tipo_cambio?.replace(/\./g, '').replace(',', '.')) || 6.96,
            // dolares_en_bs_total: parseFloat(cuaderno.dolares_en_bs_total?.replace(/\./g, '').replace(',', '.')) || 0,
            obs: cuaderno.obs || '',
        },
        fieldsValidation: Yup.object().shape({
            // almacen_producto_id: StringRequired,
            // precio_unitario: NumberRequired,
            // cantidad: NumberIntegerMinOneRequired,
        }),
        fields: [
            {
                label: 'Efectivo',
                name: 'efectivos',
                type: 'cuadernoEfectivoPersonal',
            },
            {
                label: 'Recibo',
                name: 'recibo',
                type: 'text',
                required: false,
                placeholder: '####',
                autoFocus: false
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const RegistrarEfectivoFaltanteValues = (cuaderno) => {
    return {
        initialValues: {
            cuaderno_id: cuaderno.id,
            monto_total: cuaderno.faltante_sobrante_calculado ?? 0,
            obs: '',
            tipo: cuaderno.tipo ?? ''
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            {
                label: 'Pertenece a:',
                name: 'tipo',
                type: 'radioStatic',
                required: true,
                // urlApi: `/estados/${1}`,
                checOptions: [
                    {
                        id: 'faltante',
                        nombre: 'Faltante'
                    },
                    {
                        id: 'sobrante',
                        nombre: 'Sobrante'
                    }
                ]
            },
            // {
            //     label: 'Seleccione caja de producto',
            //     name: 'producto_id',
            //     type: 'selectSearch',
            //     urlApi: `/cuadernosEfectivo/${cuaderno.id}/productos`,
            //     labelDescription: 'nombre',
            //     placeholder: 'Cuaderno',
            //     autoFocus: false,
            // },
            {
                label: 'Monto',
                name: 'monto_total',
                type: 'groupnumber',
                subData: 'Bs.',
                disabled: false,
                highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}