import React, { useState } from 'react'
import CardInfo from './CardInfo'
import { VendedorTab } from './VendedorTab';

export const PerfilVendedor = ( mainReloadTable, setMainReloadTable ) => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <>
            <div className='dark:bg-[#4a5c68] bg-[#4a5c6830] p-1 rounded-md'>
                <CardInfo
                    reload={reload}
                    data={data}
                    setData={setData}
                />
                <VendedorTab
                    reload={reload}
                    setReload={setReload}
                    dataCard={data}
                    mainReloadTable={mainReloadTable}
                    setMainReloadTable={setMainReloadTable}
                />
            </div>
        </>
    )
}
