import React, { useState } from 'react'
// import MainTargetInfo from './MainTargetInfo'
import { ListEfectivoCierreTable } from './ListEfectivoCierreTable';
import CardInfoEfectivo from '../elementosGenerales/CardInfo';
import { Route, Routes } from 'react-router-dom';
import { ErrorPage } from '../../../../error/errorPage';
import { EfectivoHistorial } from './efectivo_historial_cierres';

export const EfectivoCierresHistorial = ({ mainReloadTable, setMainReloadTable }) => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <div className='grid grid-cols-1 gap-2'>
            <div className='col-span-1 xl:col-span-1 dark:bg-[#4a5c68] bg-[#4a5c6840] p-1 rounded-md'>
                <CardInfoEfectivo
                    reload={reload}
                    data={data}
                    setData={setData}
                    nameLabel='CIERRES'
                />
                <ListEfectivoCierreTable
                    reload={reload}
                    setReload={setReload}
                    dataCard={data}
                    mainReloadTable={mainReloadTable}
                    setMainReloadTable={setMainReloadTable}
                />
            </div>
            <Routes>
                <Route index element={
                    null
                } />
                <Route
                    path={`/historial/:efectivo_cierre_id`}
                    element={<EfectivoHistorial
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                    />}
                />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </div>
    )
}
