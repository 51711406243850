import React from 'react'
import { HistorialDeSesiones } from './historial_de_sesiones'
import { Users } from './users'
import { HistorialDeCambios } from './historial_de_cambios'
import { Roles } from './roles'
import { Productos } from './productos'
import { Almacenes } from './almacenes'
import { useParams } from 'react-router-dom'
import { Estados } from './estados'
import { CuentaBancaria } from './cuenta_bancarias'

import { Departamentos } from './departamentos'
// import { EfectivoGeneral } from './efectivos/efectivo_general'
// import { EfectivoBanco } from './efectivos/efectivo_bancos'
// import { EfectivoProducto } from './efectivos/efectivo_productos'
import { Cuaderno } from './cuadernos'
import { Deudas } from './deudas'
import { Garantias } from './garantias'
import { Prestamos } from './prestamos'
import { Vendedores } from './vendedores'
import { Clientes } from './clientes'
import { Efectivo } from './efectivos'
// import { EfectivoProducto } from './efectivos/efectivo_producto'

export const Menu = () => {
    const params = useParams()
    // console.log(params.menuname)
    return (
        <>
            {
                params.menuname === 'usuarios' &&
                <Users />
            }
            {
                params.menuname === 'vendedores' &&
                <Vendedores />
            }
            {
                params.menuname === 'clientes' &&
                <Clientes />
            }
            {
                params.menuname === 'roles' &&
                <Roles />
            }
            {
                params.menuname === 'departamentos' &&
                <Departamentos />
            }
            {
                params.menuname === 'estados' &&
                <Estados />
            }
            {
                params.menuname === 'cuentas-bancarias' &&
                <CuentaBancaria />
            }
            {
                params.menuname === 'productos' &&
                <Productos />
            }
            {
                params.menuname === 'almacenes' &&
                <Almacenes />
            }
            {
                params.menuname === 'cuadernos' &&
                <Cuaderno />
            }
            {
                params.menuname === 'deudas' &&
                <Deudas />
            }
            {
                params.menuname === 'garantias' &&
                <Garantias />
            }
            {
                params.menuname === 'prestamos' &&
                <Prestamos />
            }
            {
                params.menuname === 'efectivo' &&
                <Efectivo />
            }
            {/* {
                params.menuname === 'producto-efectivo' &&
                <EfectivoProducto />
            } */}
            {/* {
                params.menuname === 'efectivo-general' &&
                <EfectivoGeneral />
            }
            {
                params.menuname === 'efectivo-bancos' &&
                <EfectivoBanco />
            }
            {
                params.menuname === 'efectivo-productos' &&
                <EfectivoProducto />
            } */}
            {
                params.menuname === 'historial-de-sesiones' &&
                <HistorialDeSesiones />
            }
            {
                params.menuname === 'historial-de-cambios' &&
                <HistorialDeCambios />
            }
            
        </>
    )
}
