import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { CreateValues, IngresoCuadernoValues } from '../../../../../FormSchemes/EfectivoGeneralHistorialScheme'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { CreateValuesEfectivoTraspasosExterno, CreateValuesExtraerSubEfectivosExterno } from '../../../../../FormSchemes/EfectivoProductoScheme'
import { useSelector } from 'react-redux'
import { setReloadMainTable } from '../../../../../store/slices/auth/authSlice'

export const ListEfectivoProductoTable = ({
    reload,
    setReload,
    dataCard,
    mainReloadTable,
    setMainReloadTable
}) => {
    const {
        dispatch,
        params,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        // currentData,
        setCurrentData,
        // deleteModal,
        setDeleteModal,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
        UpdateValuesModal,
        redirect
    } = useGeneralParams('nombre')

    const [ingresoCuadernoModal, setIngresoCuadernoModal] = useState(false)
    const [traspasosModal, setTraspasosModal] = useState(false)
    const [extraerSubEfectivosModal, setExtraerSubEfectivosModal] = useState(false)
    // const [modalTraspasoExterno, setModalTraspasoExterno] = useState(false);
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    const getDataPaginate = async () => {
        await requestAuthPaginate(
            'get',
            `/efectivo/${params.efectivo_id}/subEfectivos/pag`,
            null,
            paginate,
            setData,
            setStateData,
            setPaginate,
            dispatch
        )
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters, params.efectivo_id, reloadSubTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters]);

    const recall = () => {
        getDataPaginate()
        setReload(!reload)
        setMainReloadTable(!mainReloadTable)
    }

    return (
        <Section>
            <ActionSection>
                {
                    <Actions
                        buttons={[
                            {
                                icon: 'minus',
                                label: 'Centralizar efectivo',
                                action: () => setExtraerSubEfectivosModal(true),
                                className: 'dark:bg-red-700 bg-red-500 text-white'
                            },
                        ]}
                    />
                }
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: "Nombre",
                            value: "nombre"
                        },
                        {
                            label: "Estado",
                            value: "estado"
                        }
                    ]}
                />
                <div className="flex gap-2 flex-wrap">
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: "Estado",
                                value: ""
                            },
                            {
                                label: "Activo",
                                value: "activo"
                            },
                            {
                                label: "Inactivo",
                                value: "inactivo"
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={recall}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Producto',
                            columns: ['producto_nombre']
                        },
                        {
                            label: 'Monto total',
                            columns: ['monto_total'],
                            numberValue: true,
                        },
                        {
                            label: 'Monto ingreso',
                            columns: ['monto_total_ingreso'],
                            numberValue: true,
                        },
                        {
                            label: 'Monto egreso',
                            columns: ['monto_total_egreso'],
                            numberValue: true,
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'green',
                                    icon: 'fa-plus',
                                    action: (data) => redirect(`movimientos/${data.id}`),
                                    reference: 'id',
                                    tooltipText: 'Ingresos'
                                },
                                {
                                    type: 'red',
                                    icon: 'fa-minus',
                                    action: (data) => redirect(`movimientos_egresos/${data.id}`),
                                    reference: 'id',
                                    tooltipText: 'Egresos'
                                },
                                // {
                                //     type: 'yellow',
                                //     icon: 'fa-money-bills',
                                //     action: (data) => redirect(`cierres/${data.id}`),
                                //     reference: 'id',
                                //     tooltipText: 'Cierres de efectivo'
                                // },
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id',
                                    validate: { value: 'abierto', validator: true }
                                }
                            ],
                            // stickyR: true
                        },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {/* <Paginator
                paginate={paginate}
                setPaginate={setPaginate}
            /> */}
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label="Registrar Ingreso directo de efectivo"
                    dataValues={CreateValues(params?.efectivo_id, true)}
                    urlApi={`/efectivo_historial`}
                    method={'post'}
                    call={() => {
                        recall()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                ingresoCuadernoModal &&
                <ModalForm
                    setModal={setIngresoCuadernoModal}
                    label="Registrar Ingreso de cuaderno"
                    dataValues={IngresoCuadernoValues(params?.efectivo_id, true)}
                    urlApi={`/efectivo_historial`}
                    method={'post'}
                    call={() => {
                        recall()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                traspasosModal &&
                <ModalForm
                    setModal={setTraspasosModal}
                    label='Cargar almacenes'
                    dataValues={CreateValuesEfectivoTraspasosExterno(dataCard)}
                    urlApi={`/efectivoTraspasos`}
                    method={'post'}
                    call={() => {
                        recall()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                extraerSubEfectivosModal &&
                <ModalForm
                    setModal={setExtraerSubEfectivosModal}
                    label='Cargar almacenes'
                    dataValues={CreateValuesExtraerSubEfectivosExterno(dataCard)}
                    urlApi={`/extraerSubEfectivos`}
                    method={'post'}
                    call={() => {
                        recall()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }

            {/* {
                deleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label="Eliminar producto del almacén"
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/almacen_producto/${currentData.id}`}
                    method={'delete'}
                    call={recall}
                    buttonLabel='Eliminar'
                />
            } */}
        </Section>
    )
}