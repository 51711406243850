import React from 'react'
import { useParams } from 'react-router-dom';
import { DescriptionTargetInfo, ItemCardTargetInfo, LogoTargetInfo, MainTargetInfo, TitleTargetInfo } from '../../../../../components/card/MainTargetInfo';

const CardInfo = ({ reload, data, setData }) => {
    const params = useParams()
    return (
        <MainTargetInfo
            extraClassName='grid-cols-1 gap-1'
            urlApi={`/users_vend/${params.user_vend_id}`}
            setData={setData}
            dataRef={params.user_vend_id}
            reload={reload}
        >
            <ItemCardTargetInfo
                logo={<LogoTargetInfo logo='fa-solid fa-user-circle' />}
            >
                <TitleTargetInfo label='' data={data?.codigo} />
                <DescriptionTargetInfo label='' data={data?.nombres} />
                <DescriptionTargetInfo label='' data={data?.estado} />
            </ItemCardTargetInfo>
        </MainTargetInfo>
    )
}

export default CardInfo