import React from 'react'
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { AlmacenProductos } from './almacen_productos';
import { ErrorPage } from '../../../error/errorPage';
import { Compras } from './compras';
import { ListAlmacenTable } from './ListAlmacenTable';

export const Almacenes = () => {
    return (
        // <div className='grid xl:grid-cols-1 gap-4 divide-y-2 divide-gray-600'>
        // <div className='grid grid-cols-1'>
        <>
            <div className='col-span-1 mb-2'>
                <ListAlmacenTable />
            </div>
            <Routes>
                <Route index element={
                    null
                } />
                <Route
                    path={`/productos/:almacen_id/*`}
                    element={<AlmacenProductos />}
                />
                <Route
                    path={`/compras/:almacen_id/*`}
                    element={<Compras />}
                />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </>
        // </div>
    )
}