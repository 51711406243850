import React, { useState } from "react"
import { ListClientesTable } from "./ListClientesTable"
import { Route, Routes } from "react-router-dom"
import { ErrorPage } from "../../../error/errorPage"
import { PerfilCliente } from "./perfil_cli"


export const Clientes = () => {
    const [mainReloadTable, setMainReloadTable] = useState(false)
    return (
        <div className='grid xl:grid-cols-2 gap-2'>
            <div className='col-span-2 xl:col-span-1'>
                <ListClientesTable
                    mainReloadTable={mainReloadTable}
                    setMainReloadTable={setMainReloadTable}
                />
            </div>
            <Routes>
                <Route index element={
                    null
                } />
                <Route
                    path={'/users_cli/:user_cli_id/*'}
                    element={
                        <PerfilCliente
                            mainReloadTable={mainReloadTable}
                            setMainReloadTable={setMainReloadTable}
                        />
                    }
                />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </div>
    )
}