import React, { useState } from 'react'
import { ListVendedoresTable } from './ListVendedoresTable'
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { ErrorPage } from '../../../error/errorPage';
import { PerfilVendedor } from './perfil_vend';

export const Vendedores = () => {
    const [mainReloadTable, setMainReloadTable] = useState(false)
    return (
        <div className='grid xl:grid-cols-2 gap-2'>
            <div className='col-span-2 xl:col-span-1'>
                <ListVendedoresTable
                    mainReloadTable={mainReloadTable}
                    setMainReloadTable={setMainReloadTable}
                />
            </div>
            <Routes>
                <Route index element={
                    null
                } />
                    <Route
                        path={`/users_vend/:user_vend_id/*`}
                        element={<PerfilVendedor 
                            mainReloadTable={mainReloadTable}
                            setMainReloadTable={setMainReloadTable}
                        />}
                    />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </div>
    )
}