import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import { Actions } from '../../../../components/form/actions'
import { RegistroDeudaInmediataValues } from '../../../../FormSchemes/DeudaScheme'

export const ListDeudaTable = () => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        // imports
        requestAuthPaginate,
        TableContainer,
        formatDateWithTime,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        TableSection,
        ActionSection,
        ModalForm,
        Section,
        redirect
    } = useGeneralParams('nombres')
    const [registrarDeudaInmediataModal, setRegistrarDeudaInmediataModal] = useState(false)
    const getDataPaginate = async () => {
        await requestAuthPaginate(
            'get',
            `/deudas/pag`,
            null,
            paginate,
            setData,
            setStateData,
            setPaginate,
            dispatch
        )
    }

    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters]);

    // useEffect(() => {
    //     console.log(selecteds)
    // }, [selecteds]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters]);

    const recall = () => {
        getDataPaginate()
    }
    return (
        <Section>
            <ActionSection>
                {
                    <Actions
                        buttons={[
                            // {
                            //     icon: 'minus',
                            //     label: 'Registrar deuda inmediata',
                            //     action: () => setRegistrarDeudaInmediataModal(true),
                            //     className: 'dark:bg-red-700 bg-red-500 text-white'
                            // },
                        ]}
                    />
                }
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    width='6/12'
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombres'
                        },
                        {
                            label: 'Código',
                            value: 'codigo'
                        }
                    ]}
                    reloadTable={recall}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='tipo_accion'
                        options={[
                            {
                                label: 'Tipo de acción',
                                value: ''
                            },
                            {
                                label: 'Pendientes',
                                value: 'pendientes'
                            },
                            {
                                label: 'Pagadas',
                                value: 'pagadas'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={recall}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Encargado',
                            columns: ['encargadoCodigo', 'encargadoDetalles'],
                            // icon: 'fa-solid fa-user-circle text-lg',
                            // skipLine: true,
                            className: 'flex items-center',
                            // stickyL: true
                        },
                        {
                            label: 'Deudor',
                            columns: ['deudorCodigo', 'deudorDetalles'],
                            // icon: 'fa-solid fa-user-circle text-lg',
                            // skipLine: true,
                            className: 'flex items-center',
                            // stickyL: true
                        },
                        {
                            label: 'Detalles',
                            columns: ['tipo'],
                        },
                        {
                            label: 'Cuaderno',
                            columns: ['cuaderno'],
                        },
                        {
                            label: 'Monto inicial Bs.',
                            columns: ['saldo_inicial'],
                        },
                        {
                            label: 'Saldo Bs.',
                            columns: ['saldo'],
                        },
                        {
                            label: 'Estado',
                            columns: ['estado'],
                            boolean: true,
                            booleanOptions: ['Completado', 'Pendiente'],
                            booleanIconTrue: 'fa-check',
                            booleanIconFalse: 'fa-circle'
                        },
                        {
                            label: 'Fecha de Prestamo',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        {
                            label: 'Fecha límite',
                            columns: ['fecha_limite'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'cyan',
                                    icon: 'fa-eye',
                                    action: (data) => {
                                        redirect(`deudas/${data.id}/`)
                                    },
                                    reference: 'id',
                                },
                            ],
                        },
                    ]}
                    data={data.data}
                    checkList={false}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                registrarDeudaInmediataModal &&
                <ModalForm
                    setModal={setRegistrarDeudaInmediataModal}
                    label='Registrar deuda inmediata'
                    dataValues={RegistroDeudaInmediataValues()}
                    urlApi={`/deudaInmediata`}
                    method={'post'}
                    call={() => {
                        recall()
                        // dispatch(setReloadSubTable())
                        // dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
        </Section>
    )
}