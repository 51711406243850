import React from 'react'
import { useParams } from 'react-router-dom';
import { DescriptionTargetInfo, ItemCardTargetInfo, LogoLabelTargetInfo, MainTargetInfo, NumberCalculatorsTargetInfo, NumberGridTargetInfo, SubTitleTargetInfo, TitleTargetInfo, ValueTargetInfo } from '../../../../../components/card/MainTargetInfo';
import { JoinStrings } from '../../../../../utils/dataValidations'
import { formatDateWithTime } from '../../../../../utils/dateFormat';
import { NoteIconSvg } from '../../../../../utils/styleFormat';

const CardInfo = ({ reload, data, setData }) => {
    const params = useParams()
    return (
        <MainTargetInfo
            extraClassName='grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:md:grid-cols-4 gap-1'
            urlApi={`/cuaderno/${params.cuaderno_id}`}
            setData={setData}
            dataRef={params.cuaderno_id}
            reload={reload}
        >
            <ItemCardTargetInfo
                logoLabel={<LogoLabelTargetInfo logo={<NoteIconSvg />} />}
                aditionalClassName='col-span-2'
            >
                <TitleTargetInfo label={''} data={data?.codigo} />
                {
                    data?.user_id &&
                    <SubTitleTargetInfo label='' data={JoinStrings([data.nombres, data.apellido_paterno, data.apellido_materno], ' ')} />
                }
                <DescriptionTargetInfo label='Fecha de apertura:' data={formatDateWithTime(data?.fecha_apertura)} />
                <DescriptionTargetInfo label='fecha de cierre:' data={formatDateWithTime(data?.fecha_cierre)} />
                {
                    data?.abierto ?
                        <DescriptionTargetInfo label='Estado:' data='Abierto' />
                        :
                        <ValueTargetInfo label='Estado:' data='Cerrado' icon={true} />
                }
                {
                    data?.revisado ?
                        <ValueTargetInfo label='Estado:' data='Revisado' icon={true} />
                        :
                        <DescriptionTargetInfo label='Estado:' data='Pendiente de revisión' />
                }
            </ItemCardTargetInfo>
            {/* <SubTitleTargetInfo label={null} data={'Detalles'} /> */}
            <NumberCalculatorsTargetInfo>
                <NumberGridTargetInfo label='Ventas Bs.' data={data?.venta_total} />
                <NumberGridTargetInfo label='Deudas Bs.' data={data?.deuda_total} />
                <NumberGridTargetInfo label='Tranferencias Bs.' data={data?.transferencia_total} />
                <NumberGridTargetInfo label='Cobros Bs.' data={data?.cobros_total} />
                <NumberGridTargetInfo label='Gastos Bs.' data={data?.gastos_total} />
                <NumberGridTargetInfo label='Efectivo Bs.' data={data?.efectivo_total} borderBotton={false} />
                {/* <EmptyGridTargetInfo label='.' data='' borderBotton={false} /> */}
            </NumberCalculatorsTargetInfo>
            <NumberCalculatorsTargetInfo>
                <NumberGridTargetInfo label='Garantías Bs.' data={data?.garantias_total} />
                <NumberGridTargetInfo label='Gar. ingresos Bs.' data={data?.garantias_ingreso} />
                <NumberGridTargetInfo label='Gar. egresos Bs.' data={data?.garantias_egreso} />
                <NumberGridTargetInfo label='Efvo. sobrante Bs.' data={data?.tipo === 'sobrante' ? data?.faltante_sobrante_calculado_string : '0,00'} />
                <NumberGridTargetInfo label='Efvo. faltante Bs.' data={data?.tipo === 'faltante' ? data?.faltante_sobrante_calculado_string : '0,00'} />
                {/* <NumberGridTargetInfo label='Efvo. entregado Bs.' data={data?.efectivo_entregado} /> */}
                <NumberGridTargetInfo label='Efvo. neto Bs.' data={data?.efectivo_total_neto} borderBotton={false} />
            </NumberCalculatorsTargetInfo>
        </MainTargetInfo>
    )
}

export default CardInfo