import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../../../../hooks/useDataPaginate'
import { CreateValues, CreateValuesDescargaDeExternos, CreateValuesTraspasosExterno, CreateValuesTraspasosInternos, DeleteValues } from '../../../../../../../FormSchemes/AlmacenHistorialScheme'
import { formatDateWithTime } from '../../../../../../../utils/dateFormat'
import { MinimalActionButton } from '../../../../../../../components/form/minimalActionButton'
import { setReloadMainTable } from '../../../../../../../store/slices/auth/authSlice'
// import { useGeneralParams } from '../../../../../../hooks/useDataPaginate'
// import { CreateValues, DeleteValues } from '../../../../../../FormSchemes/AlmacenProductoScheme'

export const ListAlmacenHistorialTable = ({
    reload,
    setReload,
    dataCard
}) => {
    const {
        dispatch,
        params,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        currentData,
        deleteModal, setDeleteModal,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
        // UpdateValuesModal,
    } = useGeneralParams('codigo')

    // const [modalTraspaso, setModalTraspaso] = useState(false);
    // const [modalTraspasoExterno, setModalTraspasoExterno] = useState(false);
    const [traspasosModal, setTraspasosModal] = useState(false);
    const [descargasModal, setDescargasModal] = useState(false);
    const [traspasosIternosModal, setTraspasosIternosModal] = useState(false);
    const [confirmarModal, setConfirmarModal] = useState(false);

    const getDataPaginate = async () => {
        await requestAuthPaginate(
            'get',
            `/almacenCierre/${params.almacen_cierre_id}/historial/pag`,
            null,
            paginate,
            setData,
            setStateData,
            setPaginate,
            dispatch
        )
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters, params.almacen_cierre_id]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters]);

    const recall = () => {
        getDataPaginate()
        setReload(!reload)
    }
    const reloadTable = () => {
        getDataPaginate()
    }

    return (
        <Section>
            <ActionSection>
                <Actions
                    buttons={[
                        // {
                        //     icon: 'minus',
                        //     label: 'Cargar almacenes',
                        //     action: () => setTraspasosModal(true),
                        //     className: 'dark:bg-red-700 bg-red-500 text-white'
                        // },
                        // {
                        //     icon: 'plus',
                        //     label: 'Descargar de almacenes',
                        //     action: () => setDescargasModal(true),
                        //     className: 'dark:bg-green-700 bg-green-500 text-white'
                        // },
                        // {
                        //     icon: 'minus',
                        //     label: 'Mover mis productos',
                        //     action: () => setTraspasosIternosModal(true),
                        //     className: 'dark:bg-sky-600 bg-sky-500 text-white'
                        // },
                        // {
                        //     icon: 'plus',
                        //     label: 'Ingreso directo',
                        //     action: () => setCreateModal(true)
                        // },
                        // {
                        //     icon: 'plus-minus',
                        //     label: 'Traspaso interno',
                        //     action: () => setModalTraspaso(true),
                        //     className: 'bg-sky-400 dark:bg-sky-600 text-white'
                        // },
                        // {
                        //     icon: 'plus-minus',
                        //     label: 'Traspaso a otro almacén',
                        //     action: () => setModalTraspasoExterno(true),
                        //     className: 'bg-green-400 dark:bg-green-700 text-white'
                        // },
                    ]}
                />
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        // {
                        //     label: 'Nombre',
                        //     value: 'nombre'
                        // },
                        {
                            label: 'Código',
                            value: 'codigo'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='tipo'
                        options={[
                            {
                                label: 'Todos',
                                value: ''
                            },
                            {
                                label: 'Ingreso',
                                value: 'ingreso'
                            },
                            {
                                label: 'Egreso',
                                value: 'egreso'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={recall}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Origen / Destino',
                            columns: ['almacen_nombre', 'almacen_encargado_nombre', 'almacen_encargado_detalles', 'user_nombre', 'user_detalles', 'encargado_proveedor']
                        },
                        {
                            label: 'Cantidad anterior',
                            columns: ['cantidad_anterior'],
                            numberValue: true
                        },
                        {
                            label: 'Cantidad',
                            columns: ['cantidad'],
                            numberValue: true
                        },
                        {
                            label: 'Cantidad actual',
                            columns: ['cantidad_actual'],
                            numberValue: true
                        },
                        {
                            label: 'Cantidad en garantía',
                            columns: ['cantidad_garantia'],
                            numberValue: true
                        },
                        {
                            label: 'Garantía Bs.',
                            columns: ['monto_garantia'],
                            numberValue: true
                        },
                        {
                            label: 'Cantidad deuda',
                            columns: ['cantidad_deuda'],
                            numberValue: true
                        },
                        {
                            label: 'Deuda Bs.',
                            columns: ['monto_deuda'],
                            numberValue: true
                        },
                        {
                            label: 'ingreso / egreso',
                            columns: ['ingreso'],
                            booleanState: true,
                            booleanOptions: ['ingreso', 'egreso']
                        },
                        {
                            label: 'transacción',
                            columns: ['codigo_transaccion', 'tipo'],
                        },
                        {
                            label: 'CONFIRMACIÓN',
                            columns: ['confirmado'],
                            boolean: true,
                            booleanOptions: ['Confirmado', 'Pendiente'],
                            booleanIconTrue: 'fa-check',
                            booleanIconFalse: 'fa-xmark'
                        },
                        {
                            label: 'Fecha de registro',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        // {
                        //     label: 'Acciones',
                        //     actions: [
                        //         // {
                        //         //     type: 'edit',
                        //         //     icon: 'fa-edit',
                        //         //     // action: (data) => UpdateValuesModal(data, setCurrentData, setUpdateModal),
                        //         // },
                        //         {
                        //             type: 'yellow',
                        //             icon: 'fa-check',
                        //             action: (data) => UpdateValuesModal(data, setCurrentData, setConfirmarModal),
                        //             reference: 'id'
                        //         },
                        //         {
                        //             type: 'delete',
                        //             icon: 'fa-trash',
                        //             action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                        //             reference: 'id'
                        //         },
                        //     ],
                        //     // stickyR: true
                        // },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                traspasosModal &&
                <ModalForm
                    setModal={setTraspasosModal}
                    label='Cargar almacenes'
                    dataValues={CreateValuesTraspasosExterno(params?.almacen_producto_id, dataCard, 'Cargar carros')}
                    urlApi={`/almacenHistorialTraspasos`}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                descargasModal &&
                <ModalForm
                    setModal={setDescargasModal}
                    label='Descargar de almacenes'
                    dataValues={CreateValuesDescargaDeExternos(params?.almacen_producto_id, dataCard, 'Descarga de envases')}
                    urlApi={`/almacenHistorialDescargas`}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                traspasosIternosModal &&
                <ModalForm
                    setModal={setTraspasosIternosModal}
                    label='Mover mis productos'
                    dataValues={CreateValuesTraspasosInternos(params?.almacen_producto_id, dataCard)}
                    urlApi={`/almacenHistorialTraspasosInternos`}
                    method={'post'}
                    call={() => {
                        recall()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label='Ingreso directo'
                    dataValues={CreateValues(params?.almacen_producto_id)}
                    urlApi={`/almacenHistorial`}
                    method={'post'}
                    call={() => {
                        recall()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {/* {
                modalTraspaso &&
                <ModalForm
                    setModal={setModalTraspaso}
                    label='Traspaso interno'
                    dataValues={CreateValuesTraspaso(params?.almacen_producto_id)}
                    urlApi={`/almacen_historial_traspaso`}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            } */}
            {/* {
                modalTraspasoExterno &&
                <ModalForm
                    setModal={setModalTraspasoExterno}
                    label='Traspaso a otro almacén'
                    dataValues={CreateValuesTraspasoExterno(params?.almacen_producto_id)}
                    urlApi={`/almacen_historial_traspaso`}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            } */}
            {
                deleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar movimiento del almacén'
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/almacen_historial/${currentData.id}`}
                    method={'delete'}
                    call={recall}
                    buttonLabel='Eliminar'
                />
            }
            {
                confirmarModal &&
                <ModalForm
                    setModal={setConfirmarModal}
                    label='Confirmar movimiento del almacén'
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/almacenHistorial/${currentData.id}/confirmar`}
                    method={'put'}
                    call={recall}
                    buttonLabel='Confirmar'
                />
            }
        </Section>
    )
}