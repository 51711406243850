import * as Yup from 'yup';
import { DecimalValidation, DecimalValidationMoreThanZero, ToOneDecimal } from '../utils/dataValidations';

export const CreateValues = (efectivo_id, ingreso) => {
    return {
        initialValues: {
            efectivo_id: efectivo_id,
            monto: 0,
            billetes: 0,
            monedas: 0,
            dolares_en_bs: 0,
            ingreso: ingreso,

        },
        fieldsValidation: Yup.object().shape({
            monto: DecimalValidationMoreThanZero,
            billetes: DecimalValidation,
            monedas: DecimalValidation,
            dolares_en_bs: DecimalValidation
        }),
        fields: [
            {
                label: 'Monto',
                name: 'monto',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: false,
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const monto = e.target.valueAsNumber || 0;
                    setValues('billetes', monto)
                    setValues('monedas', 0)
                    setValues('dolares_en_bs', 0)
                }
            },
            {
                label: 'Billetes',
                name: 'billetes',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: false,
                ChangeCalculator: (values, setValues, e) => {
                    const billetes = e.target.valueAsNumber || 0;
                    const monedas = values.monedas || 0;
                    const dolares_en_bs = values.dolares_en_bs || 0;
                    const resultado = ToOneDecimal(billetes + monedas + dolares_en_bs);
                    setValues('monto', resultado)
                }
            },
            {
                label: 'Monedas',
                name: 'monedas',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: false,
                ChangeCalculator: (values, setValues, e) => {
                    const monedas = e.target.valueAsNumber || 0;
                    const billetes = values.billetes || 0;
                    const dolares_en_bs = values.dolares_en_bs || 0;
                    const resultado = ToOneDecimal(billetes + monedas + dolares_en_bs);
                    setValues('monto', resultado)
                }
            },
            {
                label: 'Dolares en Bs.',
                name: 'dolares_en_bs',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: false,
                ChangeCalculator: (values, setValues, e) => {
                    const dolares_en_bs = e.target.valueAsNumber || 0;
                    const billetes = values.billetes || 0;
                    const monedas = values.monedas || 0;
                    const resultado = ToOneDecimal(billetes + monedas + dolares_en_bs);
                    setValues('monto', resultado)
                }
            }
        ],
    }
}

export const IngresoCuadernoValues = (efectivo_id) => {
    return {
        initialValues: {
            efectivo_id: efectivo_id,
            // monto: 0,
            // billetes: 0,
            // monedas: 0,
            // dolares_en_bs: 0,
            cuaderno_id:'',
            recibo: '',
            obs: '',
            // efectivo_entregado: 0,
            // billetes_total: 0,
            // monedas_total: 0,
            // dolares_total: 0,
            // faltante_total: 0,
            // ingreso: true,
            type: 'Ingreso de cuaderno'

        },
        fieldsValidation: Yup.object().shape({
            // monto: DecimalValidationMoreThanZero,
            // billetes: DecimalValidation,
            // monedas: DecimalValidation,
            // dolares_en_bs: DecimalValidation
        }),
        fields: [
            {
                label: 'Seleccione un cuaderno',
                name: 'cuaderno_id',
                type: 'cuadernoEfectivo',
                urlApi: 'cuadernos/cerrados',
                labelDescription: 'codigo',
                placeholder: 'Cuaderno',
                autoFocus: false,
                // subData: 'Bs.',
                // required: false,
                // ChangeCalculator: (values, setValues, e) => {
                //     const monto = e.target.valueAsNumber || 0;
                //     setValues('billetes', monto)
                //     setValues('monedas', 0)
                //     setValues('dolares_en_bs', 0)
                // }
            },
            {
                label: 'Recibo',
                name: 'recibo',
                type: 'text',
                required: false,
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
            },
            // {
            //     label: 'Total entregado',
            //     name: 'efectivo_entregado',
            //     type: 'groupnumberCalculator',
            //     subData: 'Bs.',
            //     required: false,
            //     disabled: true,
            //     ChangeCalculator: (values, setValues, e) => {
            //         // const billetes = e.target.valueAsNumber || 0;
            //         // const monedas = values.monedas || 0;
            //         // const dolares_en_bs = values.dolares_en_bs || 0;
            //         // const resultado = ToOneDecimal(billetes + monedas + dolares_en_bs);
            //         // setValues('monto', resultado)
            //     }
            // },
            // {
            //     label: 'Billetes',
            //     name: 'billetes_total',
            //     type: 'groupnumberCalculator',
            //     subData: 'Bs.',
            //     required: false,
            //     disabled: true,
            //     ChangeCalculator: (values, setValues, e) => {
            //         // const billetes = e.target.valueAsNumber || 0;
            //         // const monedas = values.monedas || 0;
            //         // const dolares_en_bs = values.dolares_en_bs || 0;
            //         // const resultado = ToOneDecimal(billetes + monedas + dolares_en_bs);
            //         // setValues('monto', resultado)
            //     }
            // },
            // {
            //     label: 'Monedas',
            //     name: 'monedas_total',
            //     type: 'groupnumberCalculator',
            //     subData: 'Bs.',
            //     required: false,
            //     disabled: true,
            //     ChangeCalculator: (values, setValues, e) => {
            //         // const billetes = e.target.valueAsNumber || 0;
            //         // const monedas = values.monedas || 0;
            //         // const dolares_en_bs = values.dolares_en_bs || 0;
            //         // const resultado = ToOneDecimal(billetes + monedas + dolares_en_bs);
            //         // setValues('monto', resultado)
            //     }
            // },
            // {
            //     label: 'Dolares',
            //     name: 'dolares_total',
            //     type: 'groupnumberCalculator',
            //     subData: 'Bs.',
            //     required: false,
            //     disabled: true,
            //     ChangeCalculator: (values, setValues, e) => {
            //         // const billetes = e.target.valueAsNumber || 0;
            //         // const monedas = values.monedas || 0;
            //         // const dolares_en_bs = values.dolares_en_bs || 0;
            //         // const resultado = ToOneDecimal(billetes + monedas + dolares_en_bs);
            //         // setValues('monto', resultado)
            //     }
            // },
            // {
            //     label: 'Faltante',
            //     name: 'efectivo_faltante',
            //     type: 'groupnumberCalculator',
            //     subData: 'Bs.',
            //     required: false,
            //     disabled: true,
            //     ChangeCalculator: (values, setValues, e) => {
            //         // const billetes = e.target.valueAsNumber || 0;
            //         // const monedas = values.monedas || 0;
            //         // const dolares_en_bs = values.dolares_en_bs || 0;
            //         // const resultado = ToOneDecimal(billetes + monedas + dolares_en_bs);
            //         // setValues('monto', resultado)
            //     }
            // },
            // // {
            // //     label: 'Monedas',
            // //     name: 'monedas',
            // //     type: 'groupnumberCalculator',
            // //     subData: 'Bs.',
            // //     required: false,
            // //     ChangeCalculator: (values, setValues, e) => {
            // //         const monedas = e.target.valueAsNumber || 0;
            // //         const billetes = values.billetes || 0;
            // //         const dolares_en_bs = values.dolares_en_bs || 0;
            // //         const resultado = ToOneDecimal(billetes + monedas + dolares_en_bs);
            // //         setValues('monto', resultado)
            // //     }
            // // },
            // // {
            // //     label: 'Dolares en Bs.',
            // //     name: 'dolares_en_bs',
            // //     type: 'groupnumberCalculator',
            // //     subData: 'Bs.',
            // //     required: false,
            // //     ChangeCalculator: (values, setValues, e) => {
            // //         const dolares_en_bs = e.target.valueAsNumber || 0;
            // //         const billetes = values.billetes || 0;
            // //         const monedas = values.monedas || 0;
            // //         const resultado = ToOneDecimal(billetes + monedas + dolares_en_bs);
            // //         setValues('monto', resultado)
            // //     }
            // // }
        ],
    }
}