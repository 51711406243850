import React, { useState } from 'react'
import CardInfo from './CardInfo'
import { ListHistorialGarantiaTable } from './ListHistorialGarantiaTable';

export const HistorialGarantia = () => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <>
            <div className='col-span-1 dark:bg-[#4c5e6b] bg-gray-300 p-1 rounded-md'>
                <CardInfo
                    reload={reload}
                    data={data}
                    setData={setData}
                />
                <ListHistorialGarantiaTable
                    reload={reload}
                    setReload={setReload}
                    dataCard={data}
                />
            </div>
        </>
    )
}
