import React, { useState } from 'react'
import CardInfo from './CardInfo'
import { CuadernoTab } from './CuadernoTab';

export const CuadernoHistorial = ({ mainReloadTable, setMainReloadTable }) => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <>
            <div className='col-span-1 dark:bg-[#4c5e6b] bg-gray-300 p-1 rounded-md'>
                <CardInfo
                    reload={reload}
                    data={data}
                    setData={setData}
                />
                <CuadernoTab
                    reload={reload}
                    setReload={setReload}
                    dataCard={data}
                    mainReloadTable={mainReloadTable}
                    setMainReloadTable={setMainReloadTable}
                />
                {/* <Routes>
                    <Route index element={
                        <ListCuadernoHistorialTable
                        reload={reload}
                        setReload={setReload}
                        dataCard={data}
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                    />
                    } />
                    <Route
                        path={`/ventas`}
                        element={
                            <ListCuadernoHistorialTable
                                reload={reload}
                                setReload={setReload}
                                dataCard={data}
                                mainReloadTable={mainReloadTable}
                                setMainReloadTable={setMainReloadTable}
                            />
                        }
                    />
                    <Route path='*' element={<ErrorPage />} />
                </Routes> */}
                {/* <ListCuadernoHistorialTable
                    reload={reload}
                    setReload={setReload}
                    dataCard={data}
                    mainReloadTable={mainReloadTable}
                    setMainReloadTable={setMainReloadTable}
                /> */}
            </div>
            {/* <div className='col-span-2 '>
            <AlmacenHistorials /> */}
            {/* <Routes>
                    <Route index element={
                        null
                    } />
                    <Route
                        path={`/historial/:almacen_producto_id`}
                        element={<AlmacenHistorials />}
                    />
                    <Route path='*' element={<ErrorPage />} />
                </Routes> */}
            {/* </div> */}
        </>
    )
}
