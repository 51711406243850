import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { requestAuth } from '../../http/httpRequest'
import { toastOn } from '../../store/slices/toast'
import { Field } from 'formik'
import Select from 'react-select'
import { colourStyles } from '../../utils/styleFormat'
import { AdvertenciaDeuda, TableItem, setFormCuadernoData } from './CuadernoEfectivoPersonalForm'

export const CuadernoEfectivoForm = ({
    field,
    errors,
    touched,
    values,
    setValues,
    handleChange
}) => {
    const [options, setOptions] = useState([])
    const [data, setData] = useState([])
    const [item, setItem] = useState(null)
    const [caja, setCaja] = useState(null)
    const [garantias, setGarantias] = useState(null)
    const [bancos, setBancos] = useState(null)
    const [faltante, setFaltante] = useState(null)
    const [gasto, setGasto] = useState(null)
    const [sobrante, setSobrante] = useState(null)

    const dispatch = useDispatch()
    const getData = async () => {
        await requestAuth(
            'get',
            field?.urlApi,
            null
        )
            .then((response) => {
                const opt = response.data.map(item => ({
                    value: item.id.toString(),
                    label: item[field.labelDescription]
                }))
                setData(response.data)

                setOptions(opt)
                // setFullData(response.data)
            }
            )
            .catch(error => {
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las opciones' }))
            })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setFormChange = (dataIn) => {
        setFormCuadernoData(dataIn, setItem, setCaja, setGarantias, setBancos, setFaltante, setGasto, setSobrante, setValues)
    }
    return (
        <>
            <div className='col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3'>
                <div className='grid grid-cols-1'>
                    <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                        {field.label}
                        {/* {field.required && <RequiredPick />} */}
                    </label>
                    <Field name={field.name}>
                        {() => (
                            <Select
                                id={field.name}
                                defaultValue={field.defaultValue}
                                placeholder={field.placeholder}
                                options={options}
                                isClearable={true}
                                onChange={(e) => {
                                    handleChange(field.name, e)
                                    setValues(field.name, e?.value ?? '')
                                    // setExtraData(data.find(l => (l.id + '') === e?.value))
                                    setFormChange(data?.find(l => (l.id + '') === e?.value))
                                }}
                                styles={colourStyles}
                                autoFocus={field.autoFocus}
                                maxMenuHeight={150}
                                noOptionsMessage={() => 'No hay opciones disponibles'}
                            />
                        )}
                    </Field>
                </div>
            </div>
            {

                item &&
                <div className='col-start-1 col-end-13 dark:text-gray-100 bg-gray-100 dark:bg-gray-700 border border-gray-200 dark:border-gray-500 rounded p-2 sm:max-h-[200px] lg:max-h-[550px] overflow-scroll'>
                    <center className='font-semibold mb-2'>CUADERNO {item.codigo}</center>
                    <TableItem
                        data={caja}
                        titleData={'CAJA'}
                        faltante={faltante}
                        gasto={gasto}
                        sobrante={sobrante}
                    />
                    <TableItem
                        data={garantias}
                        titleData={'GARANTÍAS'}
                    />
                    <TableItem
                        data={bancos}
                        titleData={'BANCOS'}
                    />
                    {
                        faltante?.monto_total > 0 &&
                        <div>
                            <AdvertenciaDeuda
                                user={item}
                                faltante={faltante}
                            />
                        </div>
                    }
                </div>
            }
        </>
    )
}