import * as Yup from 'yup';

export const CreateValues = (almacen_id) => {
    return {
        initialValues: {
            almacen_id: almacen_id || '',
            // producto_id: '',
            // estado_id: '',
            productos: []
        },
        fieldsValidation: Yup.object().shape({}),
        // fields: [
        //     {
        //         label: "Productos disponibles",
        //         name: "producto_id",
        //         type: "doubleSelectApi",
        //         required: false,
        //         optionDescription: 'nombre',
        //         urlApi: "/productos",
        //         sub_name: 'estado_id',
        //         sub_label: 'Seleccione estado',
        //         sub_urlApi: "/producto/{param}/estados",
        //         sub_optionDescription: 'etiqueta',
        //     },
        // ]
        fields: [
            {
                label: 'Seleccionar producto',
                name: 'productos',
                type: 'checkboxes',
                required: false,
                urlApi: `/producto/almacen/${almacen_id}/no-existentes`,
                indexLabel:'etiqueta'
            },
        ]        
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}