import React, { useEffect } from 'react'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { DeleteValues } from '../../../../../FormSchemes/CuadernoHistorialScheme'
import { formatDateWithTime } from '../../../../../utils/dateFormat'

export const ListCuadernoEnvasesHistorialTable = ({
    reload,
    setReload,
    mainReloadTable,
    setMainReloadTable,
    //
    params,
    setSelectAllChecked,
    setIsChecked,
    selecteds,
    setSelecteds,
    selectAllChecked,
    isChecked,
    recallState,
}) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        stateData, setStateData,
        currentData, setCurrentData,
        deleteModal, setDeleteModal,
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        TableSection,
        ActionSection,
        ModalForm,
        UpdateValuesModal,
    } = useGeneralParams('nombre')

    const getDataPaginate = async () => {
        await requestAuthPaginate(
            'get',
            `/cuaderno/${params.cuaderno_id}/envasesRecogidos/pag`,
            null,
            paginate,
            setData,
            setStateData,
            setPaginate,
            dispatch
        )
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters, params.efectivo_id, recallState]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters]);

    const recall = () => {
        setSelectAllChecked(false)
        setIsChecked(false)
        getDataPaginate()
        setReload(!reload)
        setSelecteds([])
        setMainReloadTable(!mainReloadTable)
    }

    const reloadTable = () => {
        getDataPaginate()
    }

    return (
        <>
            <ActionSection>
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: "Nombre",
                            value: "nombre"
                        },
                        {
                            label: "Estado",
                            value: "estado"
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className="flex gap-2 flex-wrap">
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: "Estado",
                                value: ""
                            },
                            {
                                label: "Activo",
                                value: "activo"
                            },
                            {
                                label: "Inactivo",
                                value: "inactivo"
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={recall}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Deudor',
                            columns: ['envaseDeudorCodigo', 'envaseDeudorDetalles']
                        },
                        {
                            label: 'Producto',
                            columns: ['producto_nombre']
                        },
                        {
                            label: 'Cantidad',
                            columns: ['cantidad'],
                            numberValue: true,
                        },
                        {
                            label: 'Monto garantía Bs.',
                            columns: ['monto'],
                            numberValue: true,
                        },
                        {
                            label: 'Prestamo / Garantía',
                            columns: ['metodo_intercambio'],
                            // tag: true
                        },
                        {
                            label: 'Fecha de prestamos',
                            columns: ['fecha_deuda'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        {
                            label: 'Estado',
                            columns: ['estado'],
                            boolean: true,
                            booleanOptions: ['Completado', 'Pendiente'],
                            booleanIconTrue: 'fa-check',
                            booleanIconFalse: 'fa-circle'
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id'
                                },
                            ],
                        },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
                {
                    deleteModal &&
                    <ModalForm
                        setModal={setDeleteModal}
                        label='Eliminar venta'
                        dataValues={DeleteValues(currentData)}
                        urlApi={`/cuadernoHistorial/${currentData.id}`}
                        method={'delete'}
                        call={recall}
                        buttonLabel='Eliminar'
                    />
                }
            </TableSection>
        </>
    )
}