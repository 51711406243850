import React from 'react'
import { Link } from 'react-router-dom'

export const TabItem = ({ to, label, active }) => {
    return (
        <Link
            to={to}
            // className={`inline-block p-4 rounded-t-lg active font-semibold shadow-md shadow-gray-400 dark:shadow-gray-800 ${active ? 'bg-yellow-50 text-gray-600 dark:bg-gray-800 dark:text-gray-300 underline underline-offset-8 decoration-4 decoration-yellow-600 dark:decoration-yellow-600' : 'bg-gray-300 text-gray-600 dark:bg-[#4c5e6b] dark:text-gray-300'}`}
            className={`inline-block py-4 px-5 min-w-[160px] rounded-t-xl active font-semibold ${active ? 'bg-yellow-50 text-gray-600 dark:bg-gray-800 dark:text-gray-300 underline underline-offset-8 decoration-4 decoration-yellow-600 dark:decoration-yellow-600' : 'bg-gray-300 text-gray-600 dark:bg-[#4c5e6b] dark:text-gray-300'}`}
        >
            {label}
        </Link>
    )
}
