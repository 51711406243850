import React, { useState } from "react"
import CardInfoCli from "./CardInfo"
import { ClienteTab } from "./ClienteTab"

export const PerfilCliente = ( mainReloadTable, setMainReloadTable ) => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <>
            <div className='dark:bg-[#4a5c68] bg-[#4a5c6830] p-1 rounded-md'>
                <CardInfoCli
                    reload={reload}
                    data={data}
                    setData={setData}
                />
                <ClienteTab
                    reload={reload}
                    setReload={setReload}
                    dataCard={data}
                    mainReloadTable={mainReloadTable}
                    setMainReloadTable={setMainReloadTable}
                />
            </div>
        </>
    )
}