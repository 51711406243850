import React, { useState } from 'react'

import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ErrorPage } from '../../../../error/errorPage'
import CardInfo from '../elementosGenerales/CardInfo'
import { ListComprasTable } from './ListComprasTable'

export const Compras = () => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <>
            <div className='col-span-2 dark:bg-[#4a5c68] bg-[#4a5c6830] p-1 rounded-md'>
                <CardInfo
                    reload={reload}
                    data={data}
                    setData={setData}
                // setReload={setReload}
                />
                <ListComprasTable
                    reload={reload}
                    setReload={setReload}
                    dataCard={data}
                />
            </div>
            <div className='col-span-1 xl:col-span-2'>
                <Routes>
                    <Route index element={
                        null
                    } />
                    {/* <Route
                        path={`/historial/:almacen_producto_id`}
                        element={<AlmacenHistorials />}
                    />
                    <Route
                        path={`/cierres/:almacen_producto_id/*`}
                        element={<AlmacenCierresHistorial />}
                    /> */}
                    <Route path='*' element={<ErrorPage />} />
                </Routes>
            </div>
        </>
    )
}
