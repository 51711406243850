import React, { useState } from 'react'
// import { ListAlmacenProductoTable } from './ListAlmacenProductoTable'
// import { Routes } from 'react-router-dom'
// import { Route } from 'react-router-dom'
// import { ErrorPage } from '../../../../error/errorPage'
// import { AlmacenHistorials } from './almacen_historials'
// import { AlmacenCierresHistorial } from './almacen_cierres'
import CardInfo from './CardInfo'
import { ListProductoEstadoTable } from './ListProductoEstadoTable';

export const ProductosEstados = () => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <>
            <div className='dark:bg-[#4a5c68] bg-[#4a5c6830] p-1 rounded-md'>
                <CardInfo
                    reload={reload}
                    data={data}
                    setData={setData}
                // setReload={setReload}
                />
                <ListProductoEstadoTable
                    reload={reload}
                    setReload={setReload}
                    dataCard={data}
                />
            </div>
            {/* <div className='col-span-2 '>
                <Routes>
                    <Route index element={
                        null
                    } />
                    <Route
                        path={`/historial/:almacen_producto_id`}
                        element={<AlmacenHistorials />}
                    />
                    <Route
                        path={`/cierres/:almacen_producto_id`}
                        element={<AlmacenCierresHistorial />}
                    />
                    <Route path='*' element={<ErrorPage />} />
                </Routes>
            </div> */}
        </>
    )
}
