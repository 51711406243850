import React, { useState } from 'react'
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { ErrorPage } from '../../../error/errorPage';
import { EfectivoHistorialEgresos } from './efectivo_historial_egresos';
import { ListEfectivoTable } from './ListEfectivoTable';
import { EfectivoCierresHistorial } from './efectivo_cierres';
import { EfectivoHistorialIngresos } from './efectivo_historial_ingresos';
import { EfectivoProducto } from './producto_efectivo';

export const Efectivo = () => {
    const [mainReloadTable, setMainReloadTable] = useState(false);
    return (
        <div className='grid grid-cols-1 gap-2'>
            <div className='col-span-1 xl:col-span-1'>
                <ListEfectivoTable
                    mainReloadTable={mainReloadTable}
                />
            </div>
            <Routes>
                <Route index element={
                    null
                } />
                <Route
                    path={`/productos/:efectivo_id/*`}
                    element={<EfectivoProducto
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                    />}
                />
                <Route
                    path={`/movimientos/:efectivo_id/*`}
                    element={<EfectivoHistorialIngresos
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                    />}
                />
                <Route
                    path={`/movimientos_egresos/:efectivo_id/*`}
                    element={<EfectivoHistorialEgresos
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                    />}
                />
                <Route
                    path={`/cierres/:efectivo_id/*`}
                    element={<EfectivoCierresHistorial
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                    />}
                />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </div>
    )
}