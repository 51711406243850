import React from 'react'
import { useParams } from 'react-router-dom';
import { DescriptionTargetInfo, ItemCardTargetInfo, MainTargetInfo, TitleTargetInfo } from '../../../../../components/card/MainTargetInfo';

const CardInfo = ({ reload, data, setData }) => {
    const params = useParams()
    return (
        <MainTargetInfo
            extraClassName='grid-cols-2 gap-1'
            urlApi={`/deudaEnvases/garantia/${params.deuda_id}`}
            setData={setData}
            dataRef={params.deuda_id}
            reload={reload}
        >
            <ItemCardTargetInfo
                // logo={<LogoTargetInfo logo='fa-solid fa-user-circle' />}
            >
                <TitleTargetInfo label='' data={ 'Historial de Garantia' } />
                <DescriptionTargetInfo label='Encargado' data={data?.envaseEncargadoDetalles} />
                <DescriptionTargetInfo label='Cliente' data={data?.envaseDeudorDetalles} />
                <DescriptionTargetInfo label='Cuaderno' data={data?.cuaderno} />
                <DescriptionTargetInfo label='Estado' data={data?.estado} />
            </ItemCardTargetInfo>
            <ItemCardTargetInfo>
                <DescriptionTargetInfo label='Fecha de Deuda' data={data?.createdAt} />
                <DescriptionTargetInfo label='Produccto' data={data?.producto_nombre} />
                <DescriptionTargetInfo label='Garantia Inicial' data={data?.monto_garantia_inicial} />
                <DescriptionTargetInfo label='Garantia Saldo' data={data?.monto_garantia} />
                <DescriptionTargetInfo label='Deuda Produto inicial' data={data?.saldo_inicial} />
                <DescriptionTargetInfo label='Deuda Produto Saldo' data={data?.saldo} />
            </ItemCardTargetInfo>
        </MainTargetInfo>
    )
}

export default CardInfo