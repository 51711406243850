import React, { useEffect } from 'react'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { formatDateWithTime } from '../../../../../utils/dateFormat'

export const ListHistorialPrestamoTable = ({
    reload,
    setReload,
}) => {
    const {
        dispatch,
        params,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        requestAuthPaginate,
        TableContainer,
        Paginator,
        Searcher,
        TableSection,
        ActionSection,
        Section,
    } = useGeneralParams('producto')

    const getDataPaginate = async () => {
        await requestAuthPaginate(
            'get',
            `/deudaEnvases/préstamo/${params.deuda_id}/pag`,
            null,
            paginate,
            setData,
            setStateData,
            setPaginate,
            dispatch
        )
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters, params.deuda_id]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginate.currentPage, paginate.pageSize, paginate.filterBy, paginate.filterParam, paginate.initial, paginate.final, paginate.filters]);

    const recall = () => {
        getDataPaginate()
        setReload(!reload)
    }

    const reloadTable = () => {
        getDataPaginate()
    }

    return (
        <Section>
            <ActionSection>
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    // options={[
                    //     {
                    //         label: 'Producto',
                    //         value: 'producto'
                    //     },
                    // ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={recall}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        
                        {
                            label: 'Cuaderno',
                            columns: ['cuaderno']
                        },
                        {
                            label: 'Saldo',
                            columns: ['saldo'],
                            numberValue: true,
                        },
                        {
                            label: 'Cantidad',
                            columns: ['cantidad'],
                            numberValue: true,
                        },
                        {
                            label: 'Obs',
                            columns: ['obs']
                        },
                        {
                            label: 'Fecha de pago',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime

                        },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
        </Section>
    )
}